export default {
  dietaryPreference: () => "/v2/company/onboarding_preferences",
  mealPlans: () => `/v2/uncatering_admin/meal_plans`,
  teams: () => `/v2/uncatering_admin/teams`,
  employees: () => `/v2/uncatering_admin/location_employees`,
  bulkSubsidyUpdate: () =>
    `/v2/uncatering_admin/location_employees/bulk_update`,
  groupOrders: () => `/v2/uncatering_admin/shipments`,
  adminPanelDietaryPreference: () =>
    `/v2/uncatering_admin/meal_plan_preferences`,
  meals: () => `/v2/uncatering_admin/meals`,
  billingAccounts: () => `/v2/uncatering_admin/location_billing_accounts`,
  locationAddress: () => `/v2/uncatering_admin/locations`,
  locationAddresses: () => `/v2/uncatering_admin/addresses`,
  uncateringInvoices: () => `/v2/uncatering_admin/invoices`,

  //location credit cards
  cards: () => `/v2/uncatering_admin/credit_cards`,
  paymentIntent: () => `/v2/uncatering_admin/payment_intents`,
};
