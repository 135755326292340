import React from "react";
import { successIcon, dangerAlertIcon, warningIcon } from "assets/img";
import classNames from "classnames";

const NotificationBanner = ({
  message,
  messageLink,
  textColor,
  messageType,
  messageVideoLink,
  visible,
  setVisible,
}) => {
  return (
    <>
      {visible && (
        <div className="w-full mb-4">
          <div className="bg-light-gray px-4 py-3 rounded-lg" role="alert">
            <div className="flex justify-between items-center gap-3">
              <img
                src={
                  messageType === "danger"
                    ? dangerAlertIcon
                    : messageType === "warning"
                    ? warningIcon
                    : successIcon
                }
                className="h-[30px] w-[30px]"
              />

              <div className="flex-1">
                <p
                  className={classNames({
                    "text-sm font-inter-regular": true,
                    "text-dark-gray": !textColor,
                    " text-green-600": textColor === "success",
                  })}
                >
                  {message} {"  "}
                  <span className="text-indigo-600 font-inter-medium">
                    {messageLink}
                  </span>
                </p>

                <div className="hidden lg:hidden md:flex">
                  <p className="text-indigo-600 text-md font-inter-medium text-base leading-tight">
                    {messageVideoLink}
                  </p>
                </div>
              </div>

              <div>
                <button
                  onClick={() => setVisible(false)}
                  className="text-dark-gray hover:text-yellow-600"
                  aria-label="Close"
                >
                  <span className="text-xl">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationBanner;
