import { useContext, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import Loader from "components/shared/loader";
import { MobXProviderContext } from "mobx-react";
import { observer } from "mobx-react";
import LocalStorage from "./utils/helpers/localStorage";
import { toJS } from "mobx";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

// App component
function App() {
  const store = useContext(MobXProviderContext);

  const { isLoading } = store.userStore;
  const currentUser = store.userStore.currentUser;
  const { userStore } = useContext(MobXProviderContext);

  if (!window.store) window.store = userStore;

  const cookiesInfoPresent =
    !!Cookies.get("user-token") &&
    !!Cookies.get("employee-id") &&
    !!Cookies.get("email");

  useEffect(() => {
    if (cookiesInfoPresent)
      userStore.getProfile(
        Cookies.get("employee-id"),
        Cookies.get("user-token"),
        Cookies.get("email")
      );
  }, []);

  const router = createBrowserRouter([
    cookiesInfoPresent && currentUser?.id ? PrivateRoute() : {},
    ...PublicRoute(),
  ]);

  return (
    <>
      {isLoading && <Loader />}

      <RouterProvider router={router} />
    </>
  );
}

export default observer(App);
