import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
} from "react";
import { InfoButton } from "assets/img";
import { MobXProviderContext } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import { NotificationBanner } from "components";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useParams } from "react-router-dom";

function DeliveryInfoDetail() {
  const [requestUpdate, setRequestUpdate] = useState(false);
  const { deliveryInfoStore, userStore } = useContext(MobXProviderContext);
  const [enablePhoneName, setEnablePhoneName] = useState(false);
  const [requestType, setRequestType] = useState("");

  const { id } = useParams();

  const [enableRequestAddressUpdate, setEnableRequestAddressUpdate] =
    useState(true);
  const [enableSaveChanges, setEnableSaveChanges] = useState(true);

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  const [signature, setSignature] = useState("");
  const nameInputRef = useRef(null);
  const phoneInputRef = useRef(null);

  const defaultLocation = userStore.defaultLocation;
  const locationAddress = deliveryInfoStore.locationAddress;
  const singleLocationAddress = deliveryInfoStore.singleLocationAddress;

  const initialState = {
    streetNumber: "",
    streetName: "",
    unit: "",
    city: "",
    province: "",
    postalCode: "",
    nickname: "",
    deliveryInstructions: "",
    destinationCode: "",
    deliveryContactName: "",
    deliveryContactPhone: "",
    deliveryVerification: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const navigate = useNavigate();

  const requestUpdateClick = useCallback(() => {
    setRequestUpdate(!requestUpdate);
  });

  useEffect(() => {
    // setPhone(locationAddress?.attributes?.delivery_contact_phone);
    // setName(locationAddress?.attributes?.delivery_contact_name);
    // setDeliveryInstructions(locationAddress?.attributes?.delivery_instructions);
    // setSignature(locationAddress?.attributes?.delivery_verification);
  }, [locationAddress]);

  useEffect(() => {
    dispatch({
      field: "nickname",
      value: singleLocationAddress?.attributes?.nickname,
    });
    dispatch({
      field: "streetNumber",
      value: singleLocationAddress?.attributes?.street_number,
    });
    dispatch({
      field: "streetName",
      value: singleLocationAddress?.attributes?.street_name,
    });
    dispatch({
      field: "city",
      value: singleLocationAddress?.attributes?.city,
    });
    dispatch({
      field: "province",
      value: singleLocationAddress?.attributes?.province,
    });
    dispatch({
      field: "postalCode",
      value: singleLocationAddress?.attributes?.postal_code,
    });
    dispatch({
      field: "unit",
      value: singleLocationAddress?.attributes?.suite,
    });
    dispatch({
      field: "deliveryInstructions",
      value: singleLocationAddress?.attributes?.delivery_instructions,
    });
    dispatch({
      field: "destinationCode",
      value: singleLocationAddress?.attributes?.destination_code,
    });
    dispatch({
      field: "deliveryContactPhone",
      value: singleLocationAddress?.attributes?.delivery_contact_phone,
    });
    dispatch({
      field: "deliveryContactName",
      value: singleLocationAddress?.attributes?.delivery_contact_name,
    });
    dispatch({
      field: "deliveryVerification",
      value: singleLocationAddress?.attributes?.delivery_verification,
    });
  }, [singleLocationAddress, requestUpdate]);

  useEffect(() => {
    deliveryInfoStore.getSingleLocationAddress(defaultLocation?.value, id);
  }, []);

  const updateLocationNameAndContact = () => {
    if (!provider?.deliveryContactPhone || !provider?.deliveryContactName)
      return;
    const payload = {
      address: {
        delivery_contact_phone: provider?.deliveryContactPhone,
        delivery_contact_name: provider?.deliveryContactName,
      },
    };

    deliveryInfoStore
      .updateAddress(defaultLocation?.value, id, payload)
      .then(() => {
        setEnablePhoneName(false);
      });

    setEnableSaveChanges(true);
  };

  useEffect(() => {
    if (signature)
      updateLocationNameAndContact({ delivery_verification: signature });
  }, [signature]);

  const updateAddress = (e) => {
    e.preventDefault();

    const payload = {
      address: {
        suite: provider?.unit,
        street_number: provider?.streetNumber,
        street_name: provider?.streetName,
        city: provider?.city,
        province: provider?.province,
        postal_code: provider?.postalCode,
        nickname: provider?.nickname,
        delivery_instructions: provider?.deliveryInstructions,
      },
    };

    deliveryInfoStore?.updateAddress(defaultLocation?.value, id, payload);
  };

  return (
    <div className="px-[32px] py-[24px] w-full pb-32">
      <button
        className="flex items-center cursor-pointer mb-[24px]"
        onClick={() => {
          navigate("/delivery-info");
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} style={{ width: 14, height: 14 }} />
        <span className="text-md text-left font-inter-medium text-secondary-light ml-2">
          Back
        </span>
      </button>

      {singleLocationAddress?.attributes?.under_review && (
        <NotificationBanner
          message={`Delivery address changes will need to be reviewed and approved by hungerhub.`}
        />
      )}

      <div className="d-col">
        <h1 className="text-dark-gray mt-[16px] text-[24px] font-inter-semibold">
          Delivery info
        </h1>
        <small className="font-inter-regular text-dark-gray text-sm">
          Destination code: ABC123
        </small>
      </div>

      <div className="grid lg:grid-cols-2 md:grid-cols-1 w-full mt-[32px] gap-[21px]">
        <div className="w-full">
          <form onSubmit={updateAddress}>
            <div className="d-row justify-between">
              <div className="d-col">
                <h2 className="text-dark-gray text-[20px] font-inter-semibold">
                  Delivery address
                </h2>

                <small className="font-inter-regular text-dark-gray text-sm">
                  This is where the food you order will arrive
                </small>
              </div>

              <button
                className={classNames({
                  "btn-outline h-[48px] rounded-xl lg:mt-0 md:mt-0 mt-3": true,
                  "opacity-25 btn-disabled-mode": requestUpdate,
                  // "opacity-25 btn-disabled-mode": enablePhoneName,
                })}
                disabled={requestUpdate || enablePhoneName}
                onClick={requestUpdateClick}
              >
                Update
              </button>
            </div>

            <div className="bg-light-gray p-[24px] rounded-xl mt-[24px]">
              <small className="text-dark-gray font-inter-regular text-xs">
                Address Nickname (give this address something easy for you to
                refer to)
              </small>

              <input
                className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                required
                type={"text"}
                disabled={!requestUpdate}
                onChange={(e) => {
                  dispatch({
                    field: "nickname",
                    value: e.target?.value,
                  });
                }}
                value={provider?.nickname}
              />

              <div className="d-row mt-[24px]">
                <div className="flex flex-col items-start w-1/2 mr-[24px]">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Street Number
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    required
                    type={"number"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      dispatch({
                        field: "streetNumber",
                        value: e.target?.value,
                      });
                    }}
                    value={provider?.streetNumber}
                  />
                </div>

                <div className="flex flex-col items-start w-1/2">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Unit/suite (optional)
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    type={"text"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      dispatch({
                        field: "unit",
                        value: e.target?.value,
                      });
                    }}
                    value={provider?.unit}
                  />
                </div>
              </div>

              <div className="flex flex-col items-start w-full mt-[24px] mr-4">
                <small className="text-dark-gray font-inter-regular text-xs">
                  Street name
                </small>

                <input
                  className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                  required
                  type={"text"}
                  disabled={!requestUpdate}
                  onChange={(e) => {
                    dispatch({
                      field: "streetName",
                      value: e.target?.value,
                    });
                  }}
                  value={provider?.streetNumber}
                />
              </div>

              <div className="d-row mt-[24px]">
                <div className="flex flex-col items-start w-1/2 mr-[24px]">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    City
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    required
                    type={"text"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      dispatch({
                        field: "city",
                        value: e.target?.value,
                      });
                    }}
                    value={provider?.city}
                  />
                </div>

                <div className="flex flex-col items-start w-1/2 mr-[24px]">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Province
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    required
                    type={"text"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      dispatch({
                        field: "province",
                        value: e.target?.value,
                      });
                    }}
                    value={provider?.province}
                  />
                </div>

                <div className="flex flex-col items-start w-1/2">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Postal Code
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    required
                    type={"text"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      dispatch({
                        field: "postalCode",
                        value: e.target?.value,
                      });
                    }}
                    value={provider?.postalCode}
                  />
                </div>
              </div>

              <div className="d-col mt-[24px]">
                <small className="font-inter-regular text-dark-gray text-sm">
                  Additional delivery instuctions (optional)
                </small>
              </div>

              <div className="mt-[24px]">
                <div className="mt-[4px]">
                  <textarea
                    type="text"
                    name="name"
                    id="name"
                    value={provider?.deliveryInstructions}
                    className={classNames({
                      "flex w-full text-secondary focus:outline-none rounded-l-md text-md py-[9px] p-[19px]": true,
                      // "bg-white": requestUpdate,
                      // "opacity-50 bg-zinc-100": !requestUpdate,
                    })}
                    placeholder="eg. Park in the back entrance, leave on kitchen table, call us when you arrive "
                    onChange={(e) => {
                      dispatch({
                        field: "deliveryInstructions",
                        value: e?.target?.value,
                      });
                    }}
                    disabled={!requestUpdate}
                  />
                </div>
              </div>
            </div>

            {requestUpdate && (
              <div className="d-row justify-end items-center mt-[24px]">
                <button
                  className="btn-outline rounded-xl mr-[16px] h-[48px]"
                  onClick={() => {
                    setEnableRequestAddressUpdate(true);
                    setRequestUpdate(false);
                  }}
                >
                  Cancel
                </button>

                <button
                  className={classNames({
                    "btn-blue-accent rounded-xl": true,
                    "btn-disabled": enableRequestAddressUpdate,
                  })}
                  type="submit"
                  // disabled={enableRequestAddressUpdate}
                >
                  Request address update
                </button>
              </div>
            )}
          </form>
        </div>

        <div className="w-full">
          <div className="d-col">
            <h2 className="text-dark-gray text-[20px] font-inter-semibold">
              Point of contact for address
            </h2>

            {!enablePhoneName && (
              <small className="font-inter-regular text-dark-gray text-sm">
                By default, the point of contact will be attached to all
                deliveries
              </small>
            )}
          </div>

          {enablePhoneName && (
            <div className="d-row items-start bg-background mt-[24px] py-[12px] px-[16px] rounded-lg">
              <img
                layout="fixed"
                className="object-contain mt-1 mr-[10px]"
                width={16}
                height={16}
                alt="info-button-checkou"
                src={InfoButton}
              />

              <span className="font-inter-regular text-dark-gray text-[16px]">
                The primary point of contact for this account will exclusively
                receive SMS tracking updates. The delivery contact for this
                address will be contacted in regards to deliveries to this
                address if needed.
              </span>
            </div>
          )}

          <div className="bg-background d-col p-[24px] mt-[24px] rounded-lg">
            <button
              onClick={() => {
                setEnablePhoneName(true);
                nameInputRef?.current?.focus();
              }}
              disabled={enablePhoneName || requestUpdate}
              className={classNames({
                "d-row items-center text-catering self-end": true,
                "opacity-25": enablePhoneName,
                "opacity-25": requestUpdate,
              })}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                size="1x"
                className="small-icon hover:text-gray-600 mr-2"
              />
              Edit
            </button>

            <div className="mt-[24px]">
              <label
                htmlFor="name"
                className="block text-sm font-inter-regular text-dark-gray"
              >
                Name*
              </label>

              <input
                type="text"
                disabled={!enablePhoneName}
                name="name"
                ref={nameInputRef}
                onChange={(e) => {
                  setName(e.target.value);
                  dispatch({
                    field: "deliveryContactName",
                    value: e?.target?.value,
                  });
                  setEnableSaveChanges(false);
                }}
                id="name"
                value={provider?.deliveryContactName}
                className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
              />
            </div>

            <div className="mt-[24px]">
              <label
                htmlFor="phone"
                className="block text-sm  font-inter-regular text-dark-gray leading-6"
              >
                Phone number*
              </label>

              <div className="mt-[4px] bg-background d-row rounded-md">
                <PhoneInput
                  required
                  id="phone"
                  defaultCountry="CA"
                  disabled={!enablePhoneName}
                  value={provider?.deliveryContactPhone}
                  ref={phoneInputRef}
                  className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                  placeholder="Phone Number"
                  onChange={(phone) => {
                    setEnableSaveChanges(false);
                    isValidPhoneNumber(phone) &&
                      dispatch({
                        field: "deliveryContactPhone",
                        value: phone,
                      });
                  }}
                />
              </div>
            </div>
          </div>

          {enablePhoneName && (
            <div className="d-row justify-end items-center mt-[24px]">
              <button
                className="btn-outline rounded-xl mr-[16px] h-[48px]"
                onClick={() => {
                  setEnableSaveChanges(true);
                  setEnablePhoneName(false);
                }}
              >
                Cancel
              </button>

              <button
                className={classNames({
                  "btn-blue-accent rounded-xl": true,
                  "btn-disabled": enableSaveChanges,
                })}
                onClick={() => {
                  updateLocationNameAndContact();
                }}
                // disabled={enableSaveChanges}
              >
                Save Changes
              </button>
            </div>
          )}

          <h2 className="text-dark-gray text-[20px] mb-[24px] mt-[32px] font-inter-semibold">
            Delivery verification
          </h2>

          <div className="py-[13px] px-[24px] bg-light-gray rounded-lg">
            <div className="d-row items-start">
              <img
                layout="fixed"
                className="object-contain mt-1 mr-[10px]"
                width={16}
                height={16}
                alt="info-button-checkou"
                src={InfoButton}
              />

              <span className="font-inter-regular text-dark-gray text-[16px]">
                <strong className="font-inter-semibold text-dark-gray text-[16px]">
                  Delivery is verified with photos submitted by drivers or with
                  the driver asking for your signature{" "}
                </strong>
                {<br />}
                To switch verification method, please contact hungerhub
              </span>
            </div>

            <div className="bg-light-gray px-[26px] d-row rounded-lg my-[8px]">
              <div key="photo" className="flex items-center">
                <input
                  name="notification-method"
                  type="radio"
                  checked={provider?.deliveryVerification === "photo"}
                  disabled
                  // disabled={requestUpdate || enablePhoneName}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />

                <label
                  htmlFor={"photo"}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  Photo
                </label>
              </div>

              <div key={"signature"} className="flex items-center pl-[24px]">
                <input
                  name="notification-method"
                  type="radio"
                  checked={provider?.deliveryVerification === "signature"}
                  disabled
                  // disabled={requestUpdate || enablePhoneName}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor={"signature"}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  Signature
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(DeliveryInfoDetail);
