import { Navigate } from "react-router-dom";
import Layout from "./Layout";
import { MealManagement } from "../containers/MealManagement";
import {
  ManageUsers,
  MealPreference,
  UpcomingGroupOrderDetail,
} from "containers/MealManagement";
import {
  Home,
  OrderDetail,
  AddUncateringMeals,
  AddCateringMeal,
  RestaurantPage,
  CheckoutPage,
  AddGroupMeal,
  NewGroupOrder,
  GroupOrderConfirmation,
  LoggedInLayout,
  OrdersPage,
  GroupOrderInstructions,
  Faqs,
  GroupOrderDetails,
  RequestChangesToMeal,
  ManageTeams,
  ViewOrder,
} from "containers/Home";
import DeliveryInfo from "containers/DeliveryInfo";
import PaymentInfo from "containers/PaymentInfo";
import DeliveryInfoDetail from "containers/DeliveryInfo/show";
import CreateNewAddress from "containers/DeliveryInfo/create";
import { ManagePlanProvider } from "contexts/ManagePlanContext";
import OurServices from "containers/OurServices";
import UncateringTeam from "containers/Uncatering/Team";
import UncateringInvoices from "containers/Uncatering/Invoices";
import MarketplacePaymentInfo from "containers/PaymentInfo/marketplacePaymentInfo";

export default function privateRoutes() {
  return {
    element: <Layout />,
    children: [
      //HOME
      // { path: "/", element: <Home /> },
      // { path: "/home", element: <Home /> },
      { path: "/home", element: <div /> },
      { path: "/", element: <div /> },
      { path: "/home/order-detail", element: <OrderDetail /> },
      { path: "/home/order-detail/:orderId", element: <ViewOrder /> },
      { path: "/delivery-info", element: <DeliveryInfo /> },
      { path: "/payment-info", element: <PaymentInfo /> },
      { path: "/delivery-info/:id", element: <DeliveryInfoDetail /> },
      { path: "/our-services", element: <OurServices /> },
      { path: "/delivery-info/create", element: <CreateNewAddress /> },

      {
        path: "/home/request-meal-changes",
        element: (
          <ManagePlanProvider>
            <RequestChangesToMeal />
          </ManagePlanProvider>
        ),
      },
      {
        path: "/home/manage-teams",
        element: (
          <ManagePlanProvider>
            <ManageTeams />
          </ManagePlanProvider>
        ),
      },
      //Uncatering
      {
        path: "/uncatering/team",
        element: <UncateringTeam />,
      },
      {
        path: "/uncatering/invoices",
        element: <UncateringInvoices />,
      },

      // MEAL MANAGEMENT
      {
        path: "/meal-management",
        element: (
          <ManagePlanProvider>
            <MealManagement />
          </ManagePlanProvider>
        ),
      },
      {
        path: "/meal-management/manage-teams",
        element: (
          <ManagePlanProvider>
            <ManageUsers />
          </ManagePlanProvider>
        ),
      },

      {
        path: "/meal-management/meal-preference",
        element: <MealPreference />,
      },
      {
        path: "/meal-management/group-order/:id",
        element: <UpcomingGroupOrderDetail />,
      },

      // ADD MEAL Uncatering, Catering & Group
      { path: "/get-started-uncatering", element: <AddUncateringMeals /> },
      { path: "/get-started-catering", element: <AddCateringMeal /> },
      {
        path: "/get-started-catering/restaurant/:code",
        element: <RestaurantPage />,
      },
      {
        path: "/get-started-catering/checkout/:code",
        element: <CheckoutPage />,
      },
      {
        path: "/get-started-group",
        element: <AddGroupMeal />,
      },
      {
        path: "/get-started-group/restaurant/:code",
        element: <RestaurantPage />,
      },
      {
        path: "/group-order/new", //?date=2024-02-20&time=5%3A45+pm&address=Toronto+St%2C+Toronto%2C+ON+M5C%2C+Canada&id=ofbii&name=Ostro&menu_id=404
        element: <NewGroupOrder />,
      },
      {
        path: "/group-order/confirmation/:slug",
        element: <GroupOrderConfirmation />,
      },
      {
        path: "/group-order/admin/orders",
        element: <LoggedInLayout mainContent={<GroupOrderDetails />} />,
      },
      {
        path: "/group-order/orders",
        element: <LoggedInLayout mainContent={<OrdersPage />} />,
      },
      {
        path: "/group-order/admin/group-order-new",
        element: <LoggedInLayout mainContent={<GroupOrderInstructions />} />,
      },
      {
        path: "/group-order/faqs",
        element: <LoggedInLayout mainContent={<Faqs />} />,
      },
      {
        path: "/marketplace/payment-info/:id",
        element: <MarketplacePaymentInfo />,
      },

      { path: "*", element: <Navigate to="/" replace /> },
    ],
  };
}
