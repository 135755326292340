import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { MobXProviderContext } from "mobx-react";
import { useContext } from "react";

function StartGroupOrderBadge({ menuId, address }) {
  const router = null;
  const store = useContext(MobXProviderContext);

  const onClickStart = () => {
    if (!address) {
      store?.userStore?.setDeliveryPickupModal({
        state: true,
        fromCart: false,
      });

      return;
    } else {
      store.groupOrderStore.setCurrentGroupOrder(null);
      router.push({
        pathname: "/group-order/new",
        query: { ...router.query, menu_id: menuId },
      });
    }
  };

  return (
    <div className="flex flex-col w-full p-6 sm:mt-4 bg-background rounded-xl sm:items-center sm:justify-between">
      <span className="text-sm mt-3">
        To create a shared cart and invite others to place orders
      </span>

      <button
        onClick={onClickStart}
        className="w-full text-white btn-blue-accent py-[10px] px-6 text-md mt-4 hover:bg-hh-accent-medium rounded-md"
      >
        Start Group Order
      </button>
    </div>
  );
}

export default observer(StartGroupOrderBadge);
