import React, { useState } from "react";
import { OnboardingSteps } from "components";
import { NotFound, SearchBar, DatePicker, RestaurantCard } from "components";
import { getFiltersQuery, isPresent } from "utils/helpers/array";
import FilterOptions from "./FilterOptions";
import pluralize from "pluralize";
import SortBySelect from "./SortBySelect";

function AddGroupMeal() {
  const [filtersModal, setFiltersModal] = useState(false);
  let activeMeals = null;
  const choosenTags = () => {
    return [];
  };

  const featuredBadges = () => {
    return [];
  };
  const clearAll = () => {};
  const updateChosenRestaurantFilters = () => {};
  // const allMenusPagy = null;
  const handleOnChange = () => {};
  const handleOnBlur = () => {};
  const isLoading = false;
  const allMenusPagy = { count: 2 };
  // const allMenus = [];

  const allMenus = [
    {
      attributes: {
        id: 119,
        name: "testing",
        code: "gozgu",
        tagline_odc_short: null,
        tagline_odc_long: null,
        available_for_direct_catering: true,
        available_for_hungerhub_catering: true,
        cuisine_tags: [],
        disabled: false,
        display_name: "testing",
        featured_badges: [],
        image_url: null,
        packaging_tags: [],
        tagline: null,
        tags: [],
        type: "location_menu",
      },
    },
    {
      attributes: {
        available_for_direct_catering: true,
        available_for_hungerhub_catering: true,
        code: "ueecm",
        cuisine_tags: ["Salads", "Dim Sum"],
        disabled: false,
        display_name: "Lan items restaurant",
        featured_badges: ["Crowd Pleasers", "New"],
        id: 1,
        image_url:
          "https://nbetesting.hungerhub.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBY2M9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--751828d567b7168f95b7e0145b371f9796031c9a/1647521523916-img",
        name: "Lan Restaurant 755 Queen St E Toronto",
        packaging_tags: ["Biodegradable Packaging"],
        tagline: "testing",
        tagline_odc_long: "Toronto's best Vietnamese, delivered to your desk!",
        tagline_odc_short: "testing",
        tags: ["100% Vegan"],
      },
    },
    {
      attributes: {
        available_for_direct_catering: true,
        available_for_hungerhub_catering: true,
        code: "ueecm",
        cuisine_tags: ["Salads", "Dim Sum"],
        disabled: false,
        display_name: "Lan items restaurant",
        featured_badges: ["Crowd Pleasers", "New"],
        id: 1,
        image_url:
          "https://nbetesting.hungerhub.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBY2M9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--751828d567b7168f95b7e0145b371f9796031c9a/1647521523916-img",
        name: "Lan Restaurant 755 Queen St E Toronto",
        packaging_tags: ["Biodegradable Packaging"],
        tagline: "testing",
        tagline_odc_long: "Toronto's best Vietnamese, delivered to your desk!",
        tagline_odc_short: "testing",
        tags: ["100% Vegan"],
      },
    },
  ];
  return (
    <div className="w-full px-[44px]">
      {/* <OnboardingSteps /> */}

      <div className="d-row">
        <SearchBar />

        <DatePicker
          fromFilters
          // isCart={isCart ?? false}
          // availableDates={availableDates}
          // activeRestaurant={activeRestaurant}
          // getAvailableDates={getAvailableDates}
          // getAvailableTimes={getAvailableTimes}
          // selectedDate={isCart ? cart?.cartDate : selectedDate}
          minDate={new Date()}
          format={"MMMM d, yyyy"}
          className="flex h-12 bg-light-gray justify-between rounded-lg ml-2 pr-2 items-center"
          pickerClassName={"font-inter-medium"}
        />
      </div>

      <div
        className="flex flex-col md:p-4 pb-0 md:px-8 bg-white mt-[48px]"
        style={{ maxWidth: "1440px" }}
      >
        <div className="flex flex-row w-full">
          <div className=" md:flex md:w-2/12">
            <div className="sticky-side-section w-[213px]">
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <span className="text-xl text-dark-gray font-inter-medium">
                    Featured
                  </span>

                  {(isPresent(choosenTags()) ||
                    isPresent(featuredBadges())) && (
                    <button
                      onClick={() => clearAll()}
                      className="flex btn-style font-inter-medium text-sm text-hh-accent-light hover:text-hh-accent-dark"
                    >
                      Clear all
                    </button>
                  )}
                </div>

                <div className="flex flex-col mt-2 mb-4">
                  <FilterOptions
                    filterType="featured"
                    filters={[
                      "East African",
                      "Ethiopian",
                      "North African",
                      "African",
                    ]}
                    updateChosenRestaurantFilters={
                      updateChosenRestaurantFilters
                    }
                    router={null}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-xl text-dark-gray font-inter-medium">
                    Cuisine
                  </span>
                </div>

                <div className="flex flex-col">
                  <FilterOptions
                    filterType="cuisine"
                    filters={[
                      "East African",
                      "Ethiopian",
                      "North African",
                      "African",
                    ]}
                    router={null}
                  />

                  <button
                    onClick={() => setFiltersModal(true)}
                    className="flex btn-style underline font-inter-medium text-primary-dark mt-2 hover:text-dark-gray"
                  >
                    Show All
                  </button>
                </div>

                <span className="text-xl text-dark-gray font-inter-medium mt-8">
                  Dietary
                </span>

                <div className="flex flex-col">
                  <FilterOptions
                    filterType="dietary"
                    filters={[
                      "East African",
                      "Ethiopian",
                      "North African",
                      "African",
                    ]}
                    updateChosenRestaurantFilters={
                      updateChosenRestaurantFilters
                    }
                    router={null}
                  />
                </div>

                <span className="text-xl text-dark-gray font-inter-medium mt-8">
                  Packaging
                </span>

                <div className="flex flex-col">
                  <FilterOptions
                    filterType="packaging"
                    filters={[]}
                    updateChosenRestaurantFilters={
                      updateChosenRestaurantFilters
                    }
                    router={null}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-wrap w-fulls">
            {/* Desktop */}
            <div className="md:flex md:w-full items-center md:sticky md:top-12 z-10 bg-white">
              <div className="flex w-full justify-between items-center mt-3 md:pt-10 md:pb-5 ml-3">
                <div className="flex items-center">
                  <span className="text-[24px] font-inter-semibold mr-1">
                    {allMenusPagy?.count}{" "}
                    {pluralize("restaurant", allMenusPagy?.count)}
                  </span>

                  <SearchBar
                    handleOnChange={handleOnChange}
                    handleOnBlur={handleOnBlur}
                    value={""}
                    width="w-[422px]"
                  />
                </div>

                {/* {allMenus?.length == 0 && (
                    <ExpandRadiusCheckbox
                      setExpandedRadius={setExpandedRadius}
                      isExpandedRadius={isExpandedRadius}
                    />
                  )} */}

                <SortBySelect sortBy={null} onChange={() => {}} />
              </div>
            </div>

            {allMenus?.length > 0 ? (
              <div className="cards-grid">
                {allMenus.map((menu, index) => (
                  <RestaurantCard
                    currentImageIndex={1}
                    setCurrentImageIndex={() => {}}
                    // loadTagsForMenu={loadTagsForMenu}
                    // setPreviewModal={setPreviewModal}
                    // setMenuDetails={setMenuDetails}
                    key={`card-${index}`}
                    index={index}
                    menu={menu}
                    isGroupMeal={true}
                  />
                ))}
              </div>
            ) : (
              !isLoading && (
                <div className="flex flex-col justify-center items-center mt-20">
                  <img
                    src={null}
                    width={170}
                    height={170}
                    alt="no-restaurants"
                  />

                  {/* <div className="flex flex-col justify-center">
                    <span className="text-sm text-center text-dark-gray mt-3">
                      No restaurants are available to deliver to this address.
                    </span>

                    {!isExpandedRadius && (
                      <button className="mt-3 btn-style" onClick={expandRadius}>
                        <span className="text-primary-dark">
                          Try expanding your delivery radius to 50 km
                        </span>
                      </button>
                    )}

                    <div
                      className={`flex ${
                        isExpandedRadius
                          ? "flex-row items-center justify-center mt-3"
                          : "flex-col"
                      }`}
                    >
                      <button
                        onClick={() => {
                          setAddressTimeModal(true);
                        }}
                        className={`${
                          isExpandedRadius ? "mt-0" : "mt-3"
                        } btn-style`}
                      >
                        <span className="text-primary-dark">
                          Change address
                        </span>
                      </button>

                      {isExpandedRadius && (
                        <span className="text-sm text-dark-gray mx-2">
                          or
                        </span>
                      )}

                      <button className="mt-3">
                        <a
                          href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/bring-hungerhub-to-your-city`}
                          className={`${
                            isExpandedRadius ? "mt-0" : "mt-3"
                          } btn-style`}
                        >
                          <span className="text-primary-dark">
                            Request hungerhub in your city
                          </span>
                        </a>
                      </button>
                    </div>
                  </div> */}
                </div>
              )
            )}

            {allMenusPagy?.next && (
              <div
                className="flex items-center justify-center my-2"
                onClick={() => {}}
              >
                <button className="btn-purple">Load more restaurants</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddGroupMeal;
