import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { foodImage, thumbsDown, thumbsUp } from "assets/img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { toJS } from "mobx";

const MyCrousal = ({
  dietaryPreferences,
  handleReviewButton,
  selectedCuisines,
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const [leftArrowClicked, setLeftArrowClicked] = useState(false);
  const [rightArrowClicked, setRightArrowClicked] = useState(false);
  const [hoverLeftArrow, setHoverLeftArrow] = useState(false);
  const [hoverRightArrow, setHoverRightArrow] = useState(false);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    const isLastSlide = currentSlide + slidesToShow >= totalItems;
    const isFirstSlide = currentSlide === 0;

    const handleClickNext = () => {
      setLeftArrowClicked(false);
      setRightArrowClicked(true);
      if (!isLastSlide) {
        const nextSlide = currentSlide + 4;
        goToSlide(nextSlide);
      }
    };

    const handleClickPrevious = () => {
      setRightArrowClicked(false);
      setLeftArrowClicked(true);
      if (!isFirstSlide) {
        const previousSlide = currentSlide - 4;
        goToSlide(previousSlide);
      }
    };

    return (
      <div className="carousel-button-group w-fit">
        <div className="hidden screen-lg:flex screen-md:flex gap-3 inline-flex">
          <button
            className={classNames(
              "h-12 w-12 justify-center items-center inline-flex rounded-xl",
              {
                "bg-slate-50 hover:bg-indigo-500":
                  !leftArrowClicked && !isFirstSlide,
                "bg-indigo-500": leftArrowClicked && !isFirstSlide,
                "bg-slate-50": isFirstSlide,
              }
            )}
            onClick={handleClickPrevious}
            disabled={isFirstSlide}
            onMouseEnter={() => {
              setHoverLeftArrow(true);
            }}
            onMouseLeave={() => {
              setHoverLeftArrow(false);
            }}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{
                color: isFirstSlide
                  ? "#E8EAEF"
                  : leftArrowClicked || hoverLeftArrow
                  ? "#FFFFFF"
                  : "#130f26",
              }}
            />
          </button>

          <button
            className={classNames(
              "h-12 w-12 justify-center items-center inline-flex rounded-xl",
              {
                "bg-slate-50 hover:bg-indigo-500":
                  !rightArrowClicked && !isLastSlide,
                "bg-indigo-500": rightArrowClicked && !isLastSlide,
                "bg-slate-50": isLastSlide,
              }
            )}
            onClick={handleClickNext}
            disabled={isLastSlide}
            onMouseEnter={() => {
              setHoverRightArrow(true);
            }}
            onMouseLeave={() => {
              setHoverRightArrow(false);
            }}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{
                color: isLastSlide
                  ? "#E8EAEF"
                  : rightArrowClicked || hoverRightArrow
                  ? "#FFFFFF"
                  : "#130f26",
              }}
            />
          </button>
        </div>
      </div>
    );
  };

  const handleClick = (name, review) => {
    handleReviewButton(name, review);
  };

  const isCuisineTypeReviewed = (name, reviewType) => {
    return selectedCuisines.some(
      (cuisineType) =>
        cuisineType.name === name && cuisineType.review === reviewType
    );
  };

  return (
    <Carousel
      responsive={responsive}
      arrows={false}
      renderButtonGroupOutside={true}
      containerClass="max-w-[716px]"
      customButtonGroup={<ButtonGroup />}
    >
      {dietaryPreferences?.map((preference, index) => (
        <div key={index} className="d-col w-[161px]">
          <div className="bg-white">
            <img
              src={preference.url || foodImage} //this is a temporary image
              alt={`Img ${index}`}
              className="w-44 screen-md:w-36 h-[76px] bg-slate-50 rounded-t-xl"
            />

            <div className=" bg-slate-50 px-[9px] py-2 rounded-b-xl">
              <div className="flex-col justify-start items-start gap-3 h-40">
                <div className="self-stretch h-10 justify-start items-start gap-3 flex">
                  <div className="text-slate-700 text-base font-inter-semibold leading-tight">
                    {preference.name}
                  </div>
                </div>

                <div className="py-2 flex flex-wrap">
                  {preference?.tags
                    ?.split(",")
                    ?.slice(0, 4)
                    ?.map((tag, tagIndex) => (
                      <div
                        key={tagIndex}
                        className="w-fit px-1.5 py-1 mr-2 mb-2 text-slate-700 bg-white rounded-3xl text-xs font-inter-medium"
                      >
                        {tag}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-4 justify-start items-start gap-1 inline-flex">
            <button
              className={classNames(
                "hover-class grow shrink basis-0 h-[30px] px-1.5 py-2 rounded justify-center items-center gap-1 flex",
                {
                  "bg-slate-50 hover:bg-indigo-500": !isCuisineTypeReviewed(
                    preference.name,
                    "like"
                  ),
                  "bg-indigo-500": isCuisineTypeReviewed(
                    preference.name,
                    "like"
                  ),
                }
              )}
              onClick={() => {
                handleClick(preference.name, "like");
              }}
            >
              <img
                className="hover-class"
                src={thumbsUp}
                alt="ThumbsUp"
                style={{
                  filter: isCuisineTypeReviewed(preference.name, "like")
                    ? "invert(82%) sepia(66%) saturate(14.06%) hue-rotate(176deg) brightness(110%) contrast(190%)"
                    : "none",
                }}
              />
            </button>

            <button
              className={classNames(
                "grow shrink basis-0 h-[30px] px-1.5 py-2 rounded justify-center items-center gap-1 flex text-xs font-inter-medium leading-[14px]",
                {
                  "bg-slate-50 text-indigo-900 hover:bg-indigo-500 hover:text-white":
                    !isCuisineTypeReviewed(preference.name, "neutral"),
                  "bg-indigo-500 text-white": isCuisineTypeReviewed(
                    preference.name,
                    "neutral"
                  ),
                }
              )}
              onClick={() => {
                handleClick(preference.name, "neutral");
              }}
            >
              Neutral
            </button>

            <button
              className={classNames(
                "hover-class grow shrink basis-0 h-[30px] px-1.5 py-2 rounded justify-center items-center gap-1 flex",
                {
                  "bg-slate-50 hover:bg-indigo-500": !isCuisineTypeReviewed(
                    preference.name,
                    "dislike"
                  ),
                  "bg-indigo-500": isCuisineTypeReviewed(
                    preference.name,
                    "dislike"
                  ),
                }
              )}
              onClick={() => {
                handleClick(preference.name, "dislike");
              }}
            >
              <img
                className="hover-class"
                src={thumbsDown}
                alt="ThumbsDown"
                style={{
                  filter: isCuisineTypeReviewed(preference.name, "dislike")
                    ? "invert(82%) sepia(66%) saturate(14.06%) hue-rotate(176deg) brightness(110%) contrast(190%)"
                    : "none",
                }}
              />
            </button>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default MyCrousal;
