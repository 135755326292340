import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AltImage } from "assets/img";
import DietaryTag from "components/DietaryTag";

import { MobXProviderContext } from "mobx-react";
import QueryString from "qs";
import React, { useContext } from "react";
import badge from "utils/helpers/badge";
import { changeSpacesToHyphens } from "utils/helpers/restaurants";
import Screen from "utils/helpers/ScreenSize";
import styles from "./RestaurantCard.module.css";

function RestaurantCard({
  setPreviewModal,
  setMenuDetails,
  menu,
  loadTagsForMenu,
  index,
  currentImageIndex,
  setCurrentImageIndex,
  isGroupMeal = false,
}) {
  const store = useContext(MobXProviderContext);
  const screenType = Screen.ScreenType();
  const router = null;

  const restaurantLink = `${
    isGroupMeal ? "/get-started-group" : "/get-started-catering"
  }/restaurant/mhkjh?${QueryString.stringify(router?.query)}`;

  const moveToRestaurantDetails = () => {
    router.push({
      pathname: restaurantLink,
    });

    store.userStore?.setLoader(true);
  };

  const getTags = () => {
    return menu?.attributes?.tags.length > 0
      ? menu?.attributes?.tags.slice(0, 3)
      : menu?.attributes?.packaging_tags.slice(0, 3);
  };

  const handleImageLoad = () => {
    setCurrentImageIndex((prevIndex) => prevIndex + 1);
  };

  const onClickRestaurantCard = () => {
    if (screenType.isDesktop) {
      loadTagsForMenu(menu?.attributes?.code);
      setMenuDetails(menu);
      setPreviewModal(true);
    } else moveToRestaurantDetails();
  };

  const cardImage = (
    <>
      {index <= currentImageIndex ? (
        <img
          priority
          src={menu?.attributes?.image_url ?? AltImage}
          width="354px"
          height={"162px"}
          layout="fixed"
          objectFit="cover"
          alt={`${menu?.attributes?.display_name}-image-card-${index + 1}`}
          placeholder="blur"
          blurDataURL={menu?.attributes?.image_url}
          onLoad={handleImageLoad}
        />
      ) : (
        <img
          loading="lazy"
          src={menu?.attributes?.image_url ?? AltImage}
          width="354px"
          height={"162px"}
          layout="fixed"
          objectFit="cover"
          alt={`Alt-image-card-${index + 1}`}
          placeholder="blur"
          blurDataURL={menu?.attributes?.image_url}
          onLoad={handleImageLoad}
        />
      )}

      <div className="absolute top-2 right-3">
        <div className="flex flex-wrap justify-end px-1 w-full float-right">
          {menu?.attributes?.featured_badges
            ?.slice(0, 2)
            ?.map((featured, index) =>
              badge.getFeaturedTag(featured, `${index}card`)
            )}
        </div>
      </div>

      <div className="absolute hidden md:group-hover:block z-5 top-0 bottom-0 right-0 left-0">
        <div
          className={`${styles.res_image} flex items-center justify-center space-x-2`}
        >
          <div
            style={{ backgroundColor: "#3a3a3a33", borderRadius: "24px" }}
            className="flex items-center justify-center px-3 py-1"
          >
            <FontAwesomeIcon
              icon={faEye}
              color="#fff"
              size="1x"
              className="icon"
            />
            <span className="font-inter-medium text-xs ml-2 text-white">
              Preview
            </span>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div
        className={`
          ${styles.res_card}
          flex flex-col relative select-none group flex-wrap cursor-pointer bg-light-gray my-5 overflow-hidden`}
      >
        {/* //desktop image view */}
        <div
          className={`${styles.res_image} relative hidden md:flex`}
          onClick={onClickRestaurantCard}
        >
          {cardImage}
        </div>

        {/* //mobile image view */}
        <a
          target="_blank"
          className={`${styles.res_image} relative flex md:hidden`}
          href={restaurantLink}
        >
          {cardImage}
        </a>

        <a
          className="flex flex-col px-4 py-2 h-[126px]"
          href={restaurantLink}
          target="_blank"
        >
          <span
            className={`${styles.title} inline-block font-inter-semibold text-primary-black overflow-ellipsis overflow-hidden whitespace-nowrap`}
          >
            {menu?.attributes?.display_name}
          </span>

          <div className="flex">
            {menu?.attributes?.cuisine_tags?.slice(0, 3)?.map((tag, index) => (
              <span
                key={`${menu?.attributes?.display_name}-tag-${index}`}
                className={`${styles.tags} mt-1 font-inter-regular text-sm text-primary-black whitespace-pre`}
              >
                {tag}{" "}
                {index + 1 !==
                  menu?.attributes?.cuisine_tags?.slice(0, 3)?.length && (
                  <span>• </span>
                )}
              </span>
            ))}
          </div>

          <div className="tags-container mt-3">
            {getTags()?.map((dietary, index) => (
              <DietaryTag
                tag={dietary}
                styles={styles}
                key={`${index}-dietary`}
              />
            ))}
          </div>
        </a>
      </div>
    </>
  );
}

export default RestaurantCard;
