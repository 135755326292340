import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useContext, useEffect, useReducer } from "react";
import { MobXProviderContext } from "mobx-react";
import ConfirmationBadge from "components/ConfirmationBadge";
// import Link from "next/link";
import { CopyLinkWhite } from "assets/img";

const GroupOrderConfirmation = ({ hostname }) => {
  const initialState = {
    link: "",
    showPassword: false,
    error: false,
    showCopyLinkBadge: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const groupOrderStore = toJS(store?.groupOrderStore);

  let { currentGroupOrder } = groupOrderStore ?? {};
  let { currentUser } = userStore ?? {};
  let { showCopyLinkBadge } = provider ?? {};

  const updateGroupOrder = (payload) => {
    if (currentGroupOrder)
      store?.groupOrderStore?.updateGroupOrder(
        currentGroupOrder?.attributes?.slug,
        payload
      );
  };

  useEffect(() => {
    if (!store?.userStore?.isHydrated) return;

    if (currentGroupOrder) {
      let payload = {
        group_order: {
          status: "active",
        },
      };

      if (currentUser?.id && !currentGroupOrder?.attributes?.user_id) {
        payload.group_order.user_id = currentUser.id;
      }

      updateGroupOrder(payload);
    }
  }, [
    store?.userStore?.isHydrated,
    currentGroupOrder?.attributes?.user_id,
    currentUser?.id,
  ]);

  const getURL = () => {
    return `${hostname}/group-order/user/restaurant/${
      currentGroupOrder?.attributes?.menu_code
    }/${currentGroupOrder?.attributes?.slug}${
      currentGroupOrder?.attributes?.source_of_business === "restaurant"
        ? "?utm_source=odc"
        : ""
    }`;
  };

  const copyLinkToDashboard = () => {
    if (currentGroupOrder) {
      updateGroupOrder({
        group_order: {
          status: "active",
        },
      });

      const updatedURL = getURL();

      navigator.clipboard.writeText(updatedURL);

      dispatch({ field: "showCopyLinkBadge", value: true });

      setTimeout(() => {
        dispatch({ field: "showCopyLinkBadge", value: false });
      }, 2000);
    }
  };

  const submitButtons = (
    <Link
      href={`/group-order/admin/orders/${currentGroupOrder?.attributes?.slug}`}
    >
      <div className="flex flex-col mt-12">
        <button type="button" className="btn-purple mt-5 h-16">
          Go To Group Order
        </button>
      </div>
    </Link>
  );

  return (
    <>
      <div
        className="flex justify-center relative bg-white min-h-[calc(100vh-200px)] mx-auto p-5 sm:p-10 md:p-8 md:mt-0"
        style={{ maxWidth: "1440px" }}
      >
        <div className="flex flex-col items-center justify-center w-full md:w-[434px]">
          <div className="flex flex-col text-center">
            <span className="text-md font-inter-semibold text-hh-accent-light">
              Step 3/3
            </span>

            <span className="text-[24px] font-inter-semibold text-black-light mt-1.5">
              Almost there!
            </span>

            <span className="text-md mt-3 w-full">
              Share this invite link to your colleagues and friends to let them
              order on your behalf.
            </span>

            <span className="text-md mt-3 w-full">
              You can access this link again from your admin panel if you close
              this window.
            </span>
          </div>

          <div className="flex flex-col justify-center items-center flex-wrap mt-12 w-full">
            <div className="flex flex-col w-full">
              <div className="flex justify-between text-md font-inter-regular rounded-xl bg-background w-full overflow-hidden focus:outline-none h-12 mt-3">
                <input
                  required
                  className="flex w-full bg-background text-secondary-text focus:outline-none rounded-l-md text-md px-3"
                  value={getURL()}
                  disabled={true}
                />

                <button
                  type="button"
                  onClick={copyLinkToDashboard}
                  className="btn-style w-2/5 bg-primary-dark hover:bg-hh-accent-light"
                >
                  <div className="flex items-center justify-center">
                    <img src={CopyLinkWhite} layout="fixed" />
                    <span className="text-white font-inter-medium text-md ml-2">
                      Copy Link
                    </span>
                  </div>
                </button>
              </div>

              <div className="flex flex-col">{submitButtons}</div>
            </div>
          </div>
        </div>

        {showCopyLinkBadge && <ConfirmationBadge text={"Link Copied!"} />}
      </div>
    </>
  );
};

export default observer(GroupOrderConfirmation);
