import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext } from "react";
import { CommentIcon, takeawayIcon } from "assets/img";

function ViewBag({ visible, setModal }) {
  //PLEASE SET THE FONT FAMILY FOR EVERY TEXT HERE AND DOUBLE CHECK THE STYLE WHILE INTEGRATING
  //PLEASE SET THE FONT FAMILY FOR EVERY TEXT HERE AND DOUBLE CHECK THE STYLE WHILE INTEGRATING
  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] rounded-lg md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="px-8">
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl font-bold">Samuel Condy</h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <div className="flex justify-between items-center mb-4">
                    <h3 className="font-bold text-lg">Greek 2 Go</h3>
                    <span className="d-row text-blue-800 text-xs px-2.5 py-0.5 rounded">
                      <img src={takeawayIcon} className="w-[20px] h-[20px]" />{" "}
                      <span className="font-normal text-slate-700 ml-1 text-base">
                        Bag #
                      </span>
                      <span className="font-semibold text-slate-700 ml-1 text-base">
                        17
                      </span>
                    </span>
                  </div>

                  <div className="grid grid-cols-4 gap-2 text-sm mb-4 bg-light-gray p-[12px]">
                    <div className="d-col border-r">
                      <small>Date</small>
                      <small>[DDD], [MONTH] [DD], [YYYY]</small>
                    </div>
                    <div className="d-col border-r">
                      <small>Meal </small>
                      <small>hungerhub staff happy hour</small>
                    </div>
                    <div className="d-col border-r">
                      <small>Cutlery </small>
                      <small>Yes</small>
                    </div>
                    <div className="d-col border-r">
                      <small>Status </small>
                      <small>Sent</small>
                    </div>
                  </div>

                  <div className="pt-4">
                    <div className="d-row justify-between bg-light-gray px-[24px] py-[16px]">
                      <div className="w-1/2">
                        <h4 className="font-bold">Item</h4>
                      </div>
                      <div className="w-1/2">
                        <h4 className="font-bold">Food total</h4>
                      </div>
                    </div>

                    <div className="d-row justify-between px-[24px] py-[16px]">
                      <div className="w-1/2 d-col">
                        <small className="text-sm">Bowl</small>
                        <small className="text-sm">Regular</small>
                        <small className="text-sm">Protein: Falafel</small>

                        <div className="d-row">
                          <img src={CommentIcon} className="h-4 w-4" />
                          <small>Please do not add tomatoes </small>
                        </div>
                      </div>

                      <div className="w-1/2 d-col">
                        <small className="text-sm">$42.33</small>
                        <small className="text-sm">$42.33</small>
                        <small className="text-sm">$42.33</small>
                      </div>
                    </div>

                    <div className="border border-slate-50" />

                    <div className="d-row justify-between px-[24px] py-[16px] mt-[32px]">
                      <div className="w-1/2 d-col">
                        <small className="text-sm">Bowl</small>
                        <small className="text-sm">Regular</small>
                        <small className="text-sm">Protein: Falafel</small>

                        <div className="d-row">
                          <img src={CommentIcon} className="h-4 w-4" />
                          <small>Please do not add tomatoes </small>
                        </div>
                      </div>

                      <div className="w-1/2 d-col">
                        <small className="text-sm">$42.33</small>
                        <small className="text-sm">$42.33</small>
                        <small className="text-sm">$42.33</small>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3xl border border-slate-50 px-4 py-6 mb-4">
                    <div className="flex justify-between items-center pt-4 mt-4">
                      <div>Total items</div>
                      <div>11</div>
                    </div>

                    <div className="flex justify-between items-center mt-2">
                      <div className="flex items-center bg-light-gray w-1/2 justify-between p-[12px] rounded-lg">
                        <div className="mr-2">Paid by user:</div>
                        <div className="font-bold">$10.53</div>
                      </div>
                      <div className="flex items-center bg-light-gray w-1/2 justify-between ml-[32px] p-[12px] rounded-lg">
                        <div className="mr-2">Paid by Office:</div>
                        <div className="font-bold">$43.12</div>
                      </div>
                    </div>

                    <div className="flex justify-between items-center pt-4 mt-4 py-[24px]">
                      <div>
                        <div>Food Total</div>
                        <div>Tax</div>
                        <div className="font-bold">Total</div>
                      </div>
                      <div className="text-right">
                        <div>$54.37</div>
                        <div>$32.23</div>
                        <div className="font-bold">$89.31</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden lg:hidden md:flex border border-zinc-200 mb-4 w-full" />

                <div className="px-8">
                  <button className="btn-outline w-full bg-light-gray border-none">
                    Delete Order
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ViewBag;
