const TipButton = ({ key, onClick, tip, tipValue }) => (
  <button
    key={key}
    type="button"
    onClick={onClick}
    className={`flex btn-style items-center justify-center my-3 p-[10px] px-4 mr-3 ${
      tip == tipValue ? "bg-hh-accent-light" : "bg-background"
    } rounded-3xl`}
  >
    <span
      className={`text-md ${tip == tipValue ? "text-white" : "text-black"}`}
    >
      {tipValue}
      {tipValue != "Other" && "%"}
    </span>
  </button>
);

export default TipButton;
