import React from "react";
import classNames from "classnames";

const DropdownSelect = ({
  options,
  showOptions,
  setValue,
  selectedValue,
  values,
}) => {
  const handleOnClick = (hour) => {
    setValue(hour);
    showOptions(false);
  };

  return (
    <div
      className={classNames(
        "select-dropdown absolute w-[39px] mt-12 ml-6 py-2 bg-white rounded border border-zinc-200 flex-col gap-0.5 overflow-y-auto ",
        {
          "h-[170px]": values === "hours",
          "h-[150px]": values !== "hours",
        }
      )}
    >
      {options.map((option, index) => (
        <div
          className={classNames(
            "text-neutral-700 font-inter-normal self-stretch h-8 px-3 py-2 justify-center items-center flex",
            {
              "bg-slate-50": selectedValue === option,
              "hover:bg-slate-50": selectedValue !== option,
            }
          )}
          key={index}
          onClick={() => handleOnClick(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default DropdownSelect;
