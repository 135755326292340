import React, { useState, useEffect } from "react";
import { NotFound } from "components";
import { Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "assets/img";
import { useNavigate } from "react-router-dom";

const ordersExpanded = [
  {
    type: "uncatering",
    name: "Lunch",
    restaurant: "Greek 2 Go",
    usesOrdered: "2",
    status: "Deadline to order: 10:30 AM",
  },
  {
    type: "catering",
    name: "Lunch",
    restaurant: "Greek 2 Go",
    usesOrdered: "2",
    status: "Deadline to order: 10:30 AM",
  },
  {
    type: "group order",
    name: "Lunch",
    restaurant: "Greek 2 Go",
    usesOrdered: "2",
    status: "Deadline to order: 10:30 AM",
  },
  {
    type: "uncatering",
    name: "Lunch",
    restaurant: "Greek 2 Go",
    usesOrdered: "2",
    status: "Deadline to order: 10:30 AM",
  },
];

const ExpandableRow = ({ order }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="w-full">
      {/* Row Header */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="grid grid-cols-7 w-full bg-light-gray h-[68px] items-center px-[12px] mt-[12px] rounded-md">
          <span
            className={`font-inter-semibold text-[14px] ${
              order.type === "uncatering"
                ? "text-uncatering"
                : order.type === "catering"
                ? "text-catering"
                : "text-group-order"
            }`}
          >
            {order.type}
          </span>
          <span className={`font-inter-semibold text-[14px] text-dark-gray`}>
            {order.name}
          </span>
          <span className={`font-inter-semibold text-[14px] text-dark-gray`}>
            {order.restaurant}
          </span>
          <span className={`font-inter-semibold text-[14px] text-dark-gray`}>
            {order.usesOrdered}
          </span>
          <span className={`font-inter-semibold text-[14px] text-dark-gray`}>
            {order.status}
          </span>

          <div className="d-row items-center justify-center md:px-1">
            <button
              onClick={() => navigate("/home/order-detail")}
              className="btn-outline w-[93px] font-inter-medium border-2 text-catering px-0"
            >
              View order
            </button>
          </div>

          <div className="d-row items-center justify-center">
            <img
              src={isOpen ? ChevronLeftIcon : ChevronRightIcon}
              className="h-[20px] w-[20px] transform-left transform rotate-90"
            />
          </div>
        </div>
      </div>

      {/* Expandable Content */}
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {ordersExpanded?.map((orderExpand) => {
          return (
            <div className="grid grid-cols-7 w-full bg-white h-[68px] items-center px-[12px] mt-[12px] rounded-md">
              <span></span>
              <span></span>
              <span
                className={`font-inter-semibold text-[14px] text-dark-gray`}
              >
                {orderExpand.restaurant}
              </span>
              <span
                className={`font-inter-semibold text-[14px] text-dark-gray`}
              >
                {orderExpand.usesOrdered}
              </span>
              <span
                className={`font-inter-semibold text-[14px] text-dark-gray`}
              >
                {orderExpand.status}
              </span>

              <div></div>

              <div></div>
            </div>
          );
        })}
      </Transition>
    </div>
  );
};

function ActiveMeals({ data }) {
  const [screenSize, setScreenSize] = useState("md");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenSize("sm");
      } else if (window.innerWidth < 1024) {
        setScreenSize("md");
      } else {
        setScreenSize("lg");
      }
    };

    handleResize(); // Initial call to set the initial screen size

    window.addEventListener("resize", handleResize); // Listen for window resize events

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (data) {
    return (
      <div className="flex-col w-full items-center flex">
        <div className="grid grid-cols-7 w-full bg-light-gray h-[46px] items-center px-[12px] rounded-md mt-[24px]">
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Type
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Name
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Restaurant
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            {screenSize === "md" ? "Ordered" : "Users Ordered"}
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray">
            Status
          </span>
          <span className="font-inter-bold text-[14px] text-dark-gray"></span>
          <span className="font-inter-bold text-[14px] text-dark-gray"></span>
        </div>

        <div className=" mx-auto w-full">
          <div className="w-full">
            {data.map((order, index) => (
              <ExpandableRow key={index} order={order} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <NotFound data={null} message="No meals" />

      <button className="btn-blue-accent">Learn about our services</button>
    </div>
  );
}

export default ActiveMeals;
