import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useContext, useEffect, useReducer, useState } from "react";
import SearchBar from "components/searchBar";
import { MobXProviderContext } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faComment } from "@fortawesome/free-solid-svg-icons";
import { Takeaway, Chinese, Wooden } from "assets/img";
import { priceFormatter } from "utils/helpers/validation";
import { useNavigate } from "react-router-dom";
import {
  dateFormat,
  dateTimeFormatLong,
  deadlineFormat,
  longDateFormat,
  longDateFormatWithShortDay,
  smallDateFormat,
  timeFormat,
} from "utils/helpers/dateTime";
import moment from "moment-timezone";
import { toTitleCase } from "utils/helpers/string";
import classNames from "classnames";
import { ORDER_CANCELLATION_HOURS } from "utils/constants/common";
import { getETA } from "utils/helpers/restaurants";
import { getOrderStatus, getOrderStatusForAttendee } from "utils/helpers/order";

const EditPencilButton = ({ onClick }) => (
  <button onClick={onClick} className="flex flex-start btn-style">
    {/* <img src={EditPencil} layout="fixed" /> */}
  </button>
);

const TrashButton = ({ onClick }) => (
  <button onClick={onClick} className="flex items-start btn-style">
    {/* <img src={TrashIconRed} lang="fixed" /> */}
  </button>
);

const ViewOrder = ({ hostname }) => {
  const initialState = {
    orderItemsSearchText: "",
    detailsModalVisible: false,
    cancelOrderModalVisible: null,
    deleteOrderModalVisible: null,
    editOrderItemsModal: false,
    editNameAttachedModalVisible: false,
    attendeeToUpdate: null,
    showCopyLinkBadge: false,
    order: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const [editItemModal, setEditItemModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const router = {};
  let { currentUser } = userStore ?? { name: "abdul" };
  let { cutlery, currentGroupOrder } = groupOrderStore ?? {};
  let { orderItemsSearchText, attendeeToUpdate } = provider;
  let order = {
    attributes: {
      accept_order_hours_in_advance: -1,
      address: "6 Toronto Street Toronto, Ontario, M5C 2V6",
      admin_deadline: "2024-03-20 01:00 PM",
      attendee_deadline: "2024-03-20 10:45 AM",
      attendees: [{}, {}],
      cancellation_deadline: "2024-03-26T13:30:00.000+00:00",
      cutlery: false,
      delivery_fee: "41.0",
      delivery_time: "01:30 PM",
      discounted_amount: "0.0",
      dropoff_task_eta: null,
      expected_attendees: 100,
      formatted_attendee_deadline: "Wed - Mar 20, 2024 - 10:45 am",
      grand_total: "442.57",
      hst: 47.37,
      id: 375,
      location_name: "test",
      menu_code: "fyayd",
      menu_id: 175,
      operating_hours: [{}, {}, {}, {}, {}],
      order_date: "2024-03-29",
      order_id: 3918,
      order_minimum_amount: 150,
      order_slug: "43d44a2240871f0db6c0b300a03607d6",
      per_person_budget: 23,
      provincial_address: "6 Toronto Street Toronto, Ontario",
      restaurant_id: 170,
      restaurant_name: "sultan test menu",
      service_fee: "15.4",
      shipment_acceptance_status: null,
      shipment_id: 5140,
      slug: "23360aa31b6813a75242a889cb71aff6",
      source_of_business: "hungerhub",
      special_hours: [],
      status: "pending",
      subtotal: "308.0",
      tax_name: "HST",
      tip: "30.8",
      title: "This is a testing",
      tzinfo: "America/New_York",
      user_id: 4322,
      user_name: "testing Abdul",
      zone_id: 1,
      type: "group_order",
    },
  };
  const navigate = useNavigate();
  let interval = null;

  useEffect(() => {
    // let { slug } = router?.query;
    // if (slug) {
    //   store.userStore?.setLoader(true);
    //   getOrder();
    //   interval = setInterval(() => {
    //     getOrder();
    //   }, 120000);
    //   return () => {
    //     if (interval) clearInterval(interval);
    //   };
    // }
  }, [router?.isReady]);

  const getOrder = () => {
    let { slug } = router?.query;

    // store.groupOrderStore
    //   ?.getGroupOrderDetails(slug)
    //   .then((response) => {
    //     store.userStore?.setLoader(false);

    //     if (response?.data) {
    //       console.log("response ->", response?.data);
    //       dispatch({ field: "order", value: response?.data });

    //       if (response?.data?.attributes?.status == "inactive") {
    //         store?.groupOrderStore
    //           ?.updateGroupOrder(slug, {
    //             group_order: {
    //               status: "active",
    //             },
    //           })
    //           .then(() => {
    //             getOrder();
    //           });
    //       }
    //     }
    //   })
    //   .catch(() => {
    //     store.userStore?.setLoader(false);
    //   });
  };

  const getURL = () => {
    return `${hostname}/group-order/user/restaurant/${
      order?.attributes?.menu_code
    }/${order?.attributes?.slug}${
      order?.attributes?.source_of_business === "restaurant"
        ? "?utm_source=odc"
        : ""
    }`;
  };

  const copyLinkToDashboard = () => {
    if (order?.attributes) {
      const updatedURL = getURL();

      navigator.clipboard.writeText(updatedURL);

      dispatch({ field: "showCopyLinkBadge", value: true });

      setTimeout(() => {
        dispatch({ field: "showCopyLinkBadge", value: false });
      }, 2000);
    }
  };

  const updateItem = (id, payload) => {
    store.userStore.setLoader(true);
    store?.groupOrderStore?.updateGroupOrderItem(id, payload).then(() => {
      store.userStore.setLoader(false);
      setEditItemModal(false);

      getOrder();
    });
  };

  const onClickRemoveAttendee = (attendee) => {
    dispatch({
      field: "attendeeToUpdate",
      value: attendee,
    });

    dispatch({
      field: "deleteOrderModalVisible",
      value: true,
    });
  };

  const removeAttendee = () => {
    store.userStore.setLoader(true);
    store?.groupOrderStore
      ?.removeAttendee(attendeeToUpdate.id, order?.attributes?.slug)
      .then((response) => {
        store.userStore.setLoader(false);

        if (response?.message == "Attendee removed!") {
          dispatch({
            field: "deleteOrderModalVisible",
            value: null,
          });

          getOrder();
        }
      });
  };

  const handleOnChange = (e) => {
    dispatch({ field: "orderItemsSearchText", value: e?.target?.value });
  };

  const isAbleToCheckout = () =>
    order?.attributes?.grand_total >= order?.attributes?.order_minimum_amount;

  const moveToCheckout = () => {
    const isPastDeadline = moment(
      order?.attributes?.admin_deadline,
      "YYYY-MM-DD hh:mm A"
    ).isBefore(moment());

    const deadlineString = moment(
      order?.attributes?.admin_deadline,
      deadlineFormat
    ).format(`${longDateFormatWithShortDay} ${timeFormat}`);

    if (isPastDeadline) {
      store.userStore.setErrorAlert(true, {
        title: "Deadline passed",
        body: `The deadline to place your order is ${deadlineString}. Please update the deadline in manage details to submit the order.`,
      });
    } else
      router?.push(
        `/group-order/checkout/${currentGroupOrder?.attributes?.slug}`
      );
  };

  const isActiveOrder = () => {
    return order?.attributes?.status == "active";
  };

  const getStatusString = () => {
    switch (order?.attributes?.status) {
      case "completed":
        return "Your order was successfully delivered";
      case "-":
        return `Your group order ${order?.attributes?.title} was not sent to restaurant as it was not checked out in time`;
      case "cancelled":
        return `Your group order ${order?.attributes?.title} has been cancelled`;
      default:
        return `Your group order ${
          order?.attributes?.title
        } is ${getOrderStatus(
          order?.attributes?.status,
          order?.attributes?.shipment_acceptance_status
        )}`;
    }
  };

  const getETAString = () => {
    if (order?.attributes?.dropoff_task_eta) {
      return `Your estimated time of arrival is ${getETA(order)}`;
    }
  };

  const getCancellationDeadline = () => {
    return moment(order?.attributes?.cancellation_deadline)
      .utc()
      .format("dddd Do MMMM YYYY - hh:mma");
  };

  const showCancelButton = () => {
    if (order.attributes.status !== "pending") return false;

    if (parseFloat(order.attributes.grand_total).toFixed(2) > 5000)
      return false;

    let dateTime = moment(
      order.attributes.order_date + " " + order.attributes.delivery_time,
      "YYYY-MM-DD hh:mm A"
    );

    let duration = moment.duration(dateTime.diff(moment()));
    let hours = duration.asHours();

    if (hours < ORDER_CANCELLATION_HOURS) return false;

    return true;
  };

  const onCancelOrder = (id) => {
    store.userStore?.setLoader(true);
    store.cartStore
      ?.cancelOrder(id)
      .then((response) => {
        store.userStore?.setLoader(false);
        dispatch({
          field: "cancelOrderModalVisible",
          value: null,
        });
        store.userStore.setErrorAlert(true, { title: response?.message });
        getOrder();
      })
      .catch(() => {
        store.userStore?.setLoader(false);
      });
  };

  const onCancelGroupOrder = () => {
    store.userStore.setLoader(true);
    store.groupOrderStore
      .cancelGroupOrder(order?.attributes?.id)
      .then((response) => {
        store.userStore.setLoader(false);
        if (
          response?.message?.includes("Order has been cancelled successfully")
        ) {
          store.userStore.setErrorAlert(true, { title: response?.message });
          dispatch({
            field: "cancelOrderModalVisible",
            value: null,
          });
          getOrder();
        }
      });
  };

  const onClickCancelOrder = () => {
    if (order?.attributes?.order_id) {
      onCancelOrder(order?.attributes?.order_id);
    } else {
      onCancelGroupOrder();
    }
  };

  const getFilteredAttendees = (attendees) => {
    const filteredAttendees = attendees?.filter((attendee) => {
      const attendeeName = `${attendee?.first_name ?? ""} ${
        attendee?.last_name ?? ""
      }`?.toLowerCase();

      const matchesMenuItem = attendee?.group_order_items?.some(
        (groupOrderItem) => {
          const menuItemDisplayName =
            groupOrderItem?.attributes?.menu_item?.display_name?.toLowerCase();

          return menuItemDisplayName.includes(
            orderItemsSearchText?.toLowerCase()
          );
        }
      );

      const filterAttendeeByStatus =
        attendee?.order_status === "placed" ||
        attendee?.order_status === "cancelled" ||
        attendee?.order_status === "delivered" ||
        attendee?.order_status === "confirmed";

      if (
        filterAttendeeByStatus &&
        (attendeeName.includes(orderItemsSearchText?.toLowerCase()) ||
          matchesMenuItem)
      ) {
        return attendee;
      }

      return false;
    });

    return filteredAttendees;
  };

  function setAdminCookie() {
    const cookieValue = "true";

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30); // Expires in 30 days

    const cookieString = `admin=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
  }

  const moveToOrderPage = () => {
    setAdminCookie();
    store.userStore.setLoader(true);
    const url = `/group-order/user/restaurant/${order?.attributes?.menu_code}/${
      order?.attributes?.slug
    }?admin=true${
      order?.attributes?.source_of_business === "restaurant"
        ? "&utm_source=odc"
        : ""
    }`;
    store.userStore.setLoader(false);
    window.open(url, "_blank");
  };

  const generatePDF = () => {
    const pdfName = `${order?.attributes?.user_name}_${order?.attributes?.title}_${order?.attributes?.order_date}_${order?.attributes?.id}`;
    store?.groupOrderStore.generatePDF(order?.attributes?.slug, pdfName);
  };

  const totalDetails = (
    <div className="flex flex-col w-full border border-background rounded-[24px] py-6 px-4">
      <div className="flex items-center">
        <span className="text-md2 font-inter-medium">
          {order?.attributes?.restaurant_name}
        </span>
      </div>

      <div className="mt-5">
        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Items</span>

          <span className="text-md">
            {priceFormatter(order?.attributes?.subtotal)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <div className="flex items-center">
            <span className="text-md">Service Fee</span>
            {/* <TooltipNew
              grayIcon
              tooltipText="A service fee of five percent of total item charges is inclusive of all hungerhub charges"
              id="service-tooltip"
              backgroundColor="white"
              textColor="black"
              place="bottom"
            /> */}
          </div>

          <span className="text-md">
            {priceFormatter(order?.attributes?.service_fee)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <div className="flex items-center">
            <span className="text-md">Delivery</span>
            {/* <TooltipNew
              grayIcon
              tooltipText="Your delivery cost is dependent on restaurant and proximity to your delivery address"
              id="delivery-tooltip"
              backgroundColor="white"
              textColor="black"
              place="bottom"
            /> */}
          </div>

          <span className="text-md">
            {priceFormatter(order?.attributes?.delivery_fee)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Tax</span>

          <span className="text-md">
            {priceFormatter(order?.attributes?.hst)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Tip</span>

          <span className="text-md">
            {priceFormatter(order?.attributes?.tip)}
          </span>
        </div>

        {order?.attributes?.discounted_amount > 0 && (
          <div className="flex w-full items-center justify-between mt-3">
            <span className="text-md">Discount</span>

            <span className="text-md">
              -{priceFormatter(order?.attributes?.discounted_amount)}
            </span>
          </div>
        )}
      </div>

      <div className="flex w-full items-center justify-between mt-6 mb-16 md:mb-6 border-t border-border-gray pt-6">
        <span className="text-md">Total</span>

        <span className="text-md">
          {priceFormatter(order?.attributes?.grand_total)}
        </span>
      </div>

      {isActiveOrder() ? (
        <div className="bg-background p-2 rounded-md">
          <label className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer">
            <span className="ml-1 font-inter-regular text-sm w-40">
              Request cutlery
            </span>

            <input
              autoComplete="off"
              type="checkbox"
              className="form-checkbox"
              onChange={(e) =>
                store.groupOrderStore.updateCutlery(e.target.checked)
              }
              checked={cutlery}
            />
          </label>
        </div>
      ) : (
        order?.attributes?.cutlery && (
          <span className="font-inter-regular text-sm bg-background p-3 rounded-md w-full">
            Cutlery requested
          </span>
        )
      )}

      {isActiveOrder() && (
        <button
          onClick={moveToCheckout}
          disabled={!isAbleToCheckout()}
          className={classNames({
            "btn-purple mt-5 py-3 md:h-12": true,
            "hover:!bg-hh-accent-dark !text-secondary-text":
              !isAbleToCheckout(),
          })}
        >
          {isAbleToCheckout() ? (
            <span className="text-sm font-inter-medium">Place Order</span>
          ) : (
            <span className="text-sm font-inter-medium">
              {priceFormatter(order?.attributes.grand_total)} • Minimum order
              total: {priceFormatter(order?.attributes?.order_minimum_amount)}
            </span>
          )}
        </button>
      )}
    </div>
  );

  const onClickManageDetails = () => {
    const isPastAdminDeadline = moment(
      order?.attributes?.admin_deadline,
      deadlineFormat
    ).isBefore(moment());

    if (isPastAdminDeadline) {
      let payload = {
        menu_id: order?.attributes?.menu_id,
        attendee: Math.abs(order?.attributes?.expected_attendees),
        budget_per_attendee: order?.attributes?.per_person_budget,
        selected_date: moment(order.attributes?.order_date).format(dateFormat),
        current_date_time: moment().format(dateTimeFormatLong),
        delivery_time: moment(
          order?.attributes?.delivery_time,
          "hh:mm A"
        ).format(timeFormat),
        admin: true,
        admin_date: moment(
          new Date(moment(order?.attributes?.admin_deadline, deadlineFormat))
        ).format(dateFormat),
      };

      store.userStore.setLoader(true);
      store?.groupOrderStore.getTimesForGroupOrder(payload).then((response) => {
        store.userStore.setLoader(false);

        if (response?.timeslots?.length == 0) {
          store.userStore?.setErrorAlert(true, {
            title: "Deadline Passed",
            body: "The deadline to submit this order has passed, please start a chat with our support team to see if the order can still be placed.",
          });
        } else {
          dispatch({ field: "detailsModalVisible", value: true });
        }
      });
    } else {
      dispatch({ field: "detailsModalVisible", value: true });
    }
  };

  const manageDetailsButton = (
    <button
      onClick={onClickManageDetails}
      className="btn-style bg-primary-dark hover:bg-hh-accent-dark items-center justify-center text-white text-md py-3 px-4 rounded-[8px] mr-2 font-inter-medium"
    >
      Manage details
    </button>
  );

  const isFutureDeliveryDate = order
    ? moment(
        `${order?.attributes?.order_date} ${order?.attributes?.delivery_time}`,
        deadlineFormat
      ).isAfter(moment())
    : false;

  return (
    <div className="w-full">
      {!currentUser && order && (
        <div className="flex flex-col justify-center mx-auto p-5 md:p-[40px] md:pt-[32px]">
          <div className="flex">
            <button
              className="flex items-center cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                style={{ width: 14, height: 14 }}
              />
              <span className="text-md text-left font-inter-medium text-dark-gray ml-2">
                Back
              </span>
            </button>
          </div>

          <strong className="font-inter-semibold text-[32px] text-dark-gray my-[24px]">
            Greek 2 Go
          </strong>

          <div className="grid grid-cols-4 gap-2 text-sm mb-4 bg-light-gray p-[12px] rounded-lg">
            <div className="d-col border-r">
              <small className="font-inter-regular text-[14px] text-dark-gray">
                Date
              </small>
              <small className="font-inter-medium text-[16px] text-dark-gray">
                [DDD], [MONTH] [DD], [YYYY]
              </small>
            </div>
            <div className="d-col border-r">
              <small className="font-inter-regular text-[14px] text-dark-gray">
                Meal{" "}
              </small>
              <small className="font-inter-medium text-[16px] text-dark-gray">
                hungerhub staff happy hour
              </small>
            </div>
            <div className="d-col border-r">
              <small className="font-inter-regular text-[14px] text-dark-gray">
                Cutlery{" "}
              </small>
              <small className="font-inter-medium text-[16px] text-dark-gray">
                Yes
              </small>
            </div>
            <div className="d-col border-r">
              <small className="font-inter-regular text-[14px] text-dark-gray">
                Status{" "}
              </small>
              <small className="font-inter-medium text-[16px] text-dark-gray">
                Sent
              </small>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-6">
            {isActiveOrder() ? (
              <>
                <div className="flex flex-col md:flex-row md:items-center">
                  <span className="text-[24px] font-inter-semibold mr-3">
                    {order?.attributes?.title}
                  </span>

                  <button
                    type="button"
                    onClick={copyLinkToDashboard}
                    className="flex btn-style items-center mt-3 md:mt-0"
                  >
                    {/* <img src={CopyLink} layout="fixed" /> */}

                    <span className="text-hh-accent-light text-md font-inter-semibold ml-2">
                      Copy invitation link
                    </span>
                  </button>
                </div>

                <div className="flex justify-between mt-6 sm:mt-0">
                  {manageDetailsButton}

                  <button
                    onClick={() =>
                      dispatch({
                        field: "cancelOrderModalVisible",
                        value: true,
                      })
                    }
                    className="btn-style border-primary-dark border hover:bg-hh-accent-dark items-center justify-center text-md text-primary-dark hover:text-white py-3 px-4 rounded-[8px] font-inter-medium"
                  >
                    Cancel Order
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex">
                  {/* {showCancelButton() && (
                    <button
                      className="mr-3 btn-style"
                      onClick={() =>
                        dispatch({
                          field: "cancelOrderModalVisible",
                          value: true,
                        })
                      }
                    >
                      <span className="text-hh-accent-light text-[15px] font-inter-medium">
                        Cancel Order
                      </span>
                    </button>
                  )}

                  {order?.attributes?.status == "cancelled" &&
                    isFutureDeliveryDate &&
                    manageDetailsButton}

                  {order?.attributes?.status !== "cancelled" && (
                    <button className="btn-purple" onClick={generatePDF}>
                      Download PDF receipt
                    </button>
                  )} */}
                </div>
              </>
            )}
          </div>

          <div className="px-[16px] py-[24px] d-row justify-between bg-light-gray rounded-lg">
            <div className="d-col">
              <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
                Food total
              </small>
              <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
                Tip
              </small>
              <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
                Tax
              </small>
              <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
                Grand total
              </small>
            </div>

            <div className="d-col">
              <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
                $17.37
              </small>
              <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
                $17.37
              </small>
              <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
                $17.37
              </small>
              <small className="mt-[14px] font-inter-semibold text-[14px] text-dark-gray">
                $17.37
              </small>
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full mt-8">
            <div className="flex md:hidden">{totalDetails}</div>

            <div className="flex flex-col w-full ">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center w-full md:gap-9">
                {isActiveOrder() && (
                  <button
                    onClick={moveToOrderPage}
                    className="btn-style w-full sm:w-auto  min-w-[110px] h-12 bg-primary-dark hover:bg-hh-accent-dark text-white font-inter-medium text-md rounded-[8px] py-3 px-4 items-center justify-center mr-2"
                  >
                    Add items
                  </button>
                )}

                <div className="d-row justify-between">
                  <div className="bg-light-gray rounded-lg d-row p-[12px] min-w-[175px] mr-[12px]">
                    <img
                      src={Takeaway}
                      className="w-[38px] h-[38px] object-contain mr-[16px]"
                    />
                    <div className="d-col">
                      <small>Bags</small>
                      <small>17</small>
                    </div>
                  </div>

                  <div className="bg-light-gray rounded-lg d-row p-[12px] min-w-[175px] mr-[12px]">
                    <img
                      src={Chinese}
                      className="w-[38px] h-[38px] object-contain mr-[16px]"
                    />
                    <div className="d-col">
                      <small>Items</small>
                      <small>17</small>
                    </div>
                  </div>

                  <div className="bg-light-gray rounded-lg d-row p-[12px] min-w-[175px]">
                    <img
                      src={Wooden}
                      className="w-[38px] h-[38px] object-contain mr-[16px]"
                    />
                    <div className="d-col">
                      <small>Cutlery</small>
                      <small>17</small>
                    </div>
                  </div>
                </div>

                <div className="d-row justify-between">
                  <SearchBar
                    value={orderItemsSearchText}
                    handleOnChange={handleOnChange}
                    placeholder="Search"
                    className="w-full !mr-0 mt-6 sm:mt-0"
                    width="lg:w-[274px] md:w-4/5"
                  />

                  <button className="btn-blue-accent ml-[12px]">
                    Export PDF
                  </button>
                </div>
              </div>

              <div className="flex mt-[24px] items-center bg-light-grey rounded-[4px] bg-light-gray px-3 py-6">
                <span className="w-1/3 sm:w-2/12 text-sm font-inter-medium">
                  Name
                </span>

                <span className="hidden sm:flex sm:w-[100px] text-sm font-inter-medium ml-2">
                  Quantity
                </span>

                <span className="w-2/3 sm:w-4/12 text-sm font-inter-medium">
                  Item
                </span>

                <span className="hidden sm:flex sm:w-2/12 text-sm font-inter-medium">
                  Status
                </span>

                <span className="hidden sm:flex sm:w-2/12 text-sm font-inter-medium">
                  Food Total
                </span>

                {isActiveOrder() && (
                  <span className="hidden sm:flex w-2/12 text-sm font-inter-medium"></span>
                )}
              </div>

              {getFilteredAttendees(order?.attributes?.attendees)?.map(
                (attendee, index) => (
                  <div
                    key={`attendee-order-${index}`}
                    className="flex w-full my-4 px-2"
                  >
                    <div className="flex flex-col w-1/3 sm:w-2/12">
                      <span className="text-md font-inter-semibold mb-2">
                        {attendee?.first_name} {attendee?.last_name}
                      </span>

                      {isActiveOrder() && (
                        <EditPencilButton
                          onClick={() => {
                            dispatch({
                              field: "attendeeToUpdate",
                              value: attendee,
                            });

                            dispatch({
                              field: "editNameAttachedModalVisible",
                              value: true,
                            });
                          }}
                        />
                      )}
                    </div>

                    <div className="flex flex-col w-2/3 sm:w-6/12">
                      {attendee?.group_order_items?.map((orderItem, index) => (
                        <div className="flex">
                          <div className="hidden sm:flex sm:w-[100px] ml-2">
                            <span className="text-md font-inter-semibold text-center">
                              {orderItem?.attributes?.quantity}
                            </span>
                          </div>

                          <div
                            key={`${attendee?.first_name}-order-item-${index}`}
                            className="flex w-full flex-col mb-6 mr-2"
                          >
                            <div className="flex w-full">
                              {isActiveOrder() && (
                                <EditPencilButton
                                  onClick={() => {
                                    setEditItem(orderItem);
                                    setEditItemModal(true);
                                  }}
                                />
                              )}

                              <div className="flex flex-col ml-[6px]">
                                <span className="text-sm font-inter-semibold">
                                  {/* <EmbedHTML
                                    text={
                                      orderItem?.attributes?.menu_item
                                        ?.display_name_with_html
                                    }
                                    className="text-primary-black capitalize"
                                  /> */}
                                </span>

                                {orderItem?.attributes?.group_order_item_options?.map(
                                  (option, index) => (
                                    <div className="flex" key={option.id}>
                                      <span className="flex flex-col text-sm">
                                        {/* <EmbedHTML
                                          isRow
                                          text={
                                            option?.attributes
                                              ?.option_group_display_name_with_html
                                          }
                                          appendClass="mr-1"
                                          className="text-primary-black font-inter-medium"
                                        />

                                        <EmbedHTML
                                          text={
                                            option?.attributes
                                              ?.option_display_name_with_html
                                          }
                                          className="text-primary-black"
                                        /> */}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>

                            {orderItem?.attributes?.special_instructions && (
                              <div className="flex items-center ">
                                <div
                                  className="flex"
                                  style={{ width: 12, height: 12 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faComment}
                                    size="1x"
                                    className="small-icon"
                                  />
                                </div>

                                <span className="text-sm ml-2">
                                  Instructions:{" "}
                                  <span className="font-inter-medium">
                                    {
                                      orderItem?.attributes
                                        ?.special_instructions
                                    }
                                  </span>
                                </span>
                              </div>
                            )}

                            <div className="flex sm:hidden mt-6">
                              {isActiveOrder() && (
                                <TrashButton
                                  onClick={() =>
                                    onClickRemoveAttendee(attendee)
                                  }
                                />
                              )}

                              <span className="text-attention text-sm ml-2">
                                Delete Order
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="hidden sm:flex sm:w-2/12 text-md">
                      {toTitleCase(
                        getOrderStatusForAttendee(
                          attendee?.order_status,
                          order?.attributes?.status
                        )
                      )}
                    </div>

                    <div className="hidden sm:flex sm:w-2/12 text-md">
                      {priceFormatter(attendee?.food_total)}
                    </div>

                    {isActiveOrder() && (
                      <div className="hidden sm:flex sm:w-2/12">
                        <TrashButton
                          onClick={() => onClickRemoveAttendee(attendee)}
                        />
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(ViewOrder);
