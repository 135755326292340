import {
  faAngleDown,
  faAngleLeft,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AltImage } from "assets/img";
import classNames from "classnames";
// import { client } from "client";
// import api from "client/api";
import AddItemModal from "components/AddItemModal";
import Cart from "components/Cart";
import ConfirmationModal from "components/ConfirmationModal";
// import DeliveryPickupModal from "components/DeliveryPickupModal";
// import Filters from "components/Filters/Filters";
import MenuItem from "components/MenuItem";
import EmbedHTML from "components/shared/embedHTML";
import TagsFilterModal from "components/TagsFilterModal";
import Tooltip from "components/Tooltip";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment-timezone";

import qs from "qs";
import { useContext, useEffect, useReducer, useState } from "react";
import badge from "utils/helpers/badge";
import { checkIsDateAvailable } from "utils/helpers/dateTime";
import {
  changeSpacesToHyphens,
  getOperatingHours,
} from "utils/helpers/restaurants";
import Screen from "utils/helpers/ScreenSize";
import { toTitleCase } from "utils/helpers/string";
import { MobXProviderContext } from "mobx-react";
import ImageLightBox from "components/ImageLightBox/ImageLightBox";
import { isPresent } from "utils/helpers/array";
import CategoryTitle from "components/CategoryTitle";
import CategorySidebar from "components/CategorySidebar";
import { ODC_TAGLINE_LENGTH } from "utils/constants/common";
import StartGroupOrderBadge from "components/StartGroupOrderBadge/StartGroupOrderBadge";
import { useLocation } from "react-router-dom";

const MAX_FILTER_TAGS_TO_SHOW = 5;

const restaurantDetail = {
  id: "405",
  type: "menu",
  attributes: {
    id: 405,
    name: "Copy main menu",
    code: "xnhhp",
    tagline_odc_short: "Main Copy menu",
    available_for_direct_catering: true,
    available_for_hungerhub_catering: true,
    background_image_url: null,
    contact_number: "+19025550313",
    cuisine_tags: ["African", "All-day breakfast"],
    display_name: "Copy main menu",
    featured_badges: [],
    image_url:
      "https://nbetesting.hungerhub.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBclFEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d34ff980795cca521fdd331cc6cf512734aca47d/1685018678164-img",
    market: {
      id: "1",
      type: "market",
      attributes: {
        code: "TOR",
        name: "Toronto",
        tax_rate: "13.0",
        timezone: "Eastern Time (US & Canada)",
        tzinfo: "America/New_York",
      },
    },
    menu_items_badges: ["GLUTEN FREE"],
    minimum_order_preferences: [
      {
        id: 368,
        menu_id: 405,
        required_notice_time: 0,
        minimum_amount: 150,
      },
    ],
    next_available_date: "2024-02-19",
    operating_hours: [
      {
        id: "3414",
        type: "operating_hour",
        attributes: {
          day: "monday",
          active: true,
          from: "07:00:00",
          to: "19:00:00",
        },
      },
      // Additional operating hours omitted for brevity
    ],
    order_minimum_amount: 150,
    packaging_tags: [],
    restaurant_address: "2 Toronto Street, Toronto, Ontario",
    restaurant_id: 232,
    special_hours: [],
    tagline: "Main Copy menu",
    tags: ["100% Vegan"],
    tzinfo: "America/New_York",
    zone_id: 1,
    categories: [
      {
        attributes: {
          id: "1244",
          type: "menu_category",
          availability: "active",
          description:
            "Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description",
          description_with_html:
            "<p>Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description</p>",
          display_name: "Catergoy 1",
          display_name_with_html: "<p>Catergoy 1</p>",
          menu_items: [
            {
              id: "4704",
              type: "menu_item",
              attributes: {
                id: 4704,
                title: "item 1",
                price: "50.0",
                position: 2,
                availability: "active",
                description: "item 1",
                description_with_html: "<p>item 1</p>",
                display_name: "item 1",
                display_name_with_html: "<p>item 1</p>",
                image_url: null,
              },
            },
          ],
        },
      },
      {
        attributes: {
          id: "12441",
          type: "menu_category",
          availability: "active",
          description:
            "Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description",
          description_with_html:
            "<p>Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description Testing category description</p>",
          display_name: "Catergoy 2",
          display_name_with_html: "<p>Catergoy 2</p>",
          menu_items: [
            {
              id: "4704",
              type: "menu_item",
              attributes: {
                id: 4704,
                title: "item 1",
                price: "50.0",
                position: 2,
                availability: "active",
                description: "item 1",
                description_with_html: "<p>item 1</p>",
                display_name: "item 1",
                display_name_with_html: "<p>item 1</p>",
                image_url: null,
              },
            },
          ],
        },
      },
      // Additional categories omitted for brevity
    ],
    address_attributes: {
      suite: "123",
      street_number: "2",
      street_name: "Toronto Street",
      city: "Toronto",
      province: "Ontario",
      postal_code: "M5C 2B5",
    },
  },
};

const itemDetail = {
  type: "menu_item",
  id: "4704",
  attributes: {
    id: 4704,
    title: "item 1",
    price: "50.0",
    availability: "active",
    image_url: null,
    badges: [],
    description: "item 1",
    description_with_html: "<p>item 1</p>",
    display_name: "item 1",
    display_name_with_html: "<p>item 1</p>",
    option_categories: [
      {
        type: "option_group",
        id: "3785",
        attributes: {
          id: 3785,
          name: "option group 1",
          choose_upto: 10,
          position: 2,
          required: true,
          availability: "active",
          display_name: "option group 1",
          display_name_with_html: "<p>option group 1</p>",
          options: [
            {
              type: "option",
              id: "11878",
              attributes: {
                id: 11878,
                name: "Internal option 1",
                position: 2,
                price: "30.0",
                availability: "active",
                display_name: "Internal option 1",
                display_name_with_html: "<p>Internal option 1</p>",
                is_changed: false,
                is_template: false,
                option_template_id: 11551,
              },
            },
          ],
        },
      },
      {
        type: "option_group",
        id: "3788",
        attributes: {
          // Attributes for the second option group would go here
        },
      },
    ],
  },
};

const RestaurantPage = () => {
  const initialState = {
    restaurantId: "",
    restaurantCategoriesAndItems: [],
    restaurantCategoriesAndItemsFiltered: [],
    isFetchingRestaurantMenu: true,
    selectedCategory: null,
    openedItems: null,
    selectedItem: null,
    activeMenuItemTags: [],
    showAllTags: false,
    tagsLengthToShow: MAX_FILTER_TAGS_TO_SHOW,
    menuItems: [],
    activeMenuItemImage: null,
    isInitialCartFetched: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const [deliveryPickupModal, setDeliveryPickupModal] = useState({
    state: false,
    fromCart: false,
  });
  const [emptyCartAlert, setEmptyCartAlert] = useState(null);
  const [isGoBack, setIsGoBack] = useState(false);
  const [addressValidationAlert, setAddressValidationAlert] = useState(null);
  const [dateValidationAlert, setDateValidationAlert] = useState(null);
  const [addItemModal, setAddItemModal] = useState(false);
  const [imageLightboxModal, setImageLightBox] = useState(false);
  const [internalAddressBrowseButtonPress, setInternalAddressForBrowseButton] =
    useState(false);
  const store = useContext(MobXProviderContext);
  const userStore = null;
  const cartStore = toJS(store?.cartStore);
  const location = useLocation();

  const isGroupMealRestaurantPage = location.pathname.startsWith(
    "/get-started-group/restaurant"
  );

  const router = null;
  let {
    address,

    isLoading,
    currentUser,
    deliveryPickupModalGlobal,
    completeAddress,
  } = userStore ?? {};

  let { unCheckedOutOrder, cart } = cartStore ?? {};
  let {
    restaurantCategoriesAndItems,
    activeMenuItemTags,
    restaurantCategoriesAndItemsFiltered,
    selectedCategory,
    tagsLengthToShow,
    showAllTags,
    activeMenuItemImage,
    isInitialCartFetched,
  } = provider;

  let backQuery = qs.parse(router?.query);
  delete backQuery["id"];
  delete backQuery["name"];
  delete backQuery["utm_source"];

  const screenType = Screen.ScreenType();

  useEffect(() => {
    store.userStore?.setLoader(true);
  }, []);

  useEffect(() => {
    setDeliveryPickupModal(deliveryPickupModalGlobal);
  }, [deliveryPickupModalGlobal?.state]);

  useEffect(() => {
    // if (!router?.isReady || !store.userStore?.isHydrated) return;

    if (restaurantDetail) {
      store.userStore?.setLoader(false);

      setDateAndTime();

      store?.restaurantStore?.setActiveRestaurant(restaurantDetail);

      if (isOldCartAvail()) {
        setEmptyCartAlert(true);
      }

      dispatch({
        field: "restaurantCategoriesAndItems",
        value: filterCategories(restaurantDetail?.attributes?.categories),
      });

      dispatch({
        field: "restaurantCategoriesAndItemsFiltered",
        value: filterCategories(restaurantDetail?.attributes?.categories),
      });

      dispatch({
        field: "selectedCategory",
        value: restaurantDetail?.attributes?.categories[0]?.id,
      });

      dispatch({
        field: "isFetchingRestaurantMenu",
        value: false,
      });

      store.userStore?.setLoader(false);
    }
  }, []); //router?.isReady, store.userStore?.isHydrated, router?.query?.id

  useEffect(() => {
    // if (!store.userStore?.isHydrated || !router?.isReady) return;

    if (cart?.cartDate && cart?.cartTime)
      if (isFutureDate()) {
        if (
          !checkIsDateAvailable(
            restaurantDetail,
            cart?.cartDate,
            cart?.cartTime
          )
        ) {
          setDateValidationAlert(true);

          return;
        }
      } else
        store?.cartStore?.saveCartDetails({
          cartDate: null,
          cartTime: null,
        });
  }, [
    store.userStore?.isHydrated,
    router?.isReady,
    cart?.cartDate,
    cart?.cartTime,
  ]);

  const setDateAndTime = () => {
    let { date, time, address } = router?.query ?? {};

    if (date && time && !doesCartHasItems()) {
      store?.cartStore?.saveCartDetails({
        cartDate: date,
        cartTime: time,
      });

      store?.userStore?.setAddressOnly(address);
    } else if (!date && !time) {
      store?.cartStore?.saveCartDetails({
        cartDate: null,
        cartTime: null,
      });
    }
  };

  useEffect(() => {
    if (!router?.isReady) return;
    if (!store?.userStore?.isHydrated) return;

    let { id, utm_source, date, time } = router?.query;

    if (!doesCartHasItems() || isSameCart()) {
      if (date && time) {
        getRestaurant(id, {
          utm_source: utm_source ? true : false,
          current_date_time: moment().format("YYYY-MM-DD HH:mm"),
          date,
          time,
        });
      }
    }
  }, [router?.query?.date, store?.userStore?.isHydrated]);

  useEffect(() => {
    if (
      unCheckedOutOrder?.id &&
      store?.userStore?.isHydrated &&
      !isInitialCartFetched
    ) {
      store.cartStore?.getCartItems(unCheckedOutOrder?.id);
      dispatch({ field: "isInitialCartFetched", value: true });
    }

    if (isOldCartAvail() && store?.userStore?.isHydrated)
      setEmptyCartAlert(true);
  }, [router?.isReady && unCheckedOutOrder?.id, store?.userStore?.isHydrated]);

  useEffect(() => {
    if (isPresent(activeMenuItemTags)) {
      let filteredCategoriesAndItems = restaurantCategoriesAndItems?.map(
        (category) => {
          return {
            ...category,
            attributes: {
              ...category.attributes,
              menu_items: category?.attributes?.menu_items?.filter(
                (menuItem) => {
                  const found = menuItem.attributes?.badges.some((b) =>
                    activeMenuItemTags.includes(b)
                  );

                  if (found) return menuItem;
                }
              ),
            },
          };
        }
      );

      dispatch({
        field: "restaurantCategoriesAndItemsFiltered",
        value: filterCategories(filteredCategoriesAndItems),
      });
    } else {
      dispatch({
        field: "restaurantCategoriesAndItemsFiltered",
        value: filterCategories(restaurantCategoriesAndItems),
      });
    }
  }, [activeMenuItemTags, restaurantCategoriesAndItems]);

  const isSameCart = () => {
    return (
      cart?.restaurant &&
      router?.query?.id === cart?.restaurant?.attributes?.code
    );
  };

  const isOldCartAvail = () => {
    return (
      cart?.restaurant &&
      router?.query?.id !== cart?.restaurant?.attributes?.code &&
      isPresent(cart?.items) &&
      toJS(store?.cartStore?.unCheckedOutOrder) &&
      toJS(store?.cartStore?.unCheckedOutOrder.id)
    );
  };

  const doesCartHasItems = () => {
    return cart?.items && isPresent(cart?.items);
  };

  const filterCategories = (filteredCategoriesAndItems) => {
    return filteredCategoriesAndItems.filter((category) =>
      isPresent(category?.attributes?.menu_items)
    );
  };

  const getRestaurant = (id, payload) => {
    store.restaurantStore
      ?.getRestaurantDetails(id, payload)
      .then(({ data }) => {
        if (data?.attributes) {
          store?.restaurantStore.setActiveRestaurant(data);
        }
      });
  };

  const setSelectedCategory = (id) => {
    window.scrollTo({
      top:
        document.getElementById(`cat-${id}`).offsetTop -
        (screenType?.isDesktop ? 20 : 180),

      behavior: "smooth",
    });

    setTimeout(() => {
      dispatch({
        field: "selectedCategory",
        value: id,
      });
    }, 700);
  };

  const checkAddressAndOpenModal = () => {
    setAddItemModal(true);
    return; // FOR ABDUL TO REMOVE THIS RETURN AND ABOVE LINE IN ACTUAL
    if (address && completeAddress?.city && cart?.cartDate && cart?.cartTime)
      setAddItemModal(true);
    else setDeliveryPickupModal({ state: true, fromCart: false });
  };

  const getItemOptions = (id, categoryId) => {
    dispatch({
      field: "selectedItem",
      value: id,
    });

    if (!provider?.openedItems?.[id]) {
      dispatch({
        field: "openedItems",
        value: {
          ...provider?.openedItems,
          [id]: { ...itemDetail, menuCategoryId: categoryId },
        },
      });

      checkAddressAndOpenModal();
      //   store.userStore?.setLoader(true);
      //   store.restaurantStore?.getMenuItemOptions(id).then(({ data }) => {
      //     dispatch({
      //       field: "openedItems",
      //       value: {
      //         ...provider?.openedItems,
      //         [id]: { ...data, menuCategoryId: categoryId },
      //       },
      //     });
      //     store?.userStore?.setLoader(false);
      //     checkAddressAndOpenModal();
      //   });
    } else checkAddressAndOpenModal();
  };

  const isFutureDate = () => {
    const date = cart?.cartDate;
    const time = cart?.cartTime;

    return moment(
      moment(date).format("YYYY-MM-DD") + " " + time,
      "YYYY-MM-DD h:mm a"
    ).isAfter(moment());
  };

  const addItemToCart = (payload) => {
    if (!isFutureDate()) {
      store?.userStore?.setErrorAlert(true, {
        title:
          "You have selected a past date/time, please enter a valid future date to continue",
      });

      return;
    }

    store?.cartStore?.saveCartDetails({
      restaurant: restaurantDetail,
    });

    let unCheckedOutOrderPayload = {
      first_name: "",
      email: "",
      phone: "",
      source_of_business: router?.query?.utm_source
        ? "restaurant"
        : "hungerhub",
      restaurant_id: restaurantDetail?.attributes?.restaurant_id,
      menu_id: restaurantDetail?.attributes?.id,
      status: "menu",
      order_date: moment(cart?.cartDate).format("DD-MM-YYYY"),
      order_time: cart?.cartTime,
      user_agent: window.navigator.userAgent,
      uncheckedout_order: {
        order_items_attributes: [payload],
      },
    };

    if (!unCheckedOutOrder) {
      if (currentUser) unCheckedOutOrderPayload.user_id = currentUser.id;

      store.userStore.setLoader(true);
      store?.cartStore
        ?.saveUncheckedOutOrder(unCheckedOutOrderPayload)
        .then((response) => {
          store.userStore.setLoader(false);
          if (response?.order) {
            store?.cartStore?.getCartItems(response?.order?.id);
            setAddItemModal(false);
          }
        })
        .catch(() => {
          store.userStore.setLoader(false);
        });
    } else {
      addToCart(
        {
          uncheckedout_order_id: unCheckedOutOrder?.id,
          order_item: payload,
          order_date: moment(cart?.cartDate).format("DD-MM-YYYY"),
          order_time: cart?.cartTime,
        },
        unCheckedOutOrderPayload
      );
    }
  };

  const markItemAsSoldOut = (menuCategoryId, menuItemId) => {
    dispatch({
      field: "restaurantCategoriesAndItemsFiltered",
      value: restaurantCategoriesAndItemsFiltered?.map((category) => {
        if (category?.attributes.id === Number(menuCategoryId)) {
          return {
            ...category,
            attributes: {
              ...category?.attributes,
              menu_items: category?.attributes?.menu_items?.map((menuItem) => {
                if (menuItem?.attributes?.id === Number(menuItemId)) {
                  return {
                    ...menuItem,
                    attributes: {
                      ...menuItem?.attributes,
                      availability: "sold_out",
                    },
                  };
                }

                return menuItem;
              }),
            },
          };
        }

        return category;
      }),
    });
  };

  const addToCart = (payload, unCheckedOutOrderPayload) => {
    store.userStore.setLoader(true);
    store?.cartStore
      ?.addItemToCart(payload, unCheckedOutOrderPayload)
      .then((data) => {
        store.userStore.setLoader(false);
        setAddItemModal(false);

        if (data?.response?.data?.sold_out) {
          markItemAsSoldOut(
            payload?.order_item?.menu_category_id,
            payload?.order_item?.menu_item_id
          );
        }

        if (unCheckedOutOrder?.id)
          store?.cartStore?.getCartItems(unCheckedOutOrder?.id);
      });
  };

  const onClickEmptyCart = () => {
    store?.userStore?.setLoader(true);

    if (cart?.stripeSessionId)
      store.cartStore?.expireSession(cart?.stripeSessionId).then(() => {
        clearCart();
      });
    else clearCart();
  };

  const clearCart = () => {
    store?.cartStore?.removeAllCartItems(unCheckedOutOrder?.id).then(() => {
      store?.userStore?.setLoader(false);

      store?.cartStore?.saveCartDetails({
        cartDate: router?.query?.date,
        cartTime: router?.query?.time,
      });

      setEmptyCartAlert(null);
    });
  };

  const onClickMoveToCart = () => {
    store.userStore.setLoader(true);
    setEmptyCartAlert(null);

    router.replace(
      `/restaurant/${
        cart?.restaurant?.attributes?.code
      }/${changeSpacesToHyphens(cart?.restaurant?.attributes?.name)}${
        cart?.data?.attributes?.source_of_business == "restaurant"
          ? "?utm_source=odc"
          : ""
      }`
    );
  };

  const onClickMenuButtonInAlert = () => {
    setAddressValidationAlert(null);

    setIsGoBack(false);
  };

  const onClickGoToRestaurants = () => {
    setAddressValidationAlert(null);

    if (internalAddressBrowseButtonPress) {
      store?.userStore?.setAddress(
        internalAddressBrowseButtonPress?.address,
        internalAddressBrowseButtonPress?.completeAddress
      );
    }

    router.push({
      pathname: "/",
      query: { ...qs.parse(backQuery) },
    });
  };

  const onClickChangeDate = () => {
    setDateValidationAlert(null);

    setTimeout(
      () => setDeliveryPickupModal({ state: true, fromCart: false }),
      500
    );
  };

  const setImage = (image) => {
    setImageLightBox(true);

    dispatch({
      field: "activeMenuItemImage",
      value: image,
    });
  };

  const backButton = (
    <button
      onClick={() => {
        router?.push({ pathname: "/", query: { ...qs.parse(backQuery) } });
      }}
      className="flex btn-style items-center"
    >
      <div className="flex" style={{ width: 16, height: 16 }}>
        <FontAwesomeIcon icon={faAngleLeft} size="1x" className="icon" />
      </div>

      <span className="text-md ml-3">Go back to restaurants</span>
    </button>
  );

  const resetButton = (
    <button
      onClick={() =>
        dispatch({
          field: "activeMenuItemTags",
          value: [],
        })
      }
      className="flex mr-3 btn-style h-[22px] items-center bg-white border-border-gray border rounded-3xl px-3"
    >
      <span className="px-2 font-inter-medium text-sm">Reset</span>
    </button>
  );

  const getDescription = () => {
    return `Order catering from ${restaurantDetail?.attributes?.display_name} delivered to your office, workplace or corporate event.`;
  };

  return (
    <>
      <div
        className="flex flex-col border-t-2 border-light-gray bg-white min-h-screen mx-auto"
        style={{ maxWidth: "1440px" }}
      >
        <div className="flex flex-col md:flex-row w-full">
          <div className="hidden md:flex flex-col md:w-2/12 sticky top-[0.5rem] max-h-[calc(100vh-100px)] overflow-auto sm:p-4 sm:px-8 md:px-4">
            {!router?.query?.utm_source && backButton}

            <div
              className={classNames({
                "flex flex-col bg-light-gray rounded-xl p-4": true,
                "mt-4": !router?.query?.utm_source,
              })}
            >
              <span className="text-xl text-primary-black font-inter-medium pb-4">
                Menu
              </span>

              {restaurantCategoriesAndItemsFiltered?.map((category, index) => (
                <CategorySidebar
                  index={index}
                  key={`${index}-desktop-cat`}
                  setSelectedCategory={setSelectedCategory}
                  category={category}
                  selected={category?.id == selectedCategory}
                  isDesktop={true}
                />
              ))}
            </div>
          </div>

          <div className="hidden sm:flex flex-col sticky top-[80px] mt-3 pt-4 z-40 bg-white md:hidden pb-5 p-4 px-8">
            {backButton}
          </div>

          <div className="flex flex-col md:w-6/12 sm:p-4 sm:px-8 md:px-4 sm:pb-0">
            <div className="sm:mb-6 flex md:hidden">
              {/* <StartGroupOrderBadge
                menuId={restaurantDetail?.attributes?.id}
                address={address}
              /> */}
            </div>

            <div className="flex relative">
              <div
                className={`w-full relative overflow-hidden sm:rounded-[20px]`}
                style={{ height: "181px" }}
              >
                {
                  <img
                    priority
                    src={
                      restaurantDetail?.attributes?.background_image_url ??
                      AltImage
                    }
                    width={
                      screenType?.isDesktop
                        ? "700px"
                        : screenType?.isMobile
                        ? "480px"
                        : "100%"
                    }
                    height={"181px"}
                    layout={
                      screenType?.isDesktop || screenType?.isMobile
                        ? "fixed"
                        : "fill"
                    }
                    objectFit="cover"
                    placeholder="blur"
                    blurDataURL={
                      restaurantDetail?.attributes?.background_image_url
                    }
                    alt={`${changeSpacesToHyphens(
                      restaurantDetail?.attributes?.display_name
                    )}-image-main`}
                  />
                }

                <div className="absolute inset-0 bg-black bg-opacity-50" />
              </div>

              <div className="absolute z-10 top-5 right-3">
                <div className="flex flex-wrap justify-end px-1 w-full float-right space-x-2">
                  {restaurantDetail?.attributes?.featured_badges
                    ?.slice(0, 2)
                    ?.map((featured, index) =>
                      badge.getFeaturedTag(featured, index)
                    )}
                </div>
              </div>

              <div className="flex absolute z-[19] bottom-3 left-4">
                <div className="flex flex-col pr-3 py-3">
                  <div className="flex items-center">
                    <h1
                      className={`text-[24px] font-inter-semibold text-white mr-2`}
                    >
                      {restaurantDetail?.attributes?.display_name}
                    </h1>

                    <Tooltip
                      whiteIcon
                      tooltipText={getOperatingHours(restaurantDetail)}
                      id="res-info-tooltip"
                      backgroundColor={"white"}
                      textColor="black"
                      place={"bottom"}
                    />
                  </div>

                  <div className="flex flex-wrap">
                    {restaurantDetail?.attributes?.cuisine_tags?.map(
                      (tag, index) => (
                        <h3
                          key={`${restaurantDetail?.attributes?.display_name}-tag-${index}`}
                          className={`font-inter-regular text-md text-white`}
                        >
                          {tag}
                          {index + 1 !==
                            restaurantDetail?.attributes?.cuisine_tags
                              ?.length && (
                            <span className="whitespace-pre"> • </span>
                          )}
                        </h3>
                      )
                    )}
                  </div>

                  <span className="hidden sm:flex mt-2 text-white text-sm pr-4">
                    {restaurantDetail?.attributes?.tagline?.length >
                    ODC_TAGLINE_LENGTH
                      ? `${restaurantDetail?.attributes?.tagline.slice(
                          0,
                          ODC_TAGLINE_LENGTH
                        )}...`
                      : restaurantDetail?.attributes?.tagline}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col sticky md:static bg-white top-20 sm:top-28 z-10">
              {isPresent(restaurantCategoriesAndItemsFiltered) && (
                <div className="flex md:hidden flex-row bg-light-gray rounded-xl p-4 sm:mt-4">
                  <div className="flex overflow-scroll">
                    {restaurantCategoriesAndItemsFiltered?.map(
                      (category, index) => (
                        <CategorySidebar
                          index={index}
                          key={`${index}-tab-cat`}
                          setSelectedCategory={setSelectedCategory}
                          category={category}
                          selected={category?.id == selectedCategory}
                        />
                      )
                    )}
                  </div>
                </div>
              )}

              <div className="hidden sm:flex flex-wrap w-full overflow-scroll">
                {restaurantDetail?.attributes?.menu_items_badges
                  ?.slice(0, provider?.tagsLengthToShow)
                  ?.map((tag) => (
                    <button
                      key={`tag-${tag}`}
                      onClick={() =>
                        dispatch({
                          field: "activeMenuItemTags",
                          value: provider?.activeMenuItemTags?.includes(tag)
                            ? provider?.activeMenuItemTags?.filter(
                                (activeTag) => tag !== activeTag
                              )
                            : [...provider.activeMenuItemTags, tag],
                        })
                      }
                      className={`flex items-center btn-style justify-center h-[22px] px-3 mr-4 my-3 bg-light-gray rounded-3xl ${
                        provider?.activeMenuItemTags?.includes(tag) &&
                        "bg-primary-dark text-white"
                      }`}
                    >
                      <span className="text-sm font-inter-medium">
                        {" "}
                        {toTitleCase(tag)}
                      </span>
                    </button>
                  ))}

                {restaurantDetail?.attributes?.menu_items_badges.length <= 4 &&
                  provider?.activeMenuItemTags.length > 0 && (
                    <div className="my-3">{resetButton}</div>
                  )}
              </div>

              {restaurantDetail?.attributes?.menu_items_badges.length > 4 && (
                <div className="hidden sm:flex items-center justify-end mt-4 mb-3 md:mb-0">
                  {provider?.activeMenuItemTags.length > 0 && (
                    <div className="my-3">{resetButton}</div>
                  )}

                  {MAX_FILTER_TAGS_TO_SHOW !==
                    restaurantDetail?.attributes?.menu_items_badges?.length && (
                    <div>
                      <button
                        onClick={() => {
                          dispatch({
                            field: "showAllTags",
                            value: !showAllTags,
                          });

                          dispatch({
                            field: "tagsLengthToShow",
                            value: showAllTags
                              ? MAX_FILTER_TAGS_TO_SHOW
                              : restaurantDetail?.attributes?.menu_items_badges
                                  ?.length,
                          });
                        }}
                        className="flex items-center btn-style bg-light-gray rounded-3xl h-[22px] px-3"
                      >
                        <FontAwesomeIcon
                          icon={!showAllTags ? faAngleDown : faAngleUp}
                          size="1x"
                          className="small-icon"
                        />

                        <span className="ml-2 font-inter-medium py-[1px] px-1">
                          {!showAllTags ? "Show All" : "Hide"}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            {isPresent(restaurantCategoriesAndItemsFiltered) ? (
              restaurantCategoriesAndItemsFiltered?.map((category, index) => (
                <div key={`menu-item-category-${index}`}>
                  <CategoryTitle
                    category={category}
                    setSelectedCategory={(id) => {
                      dispatch({
                        field: "selectedCategory",
                        value: id,
                      });
                    }}
                  />

                  <span className="flex px-4 sm:p-0 text-primary-black sm:mt-2 sm:mb-4 text-sm">
                    <EmbedHTML
                      text={category?.attributes?.description_with_html}
                      className={"text-primary-black"}
                    />
                  </span>

                  <div className="p-4 sm:p-0">
                    {category?.attributes?.menu_items?.map(
                      (menuItem, index) => (
                        <MenuItem
                          key={`menu-item-parent-${index}`}
                          keyValue={`menu-item-${index}`}
                          menuItem={menuItem}
                          setDeliveryPickupModal={setDeliveryPickupModal}
                          setAddItemModal={getItemOptions}
                          categoryId={category?.id}
                          setImage={(image) => {
                            setImage(image);
                          }}
                          checkAddressAndOpenModal={checkAddressAndOpenModal}
                        />
                      )
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>
                {store.userStore?.isHydrated && !isLoading && (
                  <span className="text-center mt-5 text-md">
                    No Menu Items are available in your search criteria
                  </span>
                )}
              </>
            )}
          </div>

          <div className="hidden md:flex flex-col md:w-4/12 mr-4">
            {isGroupMealRestaurantPage ? (
              <StartGroupOrderBadge
                menuId={restaurantDetail?.attributes?.id}
                address={address}
              />
            ) : (
              <Cart
                markItemAsSoldOut={markItemAsSoldOut}
                restaurantDetail={restaurantDetail}
                setDeliveryPickupModal={setDeliveryPickupModal}
              />
            )}
          </div>

          {/* {deliveryPickupModal?.state && (
            <DeliveryPickupModal
              visible={deliveryPickupModal?.state}
              deliveryPickupModal={deliveryPickupModal}
              setModal={(value) => {
                setDeliveryPickupModal({ state: value, fromCart: false });
                store?.userStore?.setDeliveryPickupModal({
                  state: value,
                  fromCart: false,
                });
              }}
              restaurantDetail={restaurantDetail}
              setDateValidationAlert={setDateValidationAlert}
              setAddItemModal={setAddItemModal}
              setAddressValidationAlert={(value, internalAddress) => {
                setAddressValidationAlert(value);
                setInternalAddressForBrowseButton(internalAddress);
              }}
              openedItems={provider?.openedItems}
            />
          )} */}

          {addItemModal && (
            <AddItemModal
              edit={false}
              visible={addItemModal}
              setModal={setAddItemModal}
              openedItems={provider?.openedItems}
              selectedItem={provider?.selectedItem}
              addItemToCart={addItemToCart}
              setImage={(image) => {
                setImage(image);
              }}
            />
          )}

          {emptyCartAlert && (
            <ConfirmationModal
              avoidClose
              visible={emptyCartAlert}
              setModal={setEmptyCartAlert}
              title={"Your cart already includes items"}
              body={
                "Your cart contains items from another restaurant, please complete check out or empty your cart prior to starting a new order."
              }
              rightButtonText={"Empty Cart"}
              leftButtonText={"Back To Cart"}
              onClickLeftButton={onClickMoveToCart}
              onClickRightButton={onClickEmptyCart}
            />
          )}
          {addressValidationAlert && (
            <ConfirmationModal
              visible={addressValidationAlert}
              setModal={setAddressValidationAlert}
              title={"Address out of range"}
              body={
                "The restaurant you have selected is outside your delivery range. Please select another restaurant available within your delivery area."
              }
              rightButtonText={"Browse Restaurants"}
              leftButtonText={"Back to Menu"}
              onClickLeftButton={onClickMenuButtonInAlert}
              onClickRightButton={onClickGoToRestaurants}
            />
          )}

          {dateValidationAlert && (
            <ConfirmationModal
              visible={dateValidationAlert}
              setModal={setDateValidationAlert}
              title={"Restaurant not available on this date"}
              body={`${restaurantDetail?.attributes?.name} cannot fulfil your order on the date selected.`}
              leftButtonText={"Change Date"}
              rightButtonText={"Browse Available Restaurants"}
              onClickLeftButton={onClickChangeDate}
              onClickRightButton={onClickGoToRestaurants}
            />
          )}

          <ImageLightBox
            imageSrc={activeMenuItemImage}
            visible={imageLightboxModal}
            closeModal={() => setImageLightBox(false)}
          />

          <TagsFilterModal provider={provider} dispatch={dispatch} />
        </div>
      </div>
    </>
  );
};

export default observer(RestaurantPage);
