import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useReducer,
} from "react";
import { EditBillingInfo, AddCard, RemoveCard } from "alerts";
import { MobXProviderContext } from "mobx-react";
import {
  Cheque,
  VisaCardIcon,
  MasterCardIcon,
  RadioActive,
  RadioInActive,
  DisabledRadioButton,
} from "assets/img";
import { observer } from "mobx-react";
import { NotificationBanner } from "components";
import { toJS } from "mobx";
import classNames from "classnames";
import Tooltip from "components/Tooltip";

function PaymentInfo() {
  const initialState = {
    locationBillingAccounts: null,
    defaultCard: "",
    locationCreditCards: "",
    cardId: "",
    billingAccountId: "",
    expiredCardIds: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [billingInfo, setEditBillingInfo] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [removeCard, setRemoveCard] = useState(false);
  const [removeSelectedCard, setRemoveSelectedCard] = useState(false);
  const [paymentInfoUpdateSuccess, setPaymentInfoUpdateSuccess] =
    useState(false);
  const [showSuccessCardMsg, setShowSuccessCardMsg] = useState(false);
  const [showExpiredCardMsg, setShowExpiredCardMsg] = useState(false);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);

  const [provider, dispatch] = useReducer(reducer, initialState);
  let {
    locationBillingAccounts,
    defaultCard,
    locationCreditCards,
    cardId,
    expiredCardIds,
  } = provider;
  let { locationID } = userStore;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      store.userStore.setLoader(true);

      store.paymentInfoStore
        .getLocationBillingAccounts({ location_id: locationID })
        .then((response) => {
          dispatch({
            field: "locationBillingAccounts",
            value: response?.attributes,
          });

          store.userStore.setLoader(false);
        })
        .catch(() => store.userStore?.setLoader(false));

      store.paymentInfoStore
        .getLocationCreditCards({ location_id: locationID })
        .then((response) => {
          dispatch({
            field: "locationCreditCards",
            value: response?.payment_methods,
          });

          dispatch({
            field: "defaultCard",
            value: response?.customer?.invoice_settings?.default_payment_method,
          });

          store.userStore.setLoader(false);
        })
        .catch(() => store.userStore?.setLoader(false));
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [locationID, addCard, removeCard]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const expiredCards = [];
    let isCurrentDefaultExpired = false;

    const nonExpiredCards = locationCreditCards?.data?.filter((data) => {
      const expMonth = data.card.exp_month;
      const expYear = data.card.exp_year;

      const isExpired =
        expYear < currentYear ||
        (expYear === currentYear && expMonth < currentMonth);

      if (isExpired) {
        expiredCards.push(data.id);
        if (data?.id === defaultCard) {
          isCurrentDefaultExpired = true;
        }
        return false;
      }

      return true;
    });

    dispatch({
      field: "expiredCardIds",
      value: expiredCards,
    });

    if (isCurrentDefaultExpired && nonExpiredCards?.length > 0) {
      handleDefaultStripeCard(nonExpiredCards[0]?.id);

      dispatch({
        field: "defaultCard",
        value: nonExpiredCards[0]?.id,
      });
    }
  }, [locationCreditCards, defaultCard]);

  useEffect(() => {
    setShowExpiredCardMsg(expiredCardIds?.length > 0);
  }, [expiredCardIds]);

  const updatePaymentMethod = useCallback(
    (paymentMethod) => {
      const payload = {
        location_billing_account: {
          payment_method: paymentMethod,
        },
      };
      store.paymentInfoStore.updateBillingAccount(locationID, payload);
    },
    [locationID]
  );

  const handleDefaultStripeCard = (paymentID) => {
    store.paymentInfoStore.updateDefaultLocationCreditCards(paymentID, {
      location_id: locationID,
      default: true,
    });
  };

  const handleCardRemove = () => {
    store.userStore.setLoader(true);

    store.paymentInfoStore
      .removeLocationCreditCard({
        location_id: locationID,
        card_id: cardId,
      })
      .then((response) => {
        dispatch({
          field: "locationCreditCards",
          value: { data: response?.data },
        });

        store.userStore.setLoader(false);
      })
      .catch(() => store.userStore?.setLoader(false));

    dispatch({ field: "cardId", value: "" });
    setRemoveCard(false);
  };

  return (
    <div className="px-[32px] py-[24px] w-full pb-32">
      <NotificationBanner
        message="Payment info updated successfully"
        messageType="success"
        textColor="success"
        visible={paymentInfoUpdateSuccess}
        setVisible={setPaymentInfoUpdateSuccess}
      />

      <NotificationBanner
        message="Credit card information was successfully updated"
        messageType="success"
        textColor="success"
        visible={showSuccessCardMsg}
        setVisible={setShowSuccessCardMsg}
      />

      <NotificationBanner
        message="Credit card on file has expired. Please update your payment information or select a different card to avoid any interruptions in service."
        messageType="danger"
        visible={showExpiredCardMsg}
        setVisible={setShowExpiredCardMsg}
      />

      <h1 className="font-inter-bold text-heading text-[32px] mb-[24px]">
        Payment Info
      </h1>

      <div className="flex flex-col items-start justify-center gap-[24px] px-[32px] py-[16px] relative bg-background rounded-[12px_12px_0px_0px]">
        <div className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative w-fit font-web-h5 font-inter-semibold text-plain text-[length:var(--web-h5-font-size)] tracking-[var(--web-h5-letter-spacing)] leading-[var(--web-h5-line-height)] whitespace-nowrap [font-style:var(--web-h5-font-style)]">
            Account Billing Info
          </div>

          <button
            onClick={() => {
              dispatch({
                field: "billingAccountId",
                value: locationBillingAccounts?.id,
              });
              setEditBillingInfo(true);
            }}
            className="btn-blue-accent text-white w-[78px]"
          >
            Edit
          </button>
        </div>

        <div className="flex flex-col items-start justify-center gap-[24px] relative self-stretch w-full flex-[0_0_auto]">
          <div className="flex lg:w-[1104px] w-full items-center gap-[32px] relative flex-[0_0_auto] mr-[-23.00px]">
            <div className="inline-flex items-end gap-[40px] relative lg:flex-[0_0_auto]">
              <div className="lg:inline-flex md:inline-flex flex flex-col items-start justify-center gap-[24px] relative lg:flex-[0_0_auto] lg:flex-row md:flex-col">
                <div className="md:inline-flex lg:gap-[40px] md:gap-3">
                  <div className="flex-col flex items-start relative lg:flex-[0_0_auto] md:flex-1 lg:mb-0 md:mb-0 mb-[24px]">
                    <div className="relative w-fit mt-[-1.00px] font-text-subtitle font-[number:var(--text-subtitle-font-weight)] text-plain text-[length:var(--text-subtitle-font-size)] tracking-[var(--text-subtitle-letter-spacing)] leading-[var(--text-subtitle-line-height)] whitespace-nowrap [font-style:var(--text-subtitle-font-style)]">
                      Company Billing Address
                    </div>
                    <p className="relative w-fit [font-family:'Inter-Medium',Helvetica] font-medium text-plain text-[16px] tracking-[0] leading-[28px]">
                      {locationBillingAccounts?.account_billing_address}
                    </p>
                  </div>
                  <img
                    className="lg:flex md:hidden hidden relative w-px h-[70px] object-cover"
                    alt="Line"
                    src="line-63.svg"
                  />
                  <div className="lg:inline-flex flex-col items-start relative lg:flex-[0_0_auto] md:flex-1 lg:mb-0 md:mb-0 mb-[24px]">
                    <div className="relative w-fit mt-[-1.00px] font-text-subtitle font-[number:var(--text-subtitle-font-weight)] text-plain text-[length:var(--text-subtitle-font-size)] tracking-[var(--text-subtitle-letter-spacing)] leading-[var(--text-subtitle-line-height)] whitespace-nowrap [font-style:var(--text-subtitle-font-style)]">
                      Company Billing Name
                    </div>
                    <div className="relative w-fit [font-family:'Inter-Medium',Helvetica] font-medium text-plain text-[16px] tracking-[0] leading-[28px] whitespace-nowrap">
                      {locationBillingAccounts?.account_billing_name}
                    </div>
                  </div>
                  <img
                    className="lg:flex md:hidden hidden relative w-px h-[70px] object-cover"
                    alt="Line"
                    src="line-64.svg"
                  />
                  <div className="lg:inline-flex flex-col items-start relative lg:flex-[0_0_auto] md:flex-1">
                    <div className="relative w-fit mt-[-1.00px] font-text-subtitle font-[number:var(--text-subtitle-font-weight)] text-plain text-[length:var(--text-subtitle-font-size)] tracking-[var(--text-subtitle-letter-spacing)] leading-[var(--text-subtitle-line-height)] whitespace-nowrap [font-style:var(--text-subtitle-font-style)]">
                      Po Number
                    </div>
                    <div className="relative w-fit [font-family:'Inter-Medium',Helvetica] font-medium text-plain text-[16px] tracking-[0] leading-[28px] whitespace-nowrap">
                      {locationBillingAccounts?.invoice_billing_reference
                        ? locationBillingAccounts?.invoice_billing_reference
                        : "-"}
                    </div>
                  </div>
                  <img
                    className="lg:flex md:hidden hidden relative w-px h-[70px] object-cover"
                    alt="Line"
                    src="line-65.svg"
                  />
                </div>

                <div className="md:inline-flex">
                  <div className="lg:inline-flex flex-col items-start relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] font-text-subtitle font-[number:var(--text-subtitle-font-weight)] text-plain text-[length:var(--text-subtitle-font-size)] tracking-[var(--text-subtitle-letter-spacing)] leading-[var(--text-subtitle-line-height)] whitespace-nowrap [font-style:var(--text-subtitle-font-style)]">
                      Accounting Billing Email(s)
                    </div>
                    <div className="relative w-fit [font-family:'Inter-Medium',Helvetica] font-medium text-plain text-[16px] tracking-[0] leading-[28px] whitespace-nowrap">
                      {locationBillingAccounts?.account_billing_emails?.map(
                        (email, index) => (
                          <div key={index}>{email}</div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full items-start gap-[24px] relative mt-[32px]">
        <div className="flex flex-col items-start gap-[12px] relative self-stretch w-full flex-[0_0_auto]">
          <div className="inline-flex relative self-stretch mt-[-1.00px] font-web-h5 font-[number:var(--web-h5-font-weight)] text-plain text-[length:var(--web-h5-font-size)] tracking-[var(--web-h5-letter-spacing)] leading-[var(--web-h5-line-height)] [font-style:var(--web-h5-font-style)] text-[20px] font-inter-semibold">
            Uncatering Payment Method{" "}
            <Tooltip
              indigoIcon
              tooltipText={`Contact your hungerhub account <br/>admin for questions or changes <br/>about payment methods`}
              id="payment-tooltip"
              backgroundColor="#E8EAEF"
              textColor="#3A3A3A"
              place={"right"}
            />
          </div>

          <p className="relative w-fit [font-family:'Inter',Helvetica] font-normal text-plain text-[16px] tracking-[0] leading-[20px]">
            <span className="font-text-plain font-[number:var(--text-plain-font-weight)] text-[#2d3958] text-[length:var(--text-plain-font-size)] tracking-[var(--text-plain-letter-spacing)] leading-[var(--text-plain-line-height)] [font-style:var(--text-plain-font-style)]">
              This is your current payment method of choice for{" "}
            </span>

            <span className="font-bold">Uncatering</span>
          </p>
        </div>

        <div className="lg:inline-flex md:w-full w-full items-start gap-[16px] relative flex-[0_0_auto] mt-[0px]">
          <button
            className={classNames({
              "flex text-left flex-col lg:w-[381px] md:w-full w-full h-[118px] items-start gap-[2px] px-[16px] py-[12px] relative rounded-[12px] border border-solid border-accent-grey": true,
              "opacity-100": locationBillingAccounts?.payment_method === "etf",
              "opacity-50": locationBillingAccounts?.payment_method !== "etf",
            })}
            onClick={() => {
              updatePaymentMethod("etf");
              setPaymentInfoUpdateSuccess(true);
              dispatch({
                field: "locationBillingAccounts",
                value: {
                  ...locationBillingAccounts,
                  payment_method: "etf",
                },
              });
            }}
          >
            <img
              className="relative w-[24px] h-[24px]"
              alt="Iconly light outline"
              src="https://c.animaapp.com/5n2l6o6s/img/iconly-light-outline-document.svg"
            />
            <div className="relative self-stretch [font-family:'Inter',Helvetica] font-medium text-plain text-[16px] tracking-[0.08px] leading-[26.4px]">
              EFT
            </div>
            <p className="font-inter-regular text-sm relative w-[233px] text-dark-gray font-text-plain font-[number:var(--text-plain-font-weight)] text-header-text text-[length:var(--text-plain-font-size)] tracking-[var(--text-plain-letter-spacing)] leading-[var(--text-plain-line-height)] [font-style:var(--text-plain-font-style)]">
              Electronic Fund Transfer
            </p>
          </button>

          <button
            className={classNames({
              "flex text-left flex-col lg:w-[381px] md:w-full w-full h-[118px] items-start gap-[2px] px-[16px] py-[12px] relative rounded-[12px] border border-solid border-accent-grey lg:mt-0 mt-[24px]": true,
              "opacity-100 bg-light-gray":
                locationBillingAccounts?.payment_method === "cheque",
              "opacity-50":
                locationBillingAccounts?.payment_method !== "cheque",
            })}
            onClick={() => {
              updatePaymentMethod("cheque");
              setPaymentInfoUpdateSuccess(true);
              dispatch({
                field: "locationBillingAccounts",
                value: {
                  ...locationBillingAccounts,
                  payment_method: "cheque",
                },
              });
            }}
          >
            <img
              className="relative w-[24px] h-[24px]"
              alt="Frame"
              src="https://c.animaapp.com/5n2l6o6s/img/frame-290533.svg"
            />
            <div className="relative self-stretch [font-family:'Inter',Helvetica] font-medium text-dark-gray text-[16px] tracking-[0.08px] leading-[26.4px]">
              Cheque
            </div>
            <p className="font-inter-regular text-sm w-[300px] relative font-text-plain font-[number:var(--text-plain-font-weight)] text-dark-gray text-[length:var(--text-plain-font-size)] tracking-[var(--text-plain-letter-spacing)] leading-[var(--text-plain-line-height)] [font-style:var(--text-plain-font-style)]">
              Pay by cheque{" "}
            </p>
          </button>

          <button
            className={classNames({
              "flex text-left flex-col lg:w-[381px] md:w-full w-full h-[118px] items-start gap-[2px] px-[16px] py-[12px] relative rounded-[12px] border border-solid border-accent-grey lg:mt-0 mt-[24px]": true,

              "opacity-100 bg-background":
                locationBillingAccounts?.payment_method === "credit_card",
              "opacity-50":
                locationBillingAccounts?.payment_method !== "credit_card",
            })}
            onClick={() => {
              updatePaymentMethod("credit_card");
              setPaymentInfoUpdateSuccess(true);
              dispatch({
                field: "locationBillingAccounts",
                value: {
                  ...locationBillingAccounts,
                  payment_method: "credit_card",
                },
              });
            }}
          >
            <div className="flex items-start gap-[2px] relative self-stretch w-full flex-[0_0_auto]">
              <div className="flex flex-col items-start justify-center gap-[2px] relative flex-1 grow">
                {/* <VuesaxBoldCard1 className="!relative !w-[24px] !h-[24px]" /> */}
                <img
                  className="relative w-[24px] h-[24px]"
                  alt="Frame"
                  src={Cheque}
                />
                <div className="relative self-stretch [font-family:'Inter',Helvetica] font-medium text-dark-gray text-[16px] tracking-[0.08px] leading-[26.4px]">
                  Card
                </div>
              </div>
            </div>
            <p className="font-inter-regular text-sm w-[283px] relative font-text-plain font-[number:var(--text-plain-font-weight)] text-dark-gray text-[length:var(--text-plain-font-size)] tracking-[var(--text-plain-letter-spacing)] leading-[var(--text-plain-line-height)] [font-style:var(--text-plain-font-style)]">
              Pay invoices by credit card{" "}
            </p>
          </button>
        </div>
      </div>

      <div className="d-row justify-between mt-[56px]">
        <div className="flex flex-col">
          <div className="relative self-stretch mt-[-1.00px] font-web-h5 font-[number:var(--web-h5-font-weight)] text-plain text-[length:var(--web-h5-font-size)] tracking-[var(--web-h5-letter-spacing)] leading-[var(--web-h5-line-height)] [font-style:var(--web-h5-font-style)] text-[20px] font-inter-semibold">
            Credit Card on file
          </div>

          <p className="relative w-fit [font-family:'Inter',Helvetica] font-normal text-plain text-[16px] tracking-[0] leading-[20px] whitespace-nowrap">
            <span className="font-text-plain font-[number:var(--text-plain-font-weight)] text-[#2d3958] text-[length:var(--text-plain-font-size)] tracking-[var(--text-plain-letter-spacing)] leading-[var(--text-plain-line-height)] [font-style:var(--text-plain-font-style)]">
              The card(s) on file will be available to all individuals with
              administrative privileges when placing an order
            </span>
          </p>
        </div>

        <button className="btn-blue-accent" onClick={() => setAddCard(true)}>
          Add new card
        </button>
      </div>

      {locationCreditCards?.data?.map((data, index) => {
        const isExpiredCard = expiredCardIds?.includes(data?.id);

        return (
          <div
            className={classNames(
              "rounded-lg mt-[24px] px-[12px] py-[16px] d-row justify-between",
              data?.id == defaultCard || isExpiredCard
                ? "bg-light-gray"
                : "bg-white border border-gray-300"
            )}
            key={index}
          >
            <div key={"photo"} className="w-full flex items-center">
              <div className="w-full flex justify-between">
                <div className="d-row items-center ml-3">
                  <img
                    className="relative w-[40px] h-[40px]"
                    alt="Frame"
                    src={
                      data?.card?.display_brand == "visa"
                        ? VisaCardIcon
                        : MasterCardIcon
                    }
                  />

                  <div className="d-col ml-3">
                    <small
                      className={classNames(
                        "text-sm font-inter-medium",
                        isExpiredCard ? "text-orange-400" : "text-slate-700"
                      )}
                    >
                      **** {data?.card?.last4}{" "}
                      {isExpiredCard && (
                        <small className="text-orange-400 text-sm font-inter-medium italic">
                          Expired
                        </small>
                      )}
                    </small>

                    <div className="inline-flex gap-3">
                      <button
                        className="text-red-600 text-sm hover:text-red-800"
                        onClick={() => {
                          dispatch({ field: "cardId", value: data?.id });

                          data?.id == defaultCard
                            ? setRemoveSelectedCard(true)
                            : setRemoveCard(true);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className={classNames("flex items-center", {
                    "cursor-pointer": !isExpiredCard,
                  })}
                  onClick={() => {
                    if (!isExpiredCard) {
                      handleDefaultStripeCard(data?.id);
                      dispatch({
                        field: "defaultCard",
                        value: data?.id,
                      });
                    }
                  }}
                >
                  <label
                    htmlFor={"photo"}
                    className="mr-3 block text-sm font-medium leading-6 text-secondary"
                  >
                    {data?.id == defaultCard ? "Selected" : ""}
                  </label>

                  <img
                    alt="radio-button"
                    src={
                      isExpiredCard && data?.id !== defaultCard
                        ? DisabledRadioButton
                        : data?.id == defaultCard
                        ? RadioActive
                        : RadioInActive
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <EditBillingInfo
        visibleModel={billingInfo}
        setBillingModal={setEditBillingInfo}
        billingAccount={locationBillingAccounts}
      />

      <AddCard
        visible={addCard}
        setModal={setAddCard}
        add={true}
        rightClick={() => {
          setAddCard(false);
          setShowSuccessCardMsg(true);
        }}
      />

      <RemoveCard
        visible={removeCard}
        setModal={setRemoveCard}
        title="Remove card"
        message="Are you sure you want to delete this card?"
        leftButtonText="Cancel"
        rightButtonText="Delete"
        leftClick={setRemoveCard}
        rightClick={() => {
          handleCardRemove();
        }}
      />

      <RemoveCard
        visible={removeSelectedCard}
        setModal={setRemoveSelectedCard}
        title="You can’t remove your selected card"
        message="Please select another card first"
        leftButtonText="Back"
        rightButtonText="Add new card"
        leftClick={setRemoveSelectedCard}
        rightClick={() => {
          setRemoveSelectedCard(false);
          setAddCard(true);
        }}
      />
    </div>
  );
}

export default observer(PaymentInfo);
