import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useContext, useEffect, useReducer, useState } from "react";
import { MobXProviderContext } from "mobx-react";
import SearchBar from "components/searchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faComment } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  CartBucketPurple,
  CopyLink,
  EditPencil,
  InfoButton,
  TrashIconRed,
} from "assets/img";

import TooltipNew from "components/Tooltip";
import { priceFormatter } from "utils/helpers/validation";
// import ManageOrderDetailsModal from "components/GroupOrder/ManageOrderDetailsModal";
import ConfirmationModal from "components/ConfirmationModal";
// import UpdateNameModal from "components/GroupOrder/UpdateNameModal";
import ConfirmationBadge from "components/ConfirmationBadge";
import {
  dateFormat,
  dateTimeFormatLong,
  deadlineFormat,
  longDateFormat,
  longDateFormatWithShortDay,
  smallDateFormat,
  timeFormat,
} from "utils/helpers/dateTime";
import moment from "moment-timezone";
import EmbedHTML from "components/shared/embedHTML";
import { toTitleCase } from "utils/helpers/string";
// import AddGroupItemModal from "components/GroupOrder/AddGroupItemModal";
import classNames from "classnames";
import { ORDER_CANCELLATION_HOURS } from "utils/constants/common";
import { getETA } from "utils/helpers/restaurants";
import { getOrderStatus, getOrderStatusForAttendee } from "utils/helpers/order";

const EditPencilButton = ({ onClick }) => (
  <button onClick={onClick} className="flex flex-start btn-style">
    <img src={EditPencil} layout="fixed" />
  </button>
);

const TrashButton = ({ onClick }) => (
  <button onClick={onClick} className="flex items-start btn-style">
    <img src={TrashIconRed} lang="fixed" />
  </button>
);

const UpcomingGroupOrderDetail = ({ hostname }) => {
  const initialState = {
    orderItemsSearchText: "",
    detailsModalVisible: false,
    cancelOrderModalVisible: null,
    deleteOrderModalVisible: null,
    editOrderItemsModal: false,
    editNameAttachedModalVisible: false,
    attendeeToUpdate: null,
    showCopyLinkBadge: false,
    order: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const [editItemModal, setEditItemModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const router = null;
  let { currentUser } = userStore ?? {};
  let { cutlery, currentGroupOrder } = groupOrderStore ?? {};
  const { mealManagementStore } = useContext(MobXProviderContext);
  let {
    orderItemsSearchText,
    detailsModalVisible,
    cancelOrderModalVisible,
    deleteOrderModalVisible,
    editNameAttachedModalVisible,
    showCopyLinkBadge,
    attendeeToUpdate,
    order,
  } = provider;

  let interval = null;

  useEffect(() => {
    getOrder();
    // // let { slug } = router?.query;
    // let slug = null;

    // if (slug) {
    //   store.userStore?.setLoader(true);

    //   getOrder();

    //   interval = setInterval(() => {
    //     getOrder();
    //   }, 120000);

    //   return () => {
    //     if (interval) clearInterval(interval);
    //   };
    // }
  }, []);

  const getOrder = () => {
    // mealManagementStore.fetchGroupOrderDetails(3049).then((response) => {
    //   console.log("--", response);
    // });
    mealManagementStore.fetchGroupOrderDetails(3049).then((response) => {
      if (response?.data) {
        dispatch({ field: "order", value: response?.data?.data });

        //   if (response?.data?.attributes?.status == "inactive") {
        //     store?.groupOrderStore
        //       ?.updateGroupOrder(slug, {
        //         group_order: {
        //           status: "active",
        //         },
        //       })
        //       .then(() => {
        //         getOrder();
        //       });
        //   }
      }
    });
    // let { slug } = router?.query;

    // store.groupOrderStore
    //   ?.getGroupOrderDetails(slug)
    //   .then((response) => {
    //     store.userStore?.setLoader(false);

    //     if (response?.data) {
    //       dispatch({ field: "order", value: response?.data });

    //       if (response?.data?.attributes?.status == "inactive") {
    //         store?.groupOrderStore
    //           ?.updateGroupOrder(slug, {
    //             group_order: {
    //               status: "active",
    //             },
    //           })
    //           .then(() => {
    //             getOrder();
    //           });
    //       }
    //     }
    //   })
    //   .catch(() => {
    //     store.userStore?.setLoader(false);
    //   });
  };

  const getURL = () => {
    return `${hostname}/group-order/user/restaurant/${
      order?.attributes?.menu_code
    }/${order?.attributes?.slug}${
      order?.attributes?.source_of_business === "restaurant"
        ? "?utm_source=odc"
        : ""
    }`;
  };

  const copyLinkToDashboard = () => {
    if (order?.attributes) {
      const updatedURL = getURL();

      navigator.clipboard.writeText(updatedURL);

      dispatch({ field: "showCopyLinkBadge", value: true });

      setTimeout(() => {
        dispatch({ field: "showCopyLinkBadge", value: false });
      }, 2000);
    }
  };

  const updateItem = (id, payload) => {
    store.userStore.setLoader(true);
    store?.groupOrderStore?.updateGroupOrderItem(id, payload).then(() => {
      store.userStore.setLoader(false);
      setEditItemModal(false);

      getOrder();
    });
  };

  const onClickRemoveAttendee = (attendee) => {
    dispatch({
      field: "attendeeToUpdate",
      value: attendee,
    });

    dispatch({
      field: "deleteOrderModalVisible",
      value: true,
    });
  };

  const removeAttendee = () => {
    store.userStore.setLoader(true);
    store?.groupOrderStore
      ?.removeAttendee(attendeeToUpdate.id, order?.attributes?.slug)
      .then((response) => {
        store.userStore.setLoader(false);

        if (response?.message == "Attendee removed!") {
          dispatch({
            field: "deleteOrderModalVisible",
            value: null,
          });

          getOrder();
        }
      });
  };

  const handleOnChange = (e) => {
    dispatch({ field: "orderItemsSearchText", value: e?.target?.value });
  };

  const isAbleToCheckout = () =>
    order?.attributes?.grand_total >= order?.attributes?.order_minimum_amount;

  const moveToCheckout = () => {
    const isPastDeadline = moment(
      order?.attributes?.admin_deadline,
      "YYYY-MM-DD hh:mm A"
    ).isBefore(moment());

    const deadlineString = moment(
      order?.attributes?.admin_deadline,
      deadlineFormat
    ).format(`${longDateFormatWithShortDay} ${timeFormat}`);

    if (isPastDeadline) {
      store.userStore.setErrorAlert(true, {
        title: "Deadline passed",
        body: `The deadline to place your order is ${deadlineString}. Please update the deadline in manage details to submit the order.`,
      });
    } else
      router?.push(
        `/group-order/checkout/${currentGroupOrder?.attributes?.slug}`
      );
  };

  const isActiveOrder = () => {
    return order?.attributes?.status == "active";
  };

  const getStatusString = () => {
    console.log("order?.attributes", order?.attributes);
    switch (order?.attributes?.status) {
      case "completed":
        return "Your order was successfully delivered";
      case "-":
        return `Your group order ${order?.attributes?.meal_name} was not sent to restaurant as it was not checked out in time`;
      case "cancelled":
        return `Your group order ${order?.attributes?.meal_name} has been cancelled`;
      default:
        return `Your group order ${
          order?.attributes?.meal_name
        } is ${getOrderStatus(
          order?.attributes?.status,
          order?.attributes?.shipment_acceptance_status
        )}`;
    }
  };

  const getETAString = () => {
    if (order?.attributes?.dropoff_task_eta) {
      return `Your estimated time of arrival is ${getETA(order)}`;
    }
  };

  const getCancellationDeadline = () => {
    return moment(order?.attributes?.cancellation_deadline)
      .utc()
      .format("dddd Do MMMM YYYY - hh:mma");
  };

  const showCancelButton = () => {
    if (order.attributes.status !== "pending") return false;

    if (parseFloat(order.attributes.grand_total).toFixed(2) > 5000)
      return false;

    let dateTime = moment(
      order.attributes.order_date + " " + order.attributes.delivery_time,
      "YYYY-MM-DD hh:mm A"
    );

    let duration = moment.duration(dateTime.diff(moment()));
    let hours = duration.asHours();

    if (hours < ORDER_CANCELLATION_HOURS) return false;

    return true;
  };

  const onCancelOrder = (id) => {
    store.userStore?.setLoader(true);
    store.cartStore
      ?.cancelOrder(id)
      .then((response) => {
        store.userStore?.setLoader(false);
        dispatch({
          field: "cancelOrderModalVisible",
          value: null,
        });
        store.userStore.setErrorAlert(true, { title: response?.message });
        getOrder();
      })
      .catch(() => {
        store.userStore?.setLoader(false);
      });
  };

  const onCancelGroupOrder = () => {
    store.userStore.setLoader(true);
    store.groupOrderStore
      .cancelGroupOrder(order?.attributes?.id)
      .then((response) => {
        store.userStore.setLoader(false);
        if (
          response?.message?.includes("Order has been cancelled successfully")
        ) {
          store.userStore.setErrorAlert(true, { title: response?.message });
          dispatch({
            field: "cancelOrderModalVisible",
            value: null,
          });
          getOrder();
        }
      });
  };

  const onClickCancelOrder = () => {
    if (order?.attributes?.order_id) {
      onCancelOrder(order?.attributes?.order_id);
    } else {
      onCancelGroupOrder();
    }
  };

  const getFilteredAttendees = (attendees) => {
    console.log("attendees", attendees);
    const filteredAttendees = attendees?.filter((attendee) => {
      const attendeeName = `${attendee?.first_name ?? ""} ${
        attendee?.last_name ?? ""
      }`?.toLowerCase();

      const matchesMenuItem = attendee?.order_items?.some((groupOrderItem) => {
        const menuItemDisplayName =
          groupOrderItem?.attributes?.menu_item?.display_name?.toLowerCase();

        return menuItemDisplayName.includes(
          orderItemsSearchText?.toLowerCase()
        );
      });

      const filterAttendeeByStatus =
        attendee?.tatus === "placed" ||
        attendee?.tatus === "cancelled" ||
        attendee?.tatus === "delivered" ||
        attendee?.tatus === "confirmed";

      if (
        filterAttendeeByStatus &&
        (attendeeName.includes(orderItemsSearchText?.toLowerCase()) ||
          matchesMenuItem)
      ) {
        return attendee;
      }

      return false;
    });

    return filteredAttendees;
  };

  function setAdminCookie() {
    const cookieValue = "true";

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30); // Expires in 30 days

    const cookieString = `admin=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
  }

  const moveToOrderPage = () => {
    setAdminCookie();
    store.userStore.setLoader(true);
    const url = `/group-order/user/restaurant/${order?.attributes?.menu_code}/${
      order?.attributes?.slug
    }?admin=true${
      order?.attributes?.source_of_business === "restaurant"
        ? "&utm_source=odc"
        : ""
    }`;
    store.userStore.setLoader(false);
    window.open(url, "_blank");
  };

  const generatePDF = () => {
    const pdfName = `${order?.attributes?.user_name}_${order?.attributes?.title}_${order?.attributes?.order_date}_${order?.attributes?.id}`;
    store?.groupOrderStore.generatePDF(order?.attributes?.slug, pdfName);
  };

  const totalDetails = (
    <div className="flex flex-col w-full border border-background rounded-[24px] py-6 px-4">
      <div className="flex items-center">
        <span className="text-md2 font-inter-medium">
          {order?.attributes?.restaurant_name}
        </span>
      </div>

      <div className="mt-5">
        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Items</span>

          <span className="text-md">
            {priceFormatter(order?.attributes?.food_total)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <div className="flex items-center">
            <span className="text-md">Service Fee</span>
            <TooltipNew
              grayIcon
              tooltipText="A service fee of five percent of total item charges is inclusive of all hungerhub charges"
              id="service-tooltip"
              backgroundColor="white"
              textColor="black"
              place="bottom"
            />
          </div>

          <span className="text-md">
            {priceFormatter(order?.attributes?.service_fee)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <div className="flex items-center">
            <span className="text-md">Delivery</span>
            <TooltipNew
              grayIcon
              tooltipText="Your delivery cost is dependent on restaurant and proximity to your delivery address"
              id="delivery-tooltip"
              backgroundColor="white"
              textColor="black"
              place="bottom"
            />
          </div>

          <span className="text-md">
            {priceFormatter(order?.attributes?.delivery_fee)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Tax</span>

          <span className="text-md">
            {priceFormatter(order?.attributes?.tax_amount)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Tip</span>

          <span className="text-md">
            {priceFormatter(order?.attributes?.tip)}
          </span>
        </div>

        {order?.attributes?.discounted_amount > 0 && (
          <div className="flex w-full items-center justify-between mt-3">
            <span className="text-md">Discount</span>

            <span className="text-md">
              -{priceFormatter(order?.attributes?.discounted_amount)}
            </span>
          </div>
        )}
      </div>

      <div className="flex w-full items-center justify-between mt-6 mb-16 md:mb-6 border-t border-border-gray pt-6">
        <span className="text-md">Total</span>

        <span className="text-md">
          {priceFormatter(order?.attributes?.food_total)}
        </span>
      </div>

      {isActiveOrder() ? (
        <div className="bg-background p-2 rounded-md">
          <label className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer">
            <span className="ml-1 font-inter-regular text-sm w-40">
              Request cutlery
            </span>

            <input
              autoComplete="off"
              type="checkbox"
              className="form-checkbox"
              onChange={(e) =>
                store.groupOrderStore.updateCutlery(e.target.checked)
              }
              checked={cutlery}
            />
          </label>
        </div>
      ) : (
        order?.attributes?.cutlery && (
          <span className="font-inter-regular text-sm bg-background p-3 rounded-md w-full">
            Cutlery requested
          </span>
        )
      )}

      {isActiveOrder() && (
        <button
          onClick={moveToCheckout}
          disabled={!isAbleToCheckout()}
          className={classNames({
            "btn-purple mt-5 py-3 md:h-12": true,
            "hover:!bg-hh-accent-dark !text-secondary-text":
              !isAbleToCheckout(),
          })}
        >
          {isAbleToCheckout() ? (
            <span className="text-sm font-inter-medium">Place Order</span>
          ) : (
            <span className="text-sm font-inter-medium">
              {priceFormatter(order?.attributes.grand_total)} • Minimum order
              total: {priceFormatter(order?.attributes?.order_minimum_amount)}
            </span>
          )}
        </button>
      )}
    </div>
  );

  const onClickManageDetails = () => {
    const isPastAdminDeadline = moment(
      order?.attributes?.admin_deadline,
      deadlineFormat
    ).isBefore(moment());

    if (isPastAdminDeadline) {
      let payload = {
        menu_id: order?.attributes?.menu_id,
        attendee: Math.abs(order?.attributes?.expected_attendees),
        budget_per_attendee: order?.attributes?.per_person_budget,
        selected_date: moment(order.attributes?.order_date).format(dateFormat),
        current_date_time: moment().format(dateTimeFormatLong),
        delivery_time: moment(
          order?.attributes?.delivery_time,
          "hh:mm A"
        ).format(timeFormat),
        admin: true,
        admin_date: moment(
          new Date(moment(order?.attributes?.admin_deadline, deadlineFormat))
        ).format(dateFormat),
      };

      store.userStore.setLoader(true);
      store?.groupOrderStore.getTimesForGroupOrder(payload).then((response) => {
        store.userStore.setLoader(false);

        if (response?.timeslots?.length == 0) {
          store.userStore?.setErrorAlert(true, {
            title: "Deadline Passed",
            body: "The deadline to submit this order has passed, please start a chat with our support team to see if the order can still be placed.",
          });
        } else {
          dispatch({ field: "detailsModalVisible", value: true });
        }
      });
    } else {
      dispatch({ field: "detailsModalVisible", value: true });
    }
  };

  const manageDetailsButton = (
    <button
      onClick={onClickManageDetails}
      className="btn-style bg-primary-dark hover:bg-hh-accent-dark items-center justify-center text-white text-md py-3 px-4 rounded-[8px] mr-2 font-inter-medium"
    >
      Manage details
    </button>
  );

  const isFutureDeliveryDate = order
    ? moment(
        `${order?.attributes?.order_date} ${order?.attributes?.delivery_time}`,
        deadlineFormat
      ).isAfter(moment())
    : false;

  console.log("order", order);
  return (
    <>
      {!currentUser && order && (
        <div className="flex flex-col justify-center mx-auto p-5 md:p-[40px] md:pt-[32px] w-full">
          <div className="flex">
            <Link href="/orders">
              <div className="flex items-center cursor-pointer">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  style={{ width: 14, height: 14 }}
                />
                <span className="text-md text-left text-black-light ml-3">
                  Back to Orders
                </span>
              </div>
            </Link>
          </div>

          <div className="flex mt-8 bg-background px-[10px] py-[14px] rounded-xl">
            <div className="mr-3">
              <img
                src={InfoButton}
                layout="fixed"
                alt="info-button"
                width={16}
                height={16}
              />
            </div>

            {isActiveOrder() ? (
              <span className="text-md">
                <strong>
                  Orders are not automatically sent to restaurant.
                </strong>{" "}
                You will need to verify and place order to finalize your group
                order. <br />
                Deadline to place order:{" "}
                {moment(
                  order?.attributes?.admin_deadline,
                  deadlineFormat
                ).format(`${longDateFormatWithShortDay} ${timeFormat}`)}
              </span>
            ) : (
              <span className="text-md">
                Please note that the delivery window will be within 15 minutes
                before/after your selected time
              </span>
            )}
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-6">
            {isActiveOrder() ? (
              <>
                <div className="flex flex-col md:flex-row md:items-center">
                  <span className="text-[24px] font-inter-semibold mr-3">
                    {order?.attributes?.title}
                  </span>

                  <button
                    type="button"
                    onClick={copyLinkToDashboard}
                    className="flex btn-style items-center mt-3 md:mt-0"
                  >
                    <img src={CopyLink} layout="fixed" />

                    <span className="text-hh-accent-light text-md font-inter-semibold ml-2">
                      Copy invitation link
                    </span>
                  </button>
                </div>

                <div className="flex justify-between mt-6 sm:mt-0">
                  {manageDetailsButton}

                  <button
                    onClick={() =>
                      dispatch({
                        field: "cancelOrderModalVisible",
                        value: true,
                      })
                    }
                    className="btn-style border-primary-dark border hover:bg-hh-accent-dark items-center justify-center text-md text-primary-dark hover:text-white py-3 px-4 rounded-[8px] font-inter-medium"
                  >
                    Cancel Order
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-centter">
                  <img src={CartBucketPurple} alt="cart-button-purple" />

                  <span className="text-[24px] font-inter-medium ml-3">
                    {getStatusString()}
                  </span>
                </div>

                <div className="flex">
                  {showCancelButton() && (
                    <button
                      className="mr-3 btn-style"
                      onClick={() =>
                        dispatch({
                          field: "cancelOrderModalVisible",
                          value: true,
                        })
                      }
                    >
                      <span className="text-hh-accent-light text-[15px] font-inter-medium">
                        Cancel Order
                      </span>
                    </button>
                  )}

                  {order?.attributes?.status == "cancelled" &&
                    isFutureDeliveryDate &&
                    manageDetailsButton}

                  {order?.attributes?.status !== "cancelled" && (
                    <button className="btn-purple" onClick={generatePDF}>
                      Download PDF receipt
                    </button>
                  )}
                </div>
              </>
            )}
          </div>

          {order?.attributes?.status !== "completed" &&
            order?.attributes?.status !== "cancelled" && (
              <div className="flex flex-col bg-background py-5 px-3 rounded-md mt-5">
                {showCancelButton() ? (
                  <span className="text-md font-inter-semibold mb-2">
                    You can cancel this order up to {getCancellationDeadline()}
                  </span>
                ) : (
                  <>
                    {order?.attributes?.dropoff_task_eta && (
                      <div>
                        <span className="text-md font-inter-semibold mb-2">
                          {getETAString()}
                        </span>

                        <span className="animate-dot text-green-600 ml-2">
                          •
                        </span>
                      </div>
                    )}
                  </>
                )}

                <span className="text-md">
                  If you need assistance start a chat or email us at
                  help@hungerhub.com.
                </span>
              </div>
            )}

          <div className="flex flex-col mt-6 md:flex-row md:items-center bg-light-grey rounded-xl p-6">
            {isActiveOrder() ? (
              <div className="flex flex-col md:flex-row md:mt-0">
                <div className="flex flex-col mb-4 md:mb-0">
                  <div className="flex items-center">
                    <span className="text-md text-header-text">
                      Order limit
                    </span>
                    <TooltipNew
                      grayIcon
                      tooltipText="The limit is calculated by the number of attendees and budget you have set. To edit please manage order details."
                      id="order-limit-tooltip"
                      backgroundColor="white"
                      textColor="black"
                      place="bottom"
                    />
                  </div>

                  <span className="text-md text-header-text font-inter-medium">
                    {priceFormatter(
                      order?.attributes?.expected_attendees *
                        order?.attributes?.per_person_budget
                    )}
                  </span>
                </div>

                <div className="w-[1px] bg-border-gray mx-4" />

                <div className="flex flex-col mb-4 md:mb-0">
                  <span className="text-md text-header-text">
                    Deadline for users
                  </span>
                  <span className="text-md text-header-text font-inter-medium">
                    {moment(
                      order?.attributes?.attendee_deadline,
                      deadlineFormat
                    ).format(smallDateFormat)}
                  </span>
                </div>

                <div className="w-[1px] bg-border-gray mx-4" />

                <div className="flex flex-col mb-4 md:mb-0">
                  <span className="text-md text-header-text">
                    Delivery time
                  </span>
                  <span className="text-md text-header-text font-inter-medium">
                    {moment(
                      `${order?.attributes?.order_date} ${order?.attributes?.target_time}`,
                      deadlineFormat
                    ).format(smallDateFormat)}
                  </span>
                </div>

                <div className="w-[1px] bg-border-gray mx-4" />

                <div className="flex flex-col">
                  <span className="text-md text-header-text">
                    Delivery Address
                  </span>
                  <span className="text-md text-header-text font-inter-medium">
                    {order?.attributes?.provincial_address}
                  </span>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-4 gap-4">
                <div className="flex flex-col">
                  <span className="text-md uppercase text-black-light">
                    Group order name:
                  </span>
                  <span className="text-md font-inter-medium">
                    {order?.attributes?.meal_name}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-md uppercase text-black-light">
                    Delivery address:
                  </span>
                  <span className="text-md font-inter-medium">
                    {order?.attributes?.address}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-md uppercase text-black-light">
                    Delivery date:
                  </span>
                  <span className="text-md font-inter-medium">
                    {moment(order.attributes?.meal_date, dateFormat).format(
                      longDateFormat
                    )}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-md uppercase text-black-light">
                    Delivery time:
                  </span>
                  <span className="text-md font-inter-medium">
                    {order.attributes?.target_time}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-md uppercase text-black-light">
                    Name:
                  </span>
                  <span className="text-md font-inter-medium">
                    {order.attributes?.meal_name}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-md uppercase text-black-light">
                    Billing address:
                  </span>
                  <span className="text-md font-inter-medium">
                    {order.attributes?.address}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col md:flex-row w-full mt-8">
            <div className="flex md:hidden">{totalDetails}</div>

            <div className="flex flex-col w-full md:w-[60%]">
              <div className="flex flex-col sm:flex-row justify-between items-center w-full">
                {isActiveOrder() && (
                  <button
                    onClick={moveToOrderPage}
                    className="btn-style w-full sm:w-auto  min-w-[110px] h-12 bg-primary-dark hover:bg-hh-accent-dark text-white font-inter-medium text-md rounded-[8px] py-3 px-4 items-center justify-center mr-2"
                  >
                    Add items
                  </button>
                )}

                <SearchBar
                  value={orderItemsSearchText}
                  handleOnChange={handleOnChange}
                  placeholder="Search"
                  className="w-full !mr-0 mt-6 sm:mt-0"
                />
              </div>

              <div className="flex mt-4 items-center bg-light-grey rounded-[4px] px-3 py-6">
                <span className="w-1/3 sm:w-2/12 text-sm font-inter-medium">
                  Name
                </span>

                <span className="hidden sm:flex sm:w-[100px] text-sm font-inter-medium ml-2">
                  Quantity
                </span>

                <span className="w-2/3 sm:w-4/12 text-sm font-inter-medium">
                  Item
                </span>

                <span className="hidden sm:flex sm:w-2/12 text-sm font-inter-medium">
                  Status
                </span>

                <span className="hidden sm:flex sm:w-2/12 text-sm font-inter-medium">
                  Food Total
                </span>

                {isActiveOrder() && (
                  <span className="hidden sm:flex w-2/12 text-sm font-inter-medium"></span>
                )}
              </div>

              {getFilteredAttendees(order?.attributes?.orders)?.map(
                (attendee, index) => (
                  <div
                    key={`attendee-order-${index}`}
                    className="flex w-full my-4 px-2"
                  >
                    <div className="flex flex-col w-1/3 sm:w-2/12">
                      <span className="text-md font-inter-semibold mb-2">
                        {attendee?.first_name} {attendee?.last_name}
                      </span>

                      {isActiveOrder() && (
                        <EditPencilButton
                          onClick={() => {
                            dispatch({
                              field: "attendeeToUpdate",
                              value: attendee,
                            });

                            dispatch({
                              field: "editNameAttachedModalVisible",
                              value: true,
                            });
                          }}
                        />
                      )}
                    </div>

                    <div className="flex flex-col w-2/3 sm:w-6/12">
                      {attendee?.order_items?.map((orderItem, index) => (
                        <div className="flex">
                          <div className="hidden sm:flex sm:w-[100px] ml-2">
                            <span className="text-md font-inter-semibold text-center">
                              {orderItem?.attributes?.quantity}
                            </span>
                          </div>

                          <div
                            key={`${attendee?.first_name}-order-item-${index}`}
                            className="flex w-full flex-col mb-6 mr-2"
                          >
                            <div className="flex w-full">
                              {isActiveOrder() && (
                                <EditPencilButton
                                  onClick={() => {
                                    setEditItem(orderItem);
                                    setEditItemModal(true);
                                  }}
                                />
                              )}

                              <div className="flex flex-col ml-[6px]">
                                <span className="text-sm font-inter-semibold">
                                  <EmbedHTML
                                    text={
                                      orderItem?.attributes?.menu_item
                                        ?.display_name_with_html
                                    }
                                    className="text-primary-black capitalize"
                                  />
                                </span>

                                {orderItem?.attributes?.group_order_item_options?.map(
                                  (option, index) => (
                                    <div className="flex" key={option.id}>
                                      <span className="flex flex-col text-sm">
                                        <EmbedHTML
                                          isRow
                                          text={
                                            option?.attributes
                                              ?.option_group_display_name_with_html
                                          }
                                          appendClass="mr-1"
                                          className="text-primary-black font-inter-medium"
                                        />

                                        <EmbedHTML
                                          text={
                                            option?.attributes
                                              ?.option_display_name_with_html
                                          }
                                          className="text-primary-black"
                                        />
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>

                            {orderItem?.attributes?.special_instructions && (
                              <div className="flex items-center ">
                                <div
                                  className="flex"
                                  style={{ width: 12, height: 12 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faComment}
                                    size="1x"
                                    className="small-icon"
                                  />
                                </div>

                                <span className="text-sm ml-2">
                                  Instructions:{" "}
                                  <span className="font-inter-medium">
                                    {
                                      orderItem?.attributes
                                        ?.special_instructions
                                    }
                                  </span>
                                </span>
                              </div>
                            )}

                            <div className="flex sm:hidden mt-6">
                              {isActiveOrder() && (
                                <TrashButton
                                  onClick={() =>
                                    onClickRemoveAttendee(attendee)
                                  }
                                />
                              )}

                              <span className="text-attention text-sm ml-2">
                                Delete Order
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="hidden sm:flex sm:w-2/12 text-md">
                      {toTitleCase(
                        getOrderStatusForAttendee(
                          attendee?.tatus,
                          order?.attributes?.status
                        )
                      )}
                    </div>

                    <div className="hidden sm:flex sm:w-2/12 text-md">
                      {priceFormatter(attendee?.food_total)}
                    </div>

                    {isActiveOrder() && (
                      <div className="hidden sm:flex sm:w-2/12">
                        <TrashButton
                          onClick={() => onClickRemoveAttendee(attendee)}
                        />
                      </div>
                    )}
                  </div>
                )
              )}
            </div>

            <div className="hidden md:flex w-[40%] ml-4">{totalDetails}</div>
          </div>

          {/* {detailsModalVisible && (
            <ManageOrderDetailsModal
              order={order}
              visible={detailsModalVisible}
              getOrder={getOrder}
              setModal={(value) =>
                dispatch({ field: "detailsModalVisible", value })
              }
            />
          )} */}

          {/* {editNameAttachedModalVisible && (
            <UpdateNameModal
              visible={editNameAttachedModalVisible}
              attendeeToUpdate={attendeeToUpdate}
              getOrder={getOrder}
              setModal={(value) =>
                dispatch({ field: "editNameAttachedModalVisible", value })
              }
            />
          )} */}

          {/* {editItemModal && (
            <AddGroupItemModal
              edit
              editItem={editItem}
              visible={editItemModal}
              setModal={setEditItemModal}
              updateItem={updateItem}
            />
          )} */}

          {cancelOrderModalVisible && (
            <ConfirmationModal
              visible={cancelOrderModalVisible}
              setModal={(value) =>
                dispatch({ field: "cancelOrderModalVisible", value })
              }
              title={"Cancel group order"}
              body={`Are you sure you want to cancel?`}
              leftButtonText="Go Back"
              onClickLeftButton={() =>
                dispatch({ field: "cancelOrderModalVisible", value: null })
              }
              rightButtonText={"Cancel group Order"}
              onClickRightButton={onClickCancelOrder}
              groupOrderAlert={true}
            />
          )}

          {deleteOrderModalVisible && (
            <ConfirmationModal
              visible={deleteOrderModalVisible}
              setModal={(value) =>
                dispatch({ field: "deleteOrderModalVisible", value })
              }
              title={`Cancel ${attendeeToUpdate?.first_name ?? ""} ${
                attendeeToUpdate?.last_name ?? ""
              } Order`}
              body={`Are you sure you want to cancel?`}
              leftButtonText="Go Back"
              onClickLeftButton={() =>
                dispatch({ field: "deleteOrderModalVisible", value: null })
              }
              rightButtonText={"Cancel"}
              onClickRightButton={removeAttendee}
              groupOrderAlert={true}
            />
          )}
        </div>
      )}
      {showCopyLinkBadge && (
        <ConfirmationBadge applyLeft text={"Link copied succesfully"} />
      )}
    </>
  );
};

export default observer(UpcomingGroupOrderDetail);
