import React, { useState, useEffect, useContext } from "react";
import Input from "components/input";
import TimePicker from "components/timepicker";
import MealSeries from "components/mealSeries";
import ActiveStepIndicator from "components/shared/activeStepIndicator";
import {
  mealTimings,
  defaultMealTimePickerValue,
} from "utils/constants/onboardingStep";
import { MobXProviderContext } from "mobx-react";

const Step2 = ({ disableButton }) => {
  const { onboardingStore } = useContext(MobXProviderContext);

  const [selectedMealOptions, setSelectedMealOptions] = useState([]);
  const [activeMeal, setActiveMeal] = useState("");
  const [otherMealName, setOtherMealName] = useState("");
  const [otherMealTime, setOtherMealTime] = useState("00:00 PM");
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [mealTimePicker, setMealTimePicker] = useState(
    defaultMealTimePickerValue
  );

  const handleMealChange = (meal) => {
    setSelectedMealOptions((prevSelectedMealOptions) => {
      const mealAlreadySelected = prevSelectedMealOptions.some(
        (mealOption) => mealOption.name === meal
      );

      if (mealAlreadySelected) {
        const updatedSelectedMeals = prevSelectedMealOptions.filter(
          (mealOption) => mealOption.name !== meal
        );

        const newActiveMeal =
          updatedSelectedMeals.length > 0
            ? updatedSelectedMeals[updatedSelectedMeals.length - 1].name
            : "";

        setActiveMeal(newActiveMeal);

        return updatedSelectedMeals;
      } else {
        const newSelectedMeal = {
          name: meal,
          meal_name: meal,
          target_time: "",
        };

        setActiveMeal(meal);

        return [newSelectedMeal];
      }
    });
  };

  const handleMealTimePicker = (time, mealName) => {
    if (mealName === "other") {
      setOtherMealTime(time);
    }

    const isPredefinedTime = Object.values(mealTimings).some((times) =>
      times.includes(time)
    );

    const updatedMealTimePicker = mealTimePicker.map((meal) => {
      if (Object.keys(meal).includes(mealName)) {
        return {
          ...meal,
          [mealName]: time,
          predefinedTime: isPredefinedTime,
        };
      }

      return meal;
    });

    setMealTimePicker(updatedMealTimePicker);
  };

  const handleTimeInput = (time, mealName) => {
    handleMealTimePicker(time, mealName);

    setSelectedMealOptions((preOptions) => {
      const updatedOptions = [...preOptions];
      const selectedMealIndex = updatedOptions.findIndex(
        (option) => option.name === mealName
      );

      updatedOptions[selectedMealIndex].target_time = time;
      return updatedOptions;
    });
  };

  const handleSelectedTime = (meal, timing) => {
    const isSelected = selectedMealOptions.some(
      (mealOption) =>
        mealOption.name === meal.name && mealOption.target_time === timing
    );

    return isSelected;
  };

  const handleTimePickerComponent = () => {
    setShowTimePicker(true);

    const updatedMealTimePicker = mealTimePicker.map((meal) => {
      if (Object.keys(meal).includes(activeMeal)) {
        return {
          ...meal,
          predefinedTime: false,
        };
      }

      return meal;
    });

    setMealTimePicker(updatedMealTimePicker);
  };

  const orderMeals = (meal) => {
    const order = {
      breakfast: 1,
      lunch: 2,
      dinner: 3,
      other: 4,
    };
    return order[meal.name];
  };

  const sortedMealOptions = selectedMealOptions
    .filter((mealOption) => mealOption.name !== "other")
    .sort((a, b) => orderMeals(a) - orderMeals(b));

  useEffect(() => {
    if (otherMealName !== "" && activeMeal === "other") {
      setSelectedMealOptions((preSelectedMealOption) =>
        preSelectedMealOption.map((mealOption) =>
          mealOption.name === "other"
            ? {
                ...mealOption,
                name: "other",
                meal_name: otherMealName,
                target_time: "",
              }
            : mealOption
        )
      );
    }
  }, [otherMealName, activeMeal]);

  useEffect(() => {
    if (selectedMealOptions.length === 0) {
      disableButton(true);
    } else if (
      selectedMealOptions.length === 1 ||
      selectedMealOptions.length >= 1
    ) {
      const singleOption = selectedMealOptions[0];

      if (singleOption.target_time.trim() !== "") {
        disableButton(false);
        onboardingStore.handleMealSeriesData(selectedMealOptions);
      }
    } else {
      disableButton(true);
    }
  }, [selectedMealOptions]);

  return (
    <div className="pb-4">
      <ActiveStepIndicator step={2} />

      <div className="flex-col flex gap-6">
        <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
          What meal(s) are you looking to bring to your team?
        </div>
      </div>

      <div className="py-4 gap-6 screen-lg:flex screen-md:flex screen-sm:flex-col">
        <MealSeries
          handleMealChange={handleMealChange}
          mealSelected={selectedMealOptions}
          activeMeal={activeMeal}
        />
      </div>

      {sortedMealOptions.map((mealOption, index) => (
        <React.Fragment key={index}>
          {mealOption.name !== "other" && (
            <>
              <div
                className="expected-bag-text screen-xs:w-full-235 font-inter-semibold"
                key={index}
              >
                What is the ideal time for your {mealOption.meal_name} delivery?
              </div>

              <div className="gap-3 my-6 screen-lg:flex screen-md:flex screen-sm:flex-col space-y-4 screen-lg:space-y-0 screen-md:space-y-0">
                {mealTimings[mealOption.meal_name].map((timing, index) => (
                  <div
                    key={index}
                    className={`w-full screen-lg:w-24 screen-md:w-24 h-[52px] rounded-md justify-center items-center flex hover:cursor-pointer
                        ${
                          selectedMealOptions.some((mealOption) =>
                            handleSelectedTime(mealOption, timing)
                          )
                            ? "bg-indigo-500 text-white"
                            : "text-slate-700 bg-slate-50 hover:bg-indigo-500 hover:text-white"
                        } font-inter-medium leading-tight`}
                    onClick={() => handleTimeInput(timing, mealOption.name)}
                  >
                    {timing}
                  </div>
                ))}

                {mealTimePicker.map((mealObj, index) => {
                  const mealName = Object.keys(mealObj)[0];
                  const mealTime = mealObj[mealName];
                  const predefinedTime = mealObj.predefinedTime;

                  if (mealName !== mealOption.meal_name) {
                    return null;
                  } else {
                    const showTimePickerComponent =
                      mealName === mealOption.meal_name;

                    return (
                      <div
                        key={index}
                        className="w-full screen-lg:w-36 screen-md:w-36 h-[52px] bg-slate-50 rounded-md justify-center items-center font-inter-medium text-indigo-500 flex hover:cursor-pointer"
                        onClick={handleTimePickerComponent}
                      >
                        {showTimePicker &&
                        showTimePickerComponent &&
                        !predefinedTime ? (
                          <TimePicker
                            ideal_time={mealTime}
                            datetime={handleTimeInput}
                            meal_name={mealOption.name}
                          />
                        ) : (
                          "Select Your Time"
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </>
          )}
        </React.Fragment>
      ))}

      {selectedMealOptions.some((meal) => meal.name === "other") && (
        <div className="w-full mb-6">
          <Input
            text={`What would you like to name the "Other" meal?`}
            placeholder={"ex. Town Hall Dinner"}
            otherMealName={setOtherMealName}
            mealName={otherMealName}
            isCompulsary={true}
          />
        </div>
      )}

      {otherMealName !== "" &&
        selectedMealOptions.some((meal) => meal.name === "other") && (
          <>
            <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
              What is the ideal time for your {otherMealName} delivery?
            </div>

            <div className="w-full screen-lg:w-32 screen-md:w-32 my-6 flex bg-slate-50 rounded-md font-inter-medium">
              <TimePicker
                ideal_time={otherMealTime}
                datetime={handleTimeInput}
                meal_name="other"
              />
            </div>
          </>
        )}
    </div>
  );
};

export default Step2;
