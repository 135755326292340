import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  NormalInputFloatingLabel,
  EmailInputFloatingLabel,
  SelectInputFloatingLabel,
} from "reactjs-floating-label-inputs";
import "reactjs-floating-label-inputs/dist/index.css";
import ActiveStepIndicator from "components/shared/activeStepIndicator";
import { canadianProvinces } from "utils/constants/onboardingStep";
import classNames from "classnames";
import { MobXProviderContext } from "mobx-react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";

const Step6 = ({ nextStep, disableButton }) => {
  const { onboardingStore } = useContext(MobXProviderContext);

  // const [disableButton, setDisableButton] = useState(true);
  const [labelFloating, setLabelFloating] = useState(false);
  const [floatingLabels, setFloatingLabels] = useState({
    first_name: false,
    last_name: false,
    company_name: false,
    phone_number: false,
    street_number: false,
    street_name: false,
    postal_code: false,
    unite_suit: false,
    province: false,
    comment: false,
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    confirm_email: "",
    phone_number: "",
    street_number: "",
    street_name: "",
    city: "",
    postal_code: "",
    unite_suit: "",
    province: "",
    comment: "",
  };

  const canadianProvincesOptions = canadianProvinces.map((province) => ({
    value: province,
    label: province,
  }));

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("This field needs to be filled"),
    last_name: Yup.string().required("This field needs to be filled"),
    company_name: Yup.string().required("This field needs to be filled"),
    email: Yup.string()
      .email("This field needs to be filled properly")
      .required("This field needs to be filled"),
    confirm_email: Yup.string()
      .oneOf([Yup.ref("email"), null], "This field needs to be filled properly")
      .required("This field needs to be filled"),
    phone_number: Yup.string().required("This field needs to be filled"),
    street_number: Yup.string().required("This field needs to be filled"),
    street_name: Yup.string().required("This field needs to be filled"),
    city: Yup.string().required("This field needs to be filled"),
    postal_code: Yup.string().required("This field needs to be filled"),
    province: Yup.string().required("This field needs to be filled"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await formik.validateForm();

      if (formik.isValid) {
        onboardingStore.handleUserDetailsData(values);
        const response = onboardingStore.dispatchSignUpData();
        if (response.status >= 200 && !response.status < 300) {
          nextStep();
        } else {
          formik.setErrors({
            email: "This email is already in use.",
          });
        }
      }
    },
  });

  const handlePhoneInputBlur = () => {
    formik.setFieldTouched("phone_number", true);
    setFloatingLabels({
      ...floatingLabels,
      phone_number: false,
    });
    setLabelFloating(false);
  };

  useEffect(() => {
    disableButton(false);
    return;
    const areRequiredFieldsFilled = () => {
      const requiredFields = [
        "company_name",
        "street_number",
        "street_name",
        "city",
        "postal_code",
        "province",
      ];

      return requiredFields.every((field) => !!formik.values[field]);
    };

    // setDisableButton(!areRequiredFieldsFilled());
  }, [formik.values, disableButton]);

  return (
    <div className="step-one-content pt-[44px]">
      <ActiveStepIndicator step={6} />

      <form onSubmit={formik.handleSubmit}>
        <div className="flex-col flex">
          <div className="expected-bag-text xs:w-full-235 font-inter-semibold">
            Add a new office address
          </div>

          <div className="w-full lg:w-[452px] md:w-[452px] mt-8 ">
            <div className="mb-8">
              <NormalInputFloatingLabel
                className={`${
                  formik.errors?.company_name &&
                  formik.touched.company_name &&
                  !formik.values.company_name
                    ? "error-border"
                    : ""
                }`}
                label={
                  floatingLabels.company_name
                    ? "Company name*"
                    : "Company Name*x"
                }
                placeholder="Company name*x"
                type="text"
                value={formik.values.company_name}
                onChangeValue={(val) =>
                  formik.setFieldValue("company_name", val)
                }
                onBlur={() => {
                  formik.setFieldTouched("company_name", true);
                  setFloatingLabels({ ...floatingLabels, company_name: false });
                }}
                onFocus={() =>
                  setFloatingLabels({ ...floatingLabels, company_name: true })
                }
              />

              <div className="text-red-400 text-xs font-inter-medium">
                {formik.errors?.company_name &&
                formik.touched.company_name &&
                !formik.values.company_name
                  ? formik.errors?.company_name
                  : null}
              </div>
            </div>

            <div className="lg:flex md:flex gap-6 mb-8">
              <div className="flex-col lg:w-1/2 md:w-1/2 mb-8 lg:mb-0 md:mb-0">
                <NormalInputFloatingLabel
                  className={`${
                    formik.errors?.street_number &&
                    formik.touched.street_number &&
                    !formik.values.street_number
                      ? "error-border"
                      : ""
                  }`}
                  name="street_number"
                  label={
                    floatingLabels.street_number
                      ? "Street number*"
                      : "Street Address*"
                  }
                  type="number"
                  value={formik.values.street_number}
                  onChangeValue={(val) =>
                    formik.setFieldValue("street_number", val)
                  }
                  onBlur={() => {
                    formik.setFieldTouched("street_number", true);
                    setFloatingLabels({
                      ...floatingLabels,
                      street_number: false,
                    });
                  }}
                  onFocus={() =>
                    setFloatingLabels({
                      ...floatingLabels,
                      street_number: true,
                    })
                  }
                />

                <div className="text-red-400 text-xs font-inter-medium">
                  {formik.errors?.street_number &&
                  formik.touched.street_number &&
                  !formik.values.street_number
                    ? formik.errors?.street_number
                    : null}
                </div>
              </div>

              <div className="flex-col lg:w-1/2 md:w-1/2 mb-8 lg:mb-0 md:mb-0">
                <NormalInputFloatingLabel
                  className={`${
                    formik.errors?.street_name &&
                    formik.touched.street_name &&
                    !formik.values.street_name
                      ? "error-border"
                      : ""
                  }`}
                  name="street_name"
                  label={
                    floatingLabels.street_name ? "Street name*" : "Street Name*"
                  }
                  type="text"
                  value={formik.values.street_name}
                  onChangeValue={(val) =>
                    formik.setFieldValue("street_name", val)
                  }
                  onBlur={() => {
                    formik.setFieldTouched("street_name", true);
                    setFloatingLabels({
                      ...floatingLabels,
                      street_name: false,
                    });
                  }}
                  onFocus={() =>
                    setFloatingLabels({ ...floatingLabels, street_name: true })
                  }
                />

                <div className="text-red-400 text-xs font-inter-medium">
                  {formik.errors?.street_name &&
                  formik.touched.street_name &&
                  !formik.values.street_name
                    ? formik.errors?.street_name
                    : null}
                </div>
              </div>
            </div>

            <div className="flex gap-6 mb-8">
              <div className="w-1/2">
                <NormalInputFloatingLabel
                  className={`${
                    formik.errors?.city &&
                    formik.touched.city &&
                    !formik.values.city
                      ? "error-border"
                      : ""
                  }`}
                  label="City*"
                  type="text"
                  value={formik.values.city}
                  onChangeValue={(val) => formik.setFieldValue("city", val)}
                  onBlur={() => formik.setFieldTouched("city", true)}
                />

                <div className="text-red-400 text-xs font-inter-medium">
                  {formik.errors?.city &&
                  formik.touched.city &&
                  !formik.values.city
                    ? formik.errors?.city
                    : null}
                </div>
              </div>

              <div className="w-1/2">
                <NormalInputFloatingLabel
                  className={`${
                    formik.errors?.postal_code &&
                    formik.touched.postal_code &&
                    !formik.values.postal_code
                      ? "error-border"
                      : ""
                  }`}
                  label={
                    floatingLabels.postal_code ? "Postal code*" : "Postal Code*"
                  }
                  type="text"
                  value={formik.values.postal_code}
                  onChangeValue={(val) =>
                    formik.setFieldValue("postal_code", val)
                  }
                  onBlur={() => {
                    formik.setFieldTouched("postal_code", true);
                    setFloatingLabels({
                      ...floatingLabels,
                      postal_code: false,
                    });
                  }}
                  onFocus={() =>
                    setFloatingLabels({ ...floatingLabels, postal_code: true })
                  }
                />

                <div className="text-red-400 text-xs font-inter-medium">
                  {formik.errors?.postal_code &&
                  formik.touched.postal_code &&
                  !formik.values.postal_code
                    ? formik.errors?.postal_code
                    : null}
                </div>
              </div>
            </div>

            {/*optional field*/}
            <NormalInputFloatingLabel
              className="mb-8"
              label={floatingLabels.unite_suit ? "Unit/Suite" : "Ex. floor 5"}
              type="text"
              value={formik.values.unite_suit}
              onChangeValue={(val) => formik.setFieldValue("unite_suit", val)}
              onBlur={() => {
                setFloatingLabels({ ...floatingLabels, unite_suit: false });
              }}
              onFocus={() =>
                setFloatingLabels({ ...floatingLabels, unite_suit: true })
              }
            />

            <div className="mb-8">
              <SelectInputFloatingLabel
                className={`${
                  formik.errors?.province &&
                  formik.touched.province &&
                  !formik.values.province
                    ? "error-border"
                    : ""
                }`}
                label={
                  floatingLabels.province ? "Province*" : "Select Province"
                }
                colorPrimary="#2D3958"
                value={formik.values.province}
                onChangeValue={(val) => {
                  formik.setFieldValue("province", val);
                  setFloatingLabels({ ...floatingLabels, province: !!val });
                }}
                options={canadianProvincesOptions}
              />

              <div className="text-red-400 text-xs font-inter-medium">
                {formik.errors?.province &&
                formik.touched.province &&
                !formik.values.province
                  ? formik.errors?.province
                  : null}
              </div>
            </div>

            {/*optional field*/}
            <NormalInputFloatingLabel
              className="mb-8"
              label={
                floatingLabels.comment
                  ? "Comment"
                  : "Special Delivery Instructions"
              }
              type="text"
              value={formik.values.comment}
              onChangeValue={(val) => formik.setFieldValue("comment", val)}
              onBlur={() => {
                setFloatingLabels({ ...floatingLabels, comment: false });
              }}
              onFocus={() =>
                setFloatingLabels({ ...floatingLabels, comment: true })
              }
            />
          </div>
        </div>

        {/* <div className="bottom-fixed-div px-2.5 pt-6">
          <hr className="lg:hidden md:hidden mb-4 h-[0px] border border-zinc-200" />

          <button
            className={classNames("button-class font-inter-medium", {
              "bg-gray-200": disableButton,
              "bg-indigo-900": !disableButton,
            })}
            type="submit"
            disabled={disableButton}
          >
            Continues
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default Step6;
