import classNames from "classnames";

const { RemoveIcon } = require("assets/img");
// const { default: Image } = require("next/image");
const { priceFormatter } = require("utils/helpers/validation");

const SubmitActions = ({
  edit,
  provider,
  dispatch,
  setModal,
  getItemTotalPrice,
  itemPrice,
  updateQuantity,
  setAttachLabelAlert,
  addToCart,
  isGroupOrder,
  isAdmin,
}) => (
  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center sm:mt-16">
    <div className="flex flex-col mb-3 sm:mb-0">
      <span className="text-sm mb-1">Total</span>

      <div className="flex">
        <span className="font-inter-semibold text-md text-header-text-2 mr-2">
          {priceFormatter(getItemTotalPrice)}
        </span>

        {provider?.quantity > 1 && !isGroupOrder && (
          <span className="font-inter-light text-md text-secondary-text">
            {priceFormatter(itemPrice)}{" "}
            <span className="text-sm">per item</span>
          </span>
        )}
      </div>
    </div>

    <div className="flex justify-between sm:justify-start">
      <div
        className="flex items-center border border-border-gray rounded-sm"
        style={{ paddingTop: 3, paddingBottom: 3 }}
      >
        <div
          className={classNames("py-1.5 cursor-pointer px-3", {
            "mt-0.5": provider?.quantity == 1,
          })}
          onClick={() => {
            provider?.quantity == 1
              ? setModal(false)
              : dispatch({
                  field: "quantity",
                  value: provider?.quantity - 1,
                });
          }}
        >
          {provider?.quantity > 1 ? (
            <div className="bg-black w-2 h-0.5" />
          ) : (
            <div>
              <img
                src={RemoveIcon}
                className="icon bg-white"
                layout="fixed"
                alt="remove-icon"
              />
            </div>
          )}
        </div>

        <input
          className="text-black-light font-inter-medium text-md w-10 text-center outline-none"
          type="number"
          min="1"
          value={provider.quantity}
          onChange={(e) => updateQuantity(e, dispatch)}
        />

        <div
          className="py-1.5 cursor-pointer px-3"
          onClick={() => {
            if (provider.nameLabel !== "" && !isAdmin)
              setAttachLabelAlert(true);
            else
              dispatch({
                field: "quantity",
                value: provider?.quantity + 1,
              });
          }}
        >
          <span
            className="text-md text-black-light font-inter-medium"
            style={{ lineHeight: 0.8 }}
          >
            +
          </span>
        </div>
      </div>

      <button
        className="btn-purple ml-2 w-28 text-md"
        style={{ borderRadius: 4 }}
        onClick={addToCart}
      >
        {edit ? "Update" : "Add"}
      </button>
    </div>
  </div>
);

export default SubmitActions;
