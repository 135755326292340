import React, { useState, useEffect, useRef, useContext } from "react";
// import { dangerIcon, calendarIcon } from "assets/img";
import Rangeslider from "./rangeSlider";
import MyCalendar from "components/calendar";
import ActiveStepIndicator from "./activeStepIndicator";
import { weekDays } from "utils/constants/onboardingStep";
import { MobXProviderContext } from "mobx-react";

const Step3 = ({ disableButton }) => {
  const { onboardingStore } = useContext(MobXProviderContext);

  const [mealDeliveryOption, setMealDeliveryOption] = useState("");
  const [mealRecievingDays, setMealRecievingDays] = useState([]);
  const [mealStartDate, setMealStartDate] = useState("");
  const [mealSelectedDates, setMealSelectedDates] = useState([]);
  const [mealAllowanceType, setMealAllowanceType] = useState("");
  const [mealAllowanceValue, setMealAllowanceValue] = useState(0);
  const [enableOverpayment, setEnableOverpayment] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    if (mealAllowanceValue === 0 && mealAllowanceType === "daily") {
      handleMealAllowance(20);
    } else if (mealAllowanceValue === 0 && mealAllowanceType === "weekly") {
      handleMealAllowance(100);
    }
  }, [mealAllowanceType, mealAllowanceValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (mealDeliveryOption === "every week") {
      if (
        mealRecievingDays.length !== 0 &&
        mealStartDate !== "" &&
        mealAllowanceType !== ""
      ) {
        disableButton(false);
      } else {
        disableButton(true);
      }
    } else if (mealDeliveryOption === "i will set my own dates") {
      if (mealSelectedDates.length !== 0 && mealAllowanceType !== "") {
        disableButton(false);
      } else {
        disableButton(true);
      }
    }
  }, [
    mealDeliveryOption,
    mealSelectedDates,
    mealRecievingDays,
    mealAllowanceType,
    mealStartDate,
    disableButton,
  ]);

  useEffect(() => {
    // onboardingStore.handleMealSeriesAndSubsidiesData(
    //   mealRecievingDays,
    //   mealStartDate,
    //   mealSelectedDates,
    //   mealAllowanceType,
    //   mealAllowanceValue,
    //   enableOverpayment
    // );
  }, [
    onboardingStore,
    mealRecievingDays,
    mealStartDate,
    mealSelectedDates,
    mealAllowanceType,
    mealAllowanceValue,
    enableOverpayment,
  ]);

  const handleRecievingDaysClick = (value) => {
    if (mealRecievingDays.includes(value)) {
      setMealRecievingDays((prevDays) =>
        prevDays.filter((day) => day !== value)
      );
    } else {
      setMealRecievingDays((prevDays) => [...prevDays, value]);
    }
  };

  const handleMealAllowance = (value) => {
    setMealAllowanceValue(value);
  };

  const handleMealAllowanceType = (value) => {
    setMealAllowanceType(value);
    if (value === "daily") {
      setMealAllowanceValue(20);
    } else if (value === "weekly") {
      setMealAllowanceValue(100);
    } else {
      setMealAllowanceValue(0);
    }
  };

  const handleMultipleDateChange = (newDate) => {
    const monthName = newDate[0]?.month.name;
    const updatedMonthsArray = [...mealSelectedDates];

    if (newDate.length > 0) {
      const monthIndex = updatedMonthsArray.findIndex(
        (month) => Object.keys(month)[0] === monthName
      );

      if (monthIndex !== -1) {
        newDate.forEach((date, index) => {
          const formattedDate = `${date.day}-${date.month.number}-${date.year}`;

          if (
            !updatedMonthsArray[monthIndex][monthName].includes(formattedDate)
          ) {
            updatedMonthsArray[monthIndex][monthName].push(formattedDate);
          }
        });
      } else {
        updatedMonthsArray.push({
          [monthName]: newDate.map(
            (date) => `${date.day}-${date.month.number}-${date.year}`
          ),
        });
      }
      setMealSelectedDates(updatedMonthsArray);
    }
  };

  const handleDateChange = (value) => {
    setMealStartDate(
      value.toDate().toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );
    setShowCalendar(false);
  };

  return (
    <div className="step-one-content d-col items-center justify-center">
      <ActiveStepIndicator step={3} />

      <div className="flex-col flex gap-6">
        <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
          How often do you want to enable meal delivery?
        </div>

        <div className="mb-0.5 w-full screen-xs:w-full-215">
          <div className="screen-lg:inline-flex screen-md:inline-flex">
            <div
              className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                mealDeliveryOption === "every week"
                  ? "bg-indigo-500 text-white "
                  : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
              } rounded-3xl justify-center gap-2 flex`}
              onClick={() => setMealDeliveryOption("every week")}
            >
              Every week
            </div>

            <div
              className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                mealDeliveryOption === "i will set my own dates"
                  ? "bg-indigo-500 text-white "
                  : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
              } rounded-3xl justify-center gap-2 flex`}
              onClick={() => setMealDeliveryOption("i will set my own dates")}
            >
              I will set my own dates
            </div>
          </div>
        </div>

        {mealDeliveryOption === "every week" && (
          <>
            <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
              On what day(s) would you like to recieve meals?
            </div>

            <div className="mb-0.5 w-full screen-xs:w-full-215">
              <div className="screen-lg:inline-flex screen-md:inline-flex">
                {weekDays.map((value, index) => (
                  <div
                    key={index}
                    className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                      mealRecievingDays.some((day) => day === value)
                        ? "bg-indigo-500 text-white "
                        : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                    } rounded-3xl justify-center gap-2 flex`}
                    onClick={() => handleRecievingDaysClick(value)}
                  >
                    {value}
                  </div>
                ))}
              </div>
            </div>

            <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
              When would you like to start?
            </div>

            <div className="mb-0.5 w-full screen-xs:w-full-215">
              <div className="screen-lg:inline-flex screen-md:inline-flex">
                <div className="screen-lg:w-46 screen-md:w-46  h-12 bg-slate-50 screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 rounded-xl items-center flex">
                  <input
                    className="w-full text-slate-700 font-inter-medium text-sm bg-slate-50 outline-none"
                    placeholder="Select date"
                    value={
                      mealStartDate === "I am not sure yet" ? "" : mealStartDate
                    }
                    onClick={() => setShowCalendar(true)}
                  />

                  <span>
                    {/* <img
                      className="w-[20px] h-[20px] "
                      src={calendarIcon}
                      alt="CalendarIcon"
                      onClick={() => setShowCalendar(true)}
                    /> */}
                  </span>
                </div>

                <div
                  className={`screen-lg:w-40 screen-md:w-40 h-12 screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-indigo-500 font-inter-medium text-sm leading-[14px] ${
                    mealStartDate === "I am not sure yet"
                      ? "bg-indigo-500 text-white "
                      : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                  } rounded-xl items-center justify-center gap-2 flex`}
                  onClick={() => setMealStartDate("I am not sure yet")}
                >
                  I am not sure yet
                </div>
              </div>
            </div>
          </>
        )}

        {showCalendar && (
          <div className="w-44" ref={calendarRef}>
            <MyCalendar
              highlightToday={true}
              disableMonthYearPicker={true}
              isMultiple={false}
              showButtons={true}
              handleDatePicker={handleDateChange}
              selectedValue={mealStartDate}
            />
          </div>
        )}

        {mealDeliveryOption === "i will set my own dates" && (
          <div className="flex inline-flex gap-5 overflow-x-auto">
            <MyCalendar
              highlightToday={false}
              disableMonthYearPicker={true}
              isMultiple={true}
              showButtons={false}
              handleMultipleDates={handleMultipleDateChange}
              value={1}
            />

            <MyCalendar
              highlightToday={false}
              disableMonthYearPicker={true}
              isMultiple={true}
              showButtons={false}
              handleMultipleDates={handleMultipleDateChange}
              value={2}
            />

            <MyCalendar
              highlightToday={false}
              disableMonthYearPicker={true}
              isMultiple={true}
              showButtons={false}
              handleMultipleDates={handleMultipleDateChange}
              value={3}
            />
          </div>
        )}

        {(mealDeliveryOption === "i will set my own dates" ||
          mealDeliveryOption === "every week") && (
          <>
            <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
              Meal allowance per user
            </div>

            <div className="mb-0.5 w-full screen-xs:w-full-215">
              <div className="screen-lg:inline-flex screen-md:inline-flex">
                <div
                  className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                    mealAllowanceType === "daily"
                      ? "bg-indigo-500 text-white "
                      : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                  } rounded-3xl justify-center gap-2 flex`}
                  onClick={() => handleMealAllowanceType("daily")}
                >
                  Daily
                </div>

                <div
                  className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                    mealAllowanceType === "weekly"
                      ? "bg-indigo-500 text-white "
                      : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                  } rounded-3xl justify-center gap-2 flex`}
                  onClick={() => handleMealAllowanceType("weekly")}
                >
                  Weekly
                </div>
              </div>
            </div>
          </>
        )}

        {mealAllowanceType !== "" && (
          <div className="screen-lg:w-[541px] screen-md:w-[541px] w-full  px-2 py-4 bg-slate-50 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              {mealAllowanceType} per person allowance
            </div>

            <div className="hidden screen-lg:flex screen-md:flex screen-sm:hidden w-full screen-lg:w-[512px] screen-md:w-[512px] justify-between items-center gap-6 inline-flex">
              <div className="text-slate-700 text-base font-inter-normal leading-7">
                $0
              </div>

              <Rangeslider
                minValue={0}
                maxValue={mealAllowanceType === "daily" ? "50" : "200"}
                currentValue={mealAllowanceValue}
                setMealAllowance={handleMealAllowance}
              />

              <div className="text-slate-700 text-base font-inter-normal leading-7">
                {mealAllowanceType === "daily" ? "$50" : "$200"}
              </div>
            </div>

            <div className="screen-lg:w-[512px] screen-md:w-[512px] w-full flex-col gap-1 flex">
              <div className="h-[46px] rounded-xl mb-2 bg-white">
                <span className="ml-4 text-slate-700 font-inter-medium">$</span>

                <input
                  type="number"
                  id="otherMeal"
                  value={mealAllowanceValue}
                  onChange={(e) => handleMealAllowance(Number(e.target.value))}
                  className={`h-[42px] pl-0 p-4 rounded-xl text-base font-inter-medium leading-7 text-slate-700 outline-none `}
                />
              </div>
            </div>

            <div className="flex-col justify-start items-start gap-[7px] flex">
              <label className="relative inline-flex items-center cursor-pointer gap-2.5">
                <input
                  type="checkbox"
                  checked={enableOverpayment}
                  className="sr-only peer"
                  onChange={(e) => setEnableOverpayment(e.target.checked)}
                />

                <div className=" w-9 h-5 p-0.5 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:start-[2px] after:bg-white after:w-4 after:h-4 after:shadow after:border after:rounded-full after:transition-all  peer-checked:bg-indigo-500"></div>

                <span className="text-slate-700 text-sm font-inter-medium leading-none">
                  Enable overpayment
                </span>
              </label>

              <div className="justify-start items-start gap-1 inline-flex">
                {/* <img src={dangerIcon} className="w-3 h-3" alt="DangerIcon" /> */}

                <div className="screen-lg:w-[428px] screen-md:w-[428px] w-full text-slate-500 text-xs font-inter-normal leading-none">
                  Allow users to use their personal credit card when going over
                  set allowance
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step3;
