export const selectStylesDirectory = {
  container: (styles) => ({ ...styles, width: "100%" }),
  control: (styles) => ({
    ...styles,
    color: "#2D3958",
    backgroundColor: "#F7F9FF",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderWidth: 0,
    paddingTop: "6px",
    paddingBottom: "6px",
    borderRadius: 8,
    boxShadow: "none",
    fontSize: "16px",
    fontFamily: "Inter-Medium",
    minWidth: "160px",
    height: "48px",
    cursor: "pointer",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? "#F7F9FF !important"
        : isSelected
        ? `#F7F9FF !important`
        : "white !important",
      color: isDisabled ? "#ccc" : "#2D3958",
      cursor: isDisabled ? "not-allowed" : "default",
      fontSize: "16px",
      zIndex: 1,
    };
  },
  input: (styles) => ({ ...styles }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "0px",
    paddingRight: "0px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#DEE2E6",
    fontSize: "16px",
  }),
  singleValue: (styles, { data }) => ({ ...styles, color: "#2D3958" }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#2D3958",
    paddingLeft: "0px",
    paddingRight: "0px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (styles) => ({
    ...styles,
    borderRadius: 12,
    overflow: "hidden",
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
};

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    color: "#2D3958",
    backgroundColor: "#F7F9FF",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderWidth: 0,
    paddingTop: "6px",
    paddingBottom: "6px",
    borderRadius: 8,
    boxShadow: "none",
    fontSize: "16px",
    fontFamily: "Inter-Regular",
    minWidth: "160px",
    height: "48px",
    cursor: "pointer",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? "#F7F9FF !important"
        : isSelected
        ? `#F7F9FF !important`
        : "white !important",
      color: isDisabled ? "#ccc" : "#2D3958",
      cursor: isDisabled ? "not-allowed" : "default",
      fontSize: "16px",
      zIndex: 1,
    };
  },
  input: (styles) => ({ ...styles }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "0px",
    paddingRight: "0px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#DEE2E6",
    fontSize: "16px",
  }),
  singleValue: (styles, { data }) => ({ ...styles, color: "#2D3958" }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#2D3958",
    paddingLeft: "0px",
    paddingRight: "0px",
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: 12,
    overflow: "hidden",
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
};

export const customStyles = {
  control: (styles) => ({
    ...styles,
    color: "#3A3A3A",
    background: "#FFFFFF",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderWidth: 1,
    borderColor: "#DEE2E6",
    paddingTop: "6px",
    paddingBottom: "6px",
    borderRadius: 12,
    boxShadow: "none",
    fontSize: "16px",
    fontFamily: "Inter-Regular",
    minWidth: "100%",
    height: "48px",
  }),

  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? "#F7F9FF !important"
        : isSelected
        ? `#F7F9FF !important`
        : "white !important",
      color: isDisabled ? "#ccc" : "#2D3958",
      cursor: isDisabled ? "not-allowed" : "default",
      fontSize: "16px",
    };
  },
  input: (styles) => ({ ...styles }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "0px",
    paddingRight: "0px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#DEE2E6",
    fontSize: "16px",
  }),
  singleValue: (styles, { data }) => ({ ...styles, color: "#3A3A3A" }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#3A3A3A",
  }),
  dropdownIndicator: (styles) => ({
    paddingLeft: "0px",
    paddingRight: "0px",
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
};

export const customStylesSubsidy = {
  control: (styles) => ({
    ...styles,
    color: "#3A3A3A",
    background: "#F7F9FF",
    backgroundColor: "#F7F9FF",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderWidth: 1,
    borderColor: "#DEE2E6",
    paddingTop: "6px",
    paddingBottom: "6px",
    borderRadius: 12,
    boxShadow: "none",
    fontSize: "16px",
    fontFamily: "Inter-Regular",
    minWidth: "100%",
    height: "48px",
  }),

  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? "#F7F9FF !important"
        : isSelected
        ? `#F7F9FF !important`
        : "white !important",
      color: isDisabled ? "#ccc" : "#2D3958",
      cursor: isDisabled ? "not-allowed" : "default",
      fontSize: "16px",
    };
  },
  input: (styles) => ({ ...styles }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "0px",
    paddingRight: "0px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#000",
    fontSize: "16px",
  }),
  singleValue: (styles, { data }) => ({ ...styles, color: "#3A3A3A" }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#3A3A3A",
  }),
  dropdownIndicator: (styles) => ({
    paddingLeft: "0px",
    paddingRight: "0px",
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
};

export const enableScroll = () => {
  const htmlTag = document.querySelector("html");
  htmlTag.classList.remove("disable-scroll");
  htmlTag.classList.add("enable-scroll");
};
