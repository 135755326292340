export const ORDER_ITEM_OPTIONS_COUNT = 2;
export const ODC_TAGLINE_LENGTH = 270;

export const TIP_VALUES_DESKTOP = ["10", "12", "15", "18", "20", "Other"];

export const TIP_VALUES_MOBILE = ["10", "15", "Other"];

export const ORDER_CANCELLATION_HOURS = 72;

export const IS_LARGE_SCREEN = window.innerWidth > 1024;
