import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { MobXProviderContext } from "mobx-react";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function UploadCSV({ visible, setModal, teams, mealPlanId }) {
  const [selectedTeams, setSelectedTeams] = useState([]);

  const { mealManagementStore } = useContext(MobXProviderContext);

  let labels = teams?.map((team) => {
    return { label: team?.attributes?.name, value: team?.id };
  });

  const applyPressed = () => {
    mealManagementStore
      .updateMealPlan(mealPlanId, {
        meal_series: {
          team_meal_plans_attributes: selectedTeams?.map((team) => {
            return { team_id: team?.value };
          }),
        },
      })
      .then(() => setModal(false));
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] d-col items-center justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-[37px] pb-8 py-[40px] text-left align-middle shadow-xl transition-all">
                <div className="d-row w-full justify-between items-center">
                  <h2 className="text-2xl font-inter-bold">Upload CSV</h2>
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <FontAwesomeIcon
                  size="3x"
                  icon={faCloudUpload}
                  className="mb-[12px]"
                />

                <span className="font-inter-medium text-sm text-dark-gray">
                  {" "}
                  Drag your file here to upload
                </span>

                <p className="text-dark-gray font-inter-medium text-sm max-w-[439px] text-center my-[24px]">
                  Uploaded files need to be in the same format as the CSV
                  template provided by hungerhub
                </p>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light-gray text-heading btn-style mb-3 py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Download CSV template
                  </button>

                  <button
                    className="btn-purple bg-catering btn-style py-3 px-6 ml-3"
                    onClick={applyPressed}
                  >
                    Upload file
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default UploadCSV;
