import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { MobXProviderContext } from "mobx-react";
import { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getToBeExcludedDates } from "utils/helpers/dateTime";

const DPicker = ({
  selectedDate,
  dateFormat = "MMMM d, yyyy",
  className,
  pickerClassName,
  minDate,
  activeRestaurant,
  availableDates,
  getAvailableDates,
  getAvailableTimes,
  fromFilters,
  fromModal,
  internalAddress,
  setSelectedDate,
  isGroupOrder,
  isCart,
  isCheckout,
  isModal,
  isInternalSelection,
  placeholderText = "Select Date",
}) => {
  const store = useContext(MobXProviderContext);
  const [opened, setOpened] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  //   const router = useRouter();

  console.log("selected", selectedDate);
  return (
    <div
      className={classNames(className, { "cursor-pointer": true })}
      onClick={() => setOpened(true)}
    >
      {!isCart ? (
        <DatePicker
          open={opened}
          forceShowMonthNavigation={true}
          includeDates={availableDates?.map((date) => moment(date).toDate())}
          onMonthChange={(date) => {
            if (fromModal) {
              store?.userStore?.getAvailableDates({
                address: internalAddress,
                month: moment(date).month() + 1,
                year: moment(date).year(),
                current_date_time: moment().format("YYYY-MM-DD HH:mm"),
              });
            } else {
              getAvailableDates({
                month: moment(date).month() + 1,
                year: moment(date).year(),
              });
            }
          }}
          onCalendarOpen={() => {
            if (selectedDate)
              store?.userStore?.getAvailableDates({
                address: internalAddress,
                month: moment(selectedDate).month() + 1,
                year: moment(selectedDate).year(),
                current_date_time: moment().format("YYYY-MM-DD HH:mm"),
              });
          }}
          placeholderText={placeholderText}
          selected={selectedDate ? new Date(moment(selectedDate)) : ""}
          dateFormat={dateFormat}
          onFocus={(e) => e.target.blur()}
          minDate={minDate}
          onClickOutside={() => setOpened(false)}
          onChange={(date) => {
            setSelectedDate(moment(date).format("YYYY-MM-DD"));
          }}
          className={`rounded-md pl-3 py-1 bg-light-gray cursor-pointer text-primary-black text-left text-md focus:outline-none ${pickerClassName} ${
            pickerClassName ? "" : "font-inter-medium"
          } text-md`}
        />
      ) : (
        <DatePicker
          open={opened}
          onMonthChange={(date) => setCurrentMonth(date)}
          //   excludeDates={getToBeExcludedDates(
          //     activeRestaurant,
          //     currentMonth || selectedDate
          //   )}
          placeholderText={placeholderText}
          selected={
            selectedDate
              ? moment(selectedDate).isValid() && new Date(moment(selectedDate))
              : ""
          }
          dateFormat={dateFormat}
          onFocus={(e) => e.target.blur()}
          minDate={minDate}
          onClickOutside={() => setOpened(false)}
          onChange={(date) => {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            setSelectedDate(formattedDate);
            setTimeout(() => {
              setOpened(false);
            }, 20);
          }}
          className={`rounded-md pl-3 py-1 bg-light-gray cursor-pointer text-primary-black text-left text-md focus:outline-none ${pickerClassName} ${
            pickerClassName ? "" : "font-inter-medium"
          } text-md`}
        />
      )}

      <div className="bg-light-gray overflow-hidden rounded-md">
        <FontAwesomeIcon
          icon={faCalendar}
          color={"#2D3958"}
          size="1x"
          className="m-1.5 small-icon overflow-hidden"
        />
      </div>
    </div>
  );
};

export default observer(DPicker);
