import React, { useState, useEffect, useContext } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import classNames from "classnames";
import { MobXProviderContext } from "mobx-react";
import { useNavigate } from "react-router-dom";

function Index() {
  const { onboardingStore } = useContext(MobXProviderContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [disableButton, setDisableButton] = useState(true);
  const [showSkipButton, setShowSkipButton] = useState(false);
  let navigate = useNavigate();

  const nextStep = () => {
    if (currentStep === 5 || currentStep === 6) navigate("/");
    setCurrentStep((prevStep) => prevStep + 1);
    setDisableButton(true);
  };

  const addNewAddress = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setDisableButton(true);
  };

  const enableButton = (value) => {
    setDisableButton(value);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 disableButton={enableButton} />;
      case 2:
        return <Step2 disableButton={enableButton} />;
      case 3:
        return <Step3 disableButton={enableButton} />;
      case 4:
        return <Step4 disableButton={enableButton} />;
      case 5:
        return <Step5 disableButton={enableButton} />;
      case 6:
        return <Step6 disableButton={enableButton} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    onboardingStore.fetchCuisineTypesData();

    const handleResize = () => {
      setScreenHeight({
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShowSkipButton(currentStep === 4);
  }, [currentStep]);

  return (
    <>
      {currentStep !== 7 && (
        <>
          <div
            style={{ height: screenHeight <= 321 ? "70%" : "80vh" }}
            className="relative flex flex-col items-center justify-center"
          >
            {/* <img className="w-[114px] h-[41px] mb-8" src={logo} alt="logo" /> */}

            {renderStep()}

            {currentStep !== 7 && (
              <div className="">
                <hr className="screen-lg:hidden screen-md:hidden mb-4 h-[0px] border border-zinc-200" />

                <div className="px-2.5 d-row items-cente justify-center mt-10">
                  {currentStep === 4 && showSkipButton && (
                    <button
                      className="w-full screen-lg:w-6/12 text-indigo-900 font-inter-semibold screen-lg:h-16 screen-md:h-16 h-[46px]"
                      onClick={nextStep}
                    >
                      Skip for now
                    </button>
                  )}

                  {currentStep === 5 && (
                    <button
                      onClick={addNewAddress}
                      className={classNames(
                        "screen-lg:w-[232px] screen-md:w-[232px] w-full screen-lg:h-16 screen-md:h-16 h-[46px] rounded-md text-indigo-900 border border-1 mr-[12px] border-indigo-900 text-sm screen-lg:left-11 screen-lg:bottom-12 screen-md:left-11 screen-md:bottom-12 font-inter-medium"
                      )}
                    >
                      No, add new Address{" "}
                    </button>
                  )}

                  <button
                    disabled={disableButton}
                    onClick={nextStep}
                    className={classNames(
                      "screen-lg:w-[232px] screen-md:w-[232px] w-full screen-lg:h-16 screen-md:h-16 h-[46px] rounded-md text-white text-sm screen-lg:left-11 screen-lg:bottom-12 screen-md:left-11 screen-md:bottom-12 font-inter-medium",
                      {
                        "bg-gray-200": disableButton,
                        "bg-indigo-900": !disableButton,
                      }
                    )}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Index;
