import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
} from "react";
import { InfoButton, InfoCatering } from "assets/img";
import { MobXProviderContext } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import { NotificationBanner } from "components";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";

function DeliveryInfo() {
  const [requestUpdate, setRequestUpdate] = useState(false);
  const { deliveryInfoStore, userStore } = useContext(MobXProviderContext);
  const [enableName, setEnableName] = useState(true);
  const [enablePhone, setEnablePhone] = useState(true);
  const [requestType, setRequestType] = useState("");

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [signature, setSignature] = useState("");
  const nameInputRef = useRef(null);
  const phoneInputRef = useRef(null);

  const navigate = useNavigate();

  const defaultLocation = userStore.defaultLocation;
  const locationAddress = deliveryInfoStore.locationAddress;
  const locationAddresses = deliveryInfoStore.locationAddresses;
  const primaryAddress = deliveryInfoStore.primaryAddress;

  const requestUpdateClick = useCallback(() => {
    // setRequestUpdate(!requestUpdate);
    navigate("/delivery-info/create");
  });

  useEffect(() => {
    setPhone(locationAddress?.attributes?.delivery_contact_phone);
    setName(locationAddress?.attributes?.delivery_contact_name);
    setDeliveryInstructions(locationAddress?.attributes?.delivery_instructions);
    setSignature(locationAddress?.attributes?.delivery_verification);
  }, [locationAddress]);

  useEffect(() => {
    // deliveryInfoStore.getLocationAddress(defaultLocation?.value);
    deliveryInfoStore?.getLocationAddresses(defaultLocation?.value);
  }, []);

  const updateLocationNameAndContact = (fieldToUpdate) => {
    const payload = {
      ...fieldToUpdate,
    };
    deliveryInfoStore
      .updateLocationNameAndContact(defaultLocation?.value, payload)
      .then(() => {
        setEnablePhone(true);
        setEnableName(true);
      });
  };

  useEffect(() => {
    if (signature)
      updateLocationNameAndContact({ delivery_verification: signature });
  }, [signature]);

  return (
    <div className="px-[32px] py-[24px] w-full pb-32">
      {requestType !== "" && (
        <NotificationBanner
          message={`Your request to update ${requestType} has been sent to hungerhub for review, you will be notified upon changes taking effect`}
          messageType="warning"
        />
      )}

      <h1 className="text-dark-gray mt-[16px] text-[24px] font-inter-semibold">
        Delivery info
      </h1>

      <div className="w-full bg-background rounded-lg py-[16px] lg:px-[32px] md:px-[32px] px-[16px] mb-[51px] mt-[24px]">
        <div className="d-row justify-between">
          <h2 className="text-dark-gray text-[20px] font-inter-semibold">
            Primary Delivery Address{" "}
          </h2>

          <button
            // onClick={() => navigate("/home/order-detail/1")}
            onClick={() => navigate("/delivery-info/12")}
            className="btn-outline border-none d-row items-center lg:mt-0 md:mt-0 mt-2"
          >
            <img
              layout="fixed"
              className="object-contain mr-[4px] h-[16px] w-[16px] object-contain hidden lg:flex md:flex"
              alt="info-button"
              src={InfoCatering}
            />
            <small className="font-inter-semibold text-sm text-catering">
              View details
            </small>
          </button>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 mt-[26px] gap-4">
          <div className="d-col">
            <small className="font-inter-regular text-[14px] text-dark-gray">
              Nickname
            </small>
            <small className="lg:mt-[8px] md:mt-[8px] sm:mt-[4px] text-dark-gray font-inter-medium text-sm">
              {primaryAddress?.attributes?.nickname}
            </small>
          </div>

          <div className="d-col">
            <small className="font-inter-regular text-[14px] text-dark-gray">
              Address
            </small>
            <small className="lg:mt-[8px] md:mt-[8px] sm:mt-[4px] text-dark-gray font-inter-medium text-sm">
              {primaryAddress?.attributes?.suite
                ? primaryAddress?.attributes?.suite + " - "
                : ""}
              {primaryAddress?.attributes?.street_number}{" "}
              {primaryAddress?.attributes?.street_name},{" "}
              {primaryAddress?.attributes?.city},{" "}
              {primaryAddress?.attributes?.province}{" "}
              {primaryAddress?.attributes?.postal_code}
            </small>
          </div>

          <div className="d-col">
            <small className="font-inter-regular text-[14px] text-dark-gray">
              Point of contact
            </small>
            <small className="lg:mt-[8px] md:mt-[8px] sm:mt-[4px] text-dark-gray font-inter-medium text-sm">
              {primaryAddress?.attributes?.delivery_contact_phone}{" "}
            </small>
          </div>

          <div className="d-col">
            <small className="font-inter-regular text-[14px] text-dark-gray">
              Destination code
            </small>
            <small className="lg:mt-[8px] md:mt-[8px] sm:mt-[4px] text-dark-gray font-inter-medium text-sm">
              {primaryAddress?.attributes?.destination_code}{" "}
            </small>
          </div>
        </div>
      </div>

      <div className="w-full lg:d-row md:d-row d-col lg:flex md:flex lg:justify-between md:justify-between flex-col justify-between">
        <div className="flex flex-col lg:w-2/3 md:w-2/3">
          <h2 className="text-dark-gray text-[20px] font-inter-semibold">
            Other delivery addresses on file
          </h2>
          <small className="font-inter-regular text-dark-gray text-sm">
            You can add more addresses for quick selection when you are making
            group orders or creating new uncatering meals
          </small>
        </div>
        <div className="flex justify-end lg:w-3/12 md:w-3/12 w-full lg:mt-0 md:mt-0 mt-3">
          <button
            className="btn-blue-accent rounded-xl md:h-[65px] md:px-3 lg:px-3"
            onClick={requestUpdateClick}
          >
            + Add a new delivery address
          </button>
        </div>
      </div>

      <div className="w-full overflow-x-auto">
        <div className="w-[1085px] bg-background h-[68px] rounded-xl grid grid-cols-5 items-center text-left px-[32px] mt-[24px]">
          <div className="mt-2 border border-r-1 border-t-0 border-b-0 border-l-0">
            <span className="text-sm font-inter-medium text-dark-gray">
              Nickname
            </span>
          </div>

          <div className="mt-2 border border-r-1 border-t-0 border-b-0 border-l-0 px-[16px]">
            <span className="text-sm font-inter-medium text-dark-gray">
              Address
            </span>
          </div>

          <div className="mt-2 border border-r-1 border-t-0 border-b-0 border-l-0 px-[16px]">
            <span className="text-sm font-inter-medium text-dark-gray">
              Point of contact
            </span>
          </div>

          <div className="mt-2 border border-r-1 border-t-0 border-b-0 border-l-0 border-r-0 px-[16px]">
            <span className="text-sm font-inter-medium text-dark-gray">
              Destination code
            </span>
          </div>

          <div className="mt-2 border border-r-1 border-t-0 border-b-0 border-l-0 border-r-0 px-[16px]"></div>
        </div>
        {locationAddresses?.map((address, index) => {
          return (
            <div
              className={classNames({
                "bg-white border border-1": index % 2 === 0,
                "w-[1085px] bg-background h-[68px] rounded-xl grid grid-cols-5 items-center text-left px-[32px] mt-[12px]": true,
              })}
            >
              <div className="border border-r-1 border-t-0 border-b-0 border-l-0">
                <span className="text-sm font-inter-medium text-dark-gray">
                  {address?.attributes?.nickname}
                </span>
              </div>

              <div className="border border-r-1 border-t-0 border-b-0 border-l-0 px-[16px]">
                <span className="text-sm font-inter-medium text-dark-gray">
                  {address?.attributes?.suite
                    ? address?.attributes?.suite + " - "
                    : ""}
                  {address?.attributes?.street_number}{" "}
                  {address?.attributes?.street_name},{" "}
                  {address?.attributes?.city}, {address?.attributes?.province}{" "}
                  {address?.attributes?.postal_code}
                </span>
              </div>

              <div className="border border-r-1 border-t-0 border-b-0 border-l-0 px-[16px]">
                <span className="text-sm font-inter-medium text-dark-gray">
                  {address?.attributes?.delivery_contact_phone}
                </span>
              </div>

              <div className="border border-r-1 border-t-0 border-r-0 border-b-0 border-l-0 px-[16px] d-row justify-between items-center">
                <span className="text-sm font-inter-medium text-dark-gray">
                  {address?.attributes?.destination_code}
                </span>
              </div>

              <div className="border border-r-1 border-t-0 border-r-0 border-b-0 border-l-0 px-[16px] d-row justify-between items-center">
                <button
                  onClick={() => navigate(`/delivery-info/${address?.id}`)}
                  className="btn-outline border-none d-row items-center"
                >
                  <img
                    layout="fixed"
                    className="object-contain mr-[4px] h-[16px] w-[16px] object-contain lg:flex md:hidden"
                    alt="info-button"
                    src={InfoCatering}
                  />
                  <small className="ml-3 font-inter-semibold text-sm text-catering">
                    View details
                  </small>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(DeliveryInfo);
