import React, { useState } from "react";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { ChevronLeftIcon, ChevronRightIcon } from "assets/img";

// Extend dayjs with plugins
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

const WeekPicker = ({ width }) => {
  // Initialize the current week state
  const [currentDate, setCurrentDate] = useState(dayjs());

  const goToNextWeek = () => {
    setCurrentDate(currentDate.add(1, "week"));
  };

  const goToPrevWeek = () => {
    setCurrentDate(currentDate.subtract(1, "week"));
  };

  // Format the date to display the week range
  const startOfWeek = currentDate.startOf("week").format("ddd, MMM D, YYYY");
  const endOfWeek = currentDate.endOf("week").format("ddd, MMM D, YYYY");

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={`bg-light-gray ${
        width ?? "w-[255px]"
      }  h-[48px] flex flex-row items-center justify-center rounded-lg`}
    >
      <button onClick={goToPrevWeek} className="flex justify-start">
        <img src={ChevronLeftIcon} className="h-[20px] w-[20px]" />
      </button>

      <span className="font-inter-regular text-sm text-dark-gray px-[8px] md:flex md:justify-center lg:w-8/12 md:w-11/12">{`${startOfWeek}`}</span>

      <button onClick={goToNextWeek} className="flex justify-end">
        <img src={ChevronRightIcon} className="h-[20px] w-[20px]" />
      </button>
    </div>
  );
};

export default WeekPicker;
