import React, { useState, useEffect, useContext } from "react";
import SmallTeamMessage from "./teamMessage";
import ActiveStepIndicator from "./activeStepIndicator";
import { peopleCount } from "utils/constants/onboardingStep";
import classNames from "classnames";
// import { MobXProviderContext } from "mobx-react";

const Step5 = ({ disableButton }) => {
  // const { onboardingStore } = useContext(MobXProviderContext);
  const [expectedBag, setExpectedBag] = useState("");

  useEffect(() => {
    disableButton(false);
    // onboardingStore.handleExpectedBagData(expectedBag);
  }, [expectedBag, disableButton]);

  return (
    <div className="d-col justify-center content-center items-center">
      <div className="flex-col items-center content-center">
        <ActiveStepIndicator step={5} />
        <div className="expected-bag-text xs:w-full-235 font-inter-semibold">
          Is this is where this meal should be delivered?
        </div>

        <div className="mb-0.5 w-full xs:w-full-215 mt-5 d-col bg-light-gray px-[24px] py-[12px] rounded-lg">
          <strong>99 John street,Toronto, ON M5V 0S6, Canada</strong>
          <small>Office: huhngerhub office, HUB20</small>
        </div>
      </div>
    </div>
  );
};

export default Step5;
