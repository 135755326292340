import { getUpdatedFilterValue } from "utils/helpers/string";

const FilterOptions = ({
  filterType,
  filters,
  updateChosenRestaurantFilters,
  router,
}) => {
  return (
    <div className="flex flex-col">
      {filters.map((filter) => (
        <div key={`${filter}${router?.query?.[filterType]?.includes(filter)}`}>
          <label className="select-none inline-flex items-center py-2 text-sm max-w-[170px]">
            <input
              autoComplete="off"
              type="checkbox"
              //   className="accent-checkbox-blue focus:accent-checkbox-blue;"
              onChange={(e) =>
                updateChosenRestaurantFilters(filterType, e, true)
              }
              value={filter}
              checked={
                router?.query?.[filterType]?.includes(
                  getUpdatedFilterValue(filter)
                ) ?? false
              }
            />
            <span className="ml-[6px] font-inter-medium text-[14px]">
              {filter}
            </span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default FilterOptions;
