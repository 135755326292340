import React, { useState, useEffect, useRef } from "react";
import Select from "components/dropdownSelect";

const DesktopTimepicker = ({ timeValue, onTimeSelected, mealName }) => {
  const hours = Array.from(Array(12), (_, index) =>
    String(index + 1).padStart(2, "0")
  );
  const minutes = ["00", "15", "30", "45"];
  const [defaulTime, defaultAmPm] = timeValue?.split(" ");
  const [defaultHour, defaultMinute] = defaulTime?.split(":");
  const [showHourPopup, setShowHourPopup] = useState(false);
  const [showMinutePopup, setShowMinutePopup] = useState(false);
  const [isAm, setIsAm] = useState(defaultAmPm || "PM");
  const [selectedHour, setSelectedHour] = useState(defaultHour || "12");
  const [selectedMinute, setSelectedMinute] = useState(defaultMinute || "00");
  const popupRef = useRef(null);

  const toggleAmPm = () => {
    setIsAm((prevIsAm) => (prevIsAm === "AM" ? "PM" : "AM"));
  };

  const handleHourOptionPopup = (value) => {
    setShowHourPopup(value);
  };

  const handleHourValue = (hour) => {
    setSelectedHour(hour);
    setShowHourPopup(false);
  };

  const handleMinOptionPopup = (value) => {
    setShowMinutePopup(value);
  };

  const handleMinValue = (minute) => {
    setSelectedMinute(minute);
    setShowMinutePopup(false);
  };

  useEffect(() => {
    const timeVal = `${selectedHour}:${selectedMinute} ${isAm}`;
    onTimeSelected(timeVal, mealName);
  }, [selectedHour, selectedMinute, isAm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowHourPopup(false);
        setShowMinutePopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="w-full screen-lg:w-2/3 screen-md:w-2/3 bg-slate-50 pl-6 py-3 flex items-start screen-lg:items-center screen-lg:justify-center screen-md:items-center screen-md:justify-center rounded-l-md">
        <div
          name="hours"
          className="text-slate-700 font-inter-normal bg-transparent appearance-none outline-none"
          onClick={() => setShowHourPopup(true)}
        >
          {selectedHour}
        </div>

        <span className="text-slate-700 fornt-inter-medium mx-3">: </span>

        <div
          name="minutes"
          className="text-slate-700 bg-transparent font-inter-medium appearance-none mr-4 outline-none"
          onClick={() => setShowMinutePopup(true)}
        >
          {selectedMinute}
        </div>
      </div>

      {showHourPopup && (
        <div ref={popupRef} className="relative right-24">
          <Select
            options={hours}
            showOptions={handleHourOptionPopup}
            setValue={handleHourValue}
            selectedValue={selectedHour}
            values={"hours"}
          />
        </div>
      )}

      {showMinutePopup && (
        <div ref={popupRef} className="relative right-24">
          <Select
            options={minutes}
            showOptions={handleMinOptionPopup}
            setValue={handleMinValue}
            selectedValue={selectedMinute}
            values={"minutes"}
          />
        </div>
      )}

      <div
        className={`w-[49px] h-[52px] bg-indigo-500 flex items-center justify-center rounded-r-md`}
        onClick={toggleAmPm}
      >
        <span className="text-white font-inter-medium">{isAm}</span>
      </div>
    </>
  );
};

export default DesktopTimepicker;
