import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useContext, useEffect, useReducer, useState } from "react";

// import { StoreContext } from "pages/_app";
import { MobXProviderContext } from "mobx-react";
import { SearchBar } from "components";
import {
  changeSpacesToHyphens,
  debounce,
  getETAString,
  isCateringOrder,
} from "utils/helpers/restaurants";
// import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import { dateFormat, ordersDateFormat } from "utils/helpers/dateTime";
import { priceFormatter } from "utils/helpers/validation";
import { toTitleCase } from "utils/helpers/string";
import { SortIcon } from "assets/img";
import { isPresent } from "utils/helpers/array";
import { getOrderStatus } from "utils/helpers/order";
import { getAccessToken } from "utils/helpers/localStorage";
import { enableScroll } from "utils/helpers/styles";

const OrdersPage = () => {
  const initialState = {
    searchText: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const router = null;
  let { currentUser, loginModal } = userStore ?? {};
  let { ordersPagy } = groupOrderStore ?? {};
  let { searchText } = provider;
  const [sortByDate, setSortByDate] = useState("desc");
  const [orders, setOrders] = useState([]);
  const access_token = null;

  useEffect(() => {
    if (
      store.userStore?.isHydrated &&
      currentUser?.attributes?.access_token &&
      access_token
    ) {
      getOrders();

      setTimeout(() => store.userStore.setLoginModal(false), 1000);
      enableScroll();
    }
  }, [currentUser?.attributes?.access_token, store?.userStore?.isHydrated]);

  useEffect(() => {
    if (
      store.userStore?.isHydrated &&
      !loginModal &&
      !currentUser?.attributes?.access_token &&
      !access_token
    ) {
      setTimeout(() => store.userStore.setLoginModal(true), 1000);
    }
  }, [currentUser?.attributes?.access_token, store?.userStore?.isHydrated]);

  useEffect(() => {
    getOrders();
  }, [searchText]);

  const sortByDeliveryDate = () => {
    if (sortByDate === "asc") {
      // Sort in descending order
      setOrders(
        orders
          .slice()
          .sort(
            (a, b) =>
              new Date(b?.attributes?.delivery_date) -
              new Date(a?.attributes?.delivery_date)
          )
      );
      setSortByDate("desc");
    } else {
      // Sort in ascending order
      setOrders(
        orders
          .slice()
          .sort(
            (a, b) =>
              new Date(a?.attributes?.delivery_date) -
              new Date(b?.attributes?.delivery_date)
          )
      );
      setSortByDate("asc");
    }
  };

  const getOrders = (page = 1) => {
    // store.userStore?.setLoader(true);
    // store.groupOrderStore
    //   .getAllOrders({ page, search_q: searchText })
    //   .then((response) => {
    //     store.userStore?.setLoader(false);
    //     if (isPresent(response?.data)) setOrders(response?.data);
    //     else if (response?.data?.errors) {
    //       setTimeout(() => store.userStore.setLoginModal(true), 1000);
    //     }
    //   })
    //   .catch(() => store.userStore?.setLoader(false));
  };

  const pageClick = (data) => {
    getOrders(data.selected + 1);
  };

  const handleOnChange = debounce((e) => {
    dispatch({ field: "searchText", value: e?.target?.value });
  }, 1000);

  const onClickViewOrder = (attributes) => {
    if (isCateringOrder(attributes?.order_type))
      router?.push(`/order_confirmation/${attributes?.slug}`);
    else router.push(`/group-order/admin/orders/${attributes?.slug}`);
  };

  return (
    <>
      <div className="flex flex-col w-full justify-center mx-auto p-5 md:p-[40px] sm:mt-[30px] md:mt-[15px]">
        <div className="flex items-center justify-between">
          <span className="text-[32px] font-inter-semibold">Your Orders</span>

          <SearchBar
            value={searchText}
            placeholder="Search"
            handleOnChange={handleOnChange}
          />
        </div>

        <div className="mt-6">
          <div className="flex w-full bg-background px-5 py-[16px] mb-3">
            <div className="flex text-sm w-1/2 sm:w-1/4 md:w-2/12">
              <span className="text-sm font-inter-medium">Type</span>
            </div>

            <div className="flex text-sm w-1/2 sm:w-1/4 md:w-2/12">
              <span className="flex text-right items-center sm:text-left w-full text-sm font-inter-medium">
                Delivery Date
                <div
                  className="ml-2 cursor-pointer"
                  onClick={sortByDeliveryDate}
                >
                  <img src={SortIcon} className="icon" />
                </div>
              </span>
            </div>

            <div className="hidden sm:flex text-sm sm:w-1/4 md:w-2/12">
              <span className="text-sm font-inter-medium">Delivery Time</span>
            </div>

            <div className="hidden sm:flex text-sm sm:w-1/4 md:w-2/12 md:mr-4">
              <span className="text-sm font-inter-medium">Restaurant</span>
            </div>

            <div className="hidden md:flex text-sm sm:w-1/4 md:w-2/12">
              <span className="text-sm font-inter-medium">Total</span>
            </div>

            <div className="hidden md:flex text-sm sm:w-1/4 md:w-2/12">
              <span className="text-sm font-inter-medium">Status</span>
            </div>

            <div className="hidden md:flex sm:w-1/4 md:w-2/12"></div>
          </div>

          {access_token &&
            orders?.map((order, index) => {
              const { attributes } = order;

              return (
                <div
                  key={`order-${index}`}
                  onClick={() => onClickViewOrder(attributes)}
                  className="flex cursor-pointer hover:bg-hover-light items-center border-b border-border-gray py-10 md:py-7 last:border-0 px-5 w-full"
                >
                  <div className="w-1/2 sm:w-1/4 md:w-2/12">
                    {!isCateringOrder(attributes?.order_type) ? (
                      <div className="flex flex-col">
                        <span className="text-sm font-inter-medium">
                          Group Order
                        </span>
                        <span className="text-sm">{attributes?.title}</span>
                      </div>
                    ) : (
                      <span className="text-sm font-inter-medium">
                        Catering
                      </span>
                    )}
                  </div>

                  <div className="w-1/2 sm:w-1/4 md:w-2/12 text-right sm:text-left">
                    <span className="text-sm font-inter-medium mb-2">
                      {moment(attributes?.delivery_date, dateFormat).format(
                        ordersDateFormat
                      )}
                    </span>
                  </div>

                  <div className="hidden sm:flex sm:w-1/4 md:w-2/12">
                    <span className="text-sm font-inter-medium">
                      {attributes?.delivery_time}
                    </span>
                  </div>

                  <div className="hidden sm:flex sm:w-1/4 md:w-2/12 md:mr-4">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        store.userStore.setLoader(true);
                        router.push(
                          `/restaurant/${
                            attributes?.menu_code
                          }/${changeSpacesToHyphens(
                            attributes?.restaurant_name
                          )}${
                            attributes?.source_of_business == "restaurant"
                              ? "?utm_source=odc"
                              : ""
                          }`
                        );
                      }}
                      className="text-left"
                    >
                      <span className="text-md text-right font-inter-semibold text-hh-accent-light">
                        {attributes?.restaurant_name}
                      </span>
                    </button>
                  </div>

                  <div className="hidden md:flex sm:w-1/4 md:w-2/12">
                    <span className="text-sm font-inter-medium">
                      {priceFormatter(attributes?.grand_total)}
                    </span>
                  </div>

                  <div className="hidden md:flex sm:w-1/4 md:w-2/12">
                    {attributes?.dropoff_task_eta &&
                    attributes?.status == "confirmed" ? (
                      <div>
                        <span className="text-sm font-inter-semibold mb-2">
                          {getETAString(order)}
                        </span>

                        <span className="animate-dot text-green-600 ml-2">
                          •
                        </span>
                      </div>
                    ) : (
                      <span className="text-sm capitalize font-inter-medium">
                        {toTitleCase(
                          getOrderStatus(
                            attributes?.status,
                            attributes?.shipment_acceptance_status
                          )
                        )}
                      </span>
                    )}
                  </div>

                  <div className="hidden md:flex sm:w-1/4 md:w-2/12">
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();

                        onClickViewOrder(attributes);
                      }}
                      className="flex w-auto btn-style items-center justify-center p-[10px] border-hh-accent-light border text-hh-accent-light hover:text-hh-accent-dark rounded-2xl"
                    >
                      <span className="text-sm font-inter-medium">
                        View Order
                      </span>
                    </button>
                  </div>
                </div>
              );
            })}

          {/* {ordersPagy && (ordersPagy?.next || ordersPagy?.prev) && (
            <div className="mt-12 text-primary-color">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={<span className="gap">...</span>}
                pageCount={ordersPagy?.pages}
                onPageChange={pageClick}
                forcePage={ordersPagy?.page - 1 > 0 ? ordersPagy?.page - 1 : 0}
                containerClassName={"pagination"}
                previousLinkClassName={"previous_page"}
                nextLinkClassName={"next_page"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
              />
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default observer(OrdersPage);
