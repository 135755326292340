import React, { useEffect, useState, useContext } from "react";
import { useManagePlan } from "contexts/ManagePlanContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { NotFound, NotificationBanner } from "components";
import { weekDays } from "utils/constants/onboardingStep";
import { useNavigate, useLocation } from "react-router-dom";
import { MobXProviderContext } from "mobx-react";
import { observable, toJS } from "mobx";
import {
  ChangeDeliveryTime,
  ChangeExpectedOrders,
  ChangeContact,
  ChangeDeliveryInstructions,
  ChangeSubsidy,
  ViewBag,
  ChangeMealPlanName,
  ChangeEnabledDays,
} from "alerts";
import { EditPencil } from "assets/img";
import classNames from "classnames";
import object from "utils/helpers/object";
import { priceFormatter } from "utils/helpers/validation";
import { isPresent } from "utils/helpers/array";

const EditPencilButton = ({ onClick }) => (
  <button onClick={onClick} className="d-row items-center btn-style ml-[5px]">
    <img src={EditPencil} layout="fixed" />
  </button>
);

function UncateringPlans({ managePlanPress, data }) {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="bg-light-gray">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Next order date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Delivery time
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Expected order
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Deadline to order
                </th>

                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {data?.map((meal) => (
                <tr key={meal?.id} className="even:bg-light-gray">
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-bold text-dark-gray sm:pl-3">
                    {meal?.attributes?.name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {meal?.attributes?.next_order_date}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {meal?.attributes?.target_time}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {meal?.attributes?.expected_bags}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {meal?.attributes?.cutoff}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-3">
                    <button
                      onClick={() => {
                        managePlanPress(true, meal?.id);
                      }}
                      className="btn-outline font-inter-medium border-2"
                    >
                      Manage plan
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function UpcomingGroupOrders({ onClick, data, navigate }) {
  console.log(toJS(data));
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="bg-light-gray">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Restaurant
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Order Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Delivery Time
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Budget per user
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Orders Placed
                </th>

                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {data?.map((order) => (
                <tr key={order.id} className="even:bg-light-gray">
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-bold text-dark-gray sm:pl-3">
                    {order?.attributes?.meal_name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {order?.attributes?.restaurant_name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {order?.attributes?.meal_date}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {order?.attributes?.target_time}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {priceFormatter(order?.attributes?.budget_per_user || "")}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    {order?.attributes?.orders_placed}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-3">
                    <button
                      onClick={() =>
                        navigate("/meal-management/group-order/23")
                      }
                      className="btn-outline font-inter-medium border-2"
                    >
                      View order
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const UpcomingCateringOrders = () => {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="bg-light-gray">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Restaurant
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Expected order
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Delivery Time
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              <tr></tr>
            </tbody>
          </table>

          <div className="d-col items-center">
            <small className="text-secondary font-[14px] mt-[24px]">
              No upcoming catering orders
            </small>

            <button className="btn-light-gray">Learn about Catering</button>
          </div>
        </div>
      </div>
    </div>
  );
};

function ListView() {
  const [viewBagModal, setViewBag] = useState(false);
  const {
    managePlan,
    setManagePlan,
    changeDeliveryTime,
    setChangeDeliveryTime,
    changeExpectedOrders,
    setChangeExpectedOrders,
    changeSubsidy,
    setChangeSubsidy,
    setChangeContact,
    changeContact,
    changeDeliveryInstructions,
    setChangeDeliveryInstructions,
    changeMealPlanName,
    setChangeMealPlanName,
    changeEnabledDays,
    setChangeEnabledDays,
  } = useManagePlan();
  const navigate = useNavigate();
  const location = useLocation();

  const { mealManagementStore } = useContext(MobXProviderContext);

  const mealPlans = mealManagementStore.mealPlans;
  const mealPlan = mealManagementStore.mealPlan;
  const groupOrders = mealManagementStore.groupOrders;

  useEffect(() => {
    mealManagementStore
      .fetchMealPlans()
      .then(() => mealManagementStore.fetchGroupOrders());
  }, []);

  const managePlanPress = (value, id) => {
    mealManagementStore.fetchMealPlan(id);
    setManagePlan(value);
  };

  return (
    <div className="rounded-lg flex flex-col min-w-full">
      {managePlan ? (
        <div className="pt-[24px]">
          <button
            className="flex items-center cursor-pointer mb-[24px]"
            onClick={() => setManagePlan(false)}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ width: 14, height: 14 }}
            />
            <span className="text-md text-left font-inter-medium text-black-light ml-3">
              Back
            </span>
          </button>

          <NotificationBanner
            message={
              "Delivery time changes will need to be reviewed and approved by hungerhub."
            }
            messageType="warning"
          />

          <div className="d-row mt-[24px] items-center justify-between">
            <div className="d-row">
              <strong className="text-dark-gray text-[24px] font-inter-medium">
                {mealPlan?.attributes?.name}
              </strong>

              <EditPencilButton
                onClick={() => {
                  setChangeMealPlanName(true);
                }}
              />
            </div>

            <button
              className="btn-blue-accent"
              onClick={() => {
                navigate("/home/request-meal-changes", {
                  state: { referrer: location.pathname },
                });
              }}
            >
              Request changes to a specific meal
            </button>
          </div>

          <div className="d-row my-[16px] items-center justify-between bg-light-gray py-[12px] px-[16px] rounded-lg">
            <small className="font-inter-medium text-sm text-dark-gray">
              Next upcoming order on July 16
            </small>

            <button className="text-catering font-inter-medium text-sm">
              View
            </button>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Delivery time
              </span>
              <strong className="font-inter-semibold text-dark-gray">
                {mealPlan?.attributes?.target_time}
              </strong>
              <p className="text-dark-gray font-inter-regular text-[16px]">
                Please allow for a 15 minute window before/after requested time
              </p>
              <button
                className="btn-white w-[141px] mt-[12px]"
                onClick={() => setChangeDeliveryTime(true)}
              >
                Request Changes
              </button>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Deadline to order
              </span>
              <strong className="font-inter-semibold text-dark-gray">
                {mealPlan?.attributes?.cutoff}
              </strong>
              <p className="text-dark-gray font-inter-regular text-[16px]">
                Deadline to order is set by hungerhub
              </p>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Expected Order{" "}
              </span>
              {/* <strong className="font-inter-semibold text-dark-gray">

                12:00 pm
              </strong> */}

              {mealPlan.attributes?.expected_bags_calculation_mode ===
              "same_day_average" ? (
                <div>
                  {object.isPresent(
                    mealPlan?.attributes?.parsed_expected_bags
                  ) &&
                    Object.entries(
                      mealPlan?.attributes?.parsed_expected_bags
                    ).map((key) => (
                      <small
                        key={key}
                        className="font-inter-semibold text-dark-gray"
                      >
                        {key[0]}: {key[1]}
                      </small>
                    ))}
                </div>
              ) : (
                <small className="font-inter-semibold text-dark-gray">
                  {mealPlan.attributes?.expected_bags}
                </small>
              )}
              <p className="text-dark-gray font-inter-regular text-[16px]">
                The number of people expected to place an order
              </p>
              <button
                className="btn-white w-[141px] mt-[12px]"
                onClick={() => setChangeExpectedOrders(true)}
              >
                Update
              </button>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Enabled days
              </span>

              <div className="mb-0.5 w-full xs:w-full-215">
                <div className="lg:inline-flex md:inline-flex">
                  {weekDays.map((value, index) => (
                    <div
                      key={index}
                      className={classNames({
                        "lg:mr-3 md:mr-3 mt-[6px] lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] hover:bg-indigo-500 hover:text-white rounded-3xl justify-center gap-2 flex": true,
                        "bg-blue-900 text-white":
                          mealPlan?.attributes?.days.includes(
                            value?.toLowerCase()
                          ),
                        "bg-white text-dark-gray":
                          !mealPlan?.attributes?.days.includes(
                            value?.toLowerCase()
                          ),
                      })}

                      // onClick={() => handleRecievingDaysClick(value)}
                    >
                      {value.slice(0, 3)}
                    </div>
                  ))}
                </div>
              </div>

              <button
                className="btn-white w-[141px] mt-[12px]"
                onClick={() => setChangeEnabledDays(true)}
              >
                Update
              </button>
            </div>

            <div className="min-h-[182px] bg-light-gray rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Meal subsidy
              </span>
              <strong className="font-inter-semibold text-dark-gray">
                {mealPlan?.attributes?.subsidy_type === "weekly" ? (
                  "Weekly " + priceFormatter(mealPlan?.attributes?.subsidy)
                ) : (
                  <div className="grid grid-cols-3 gap-2">
                    {mealPlan.attributes?.daily_subsidy &&
                      Object.keys(mealPlan.attributes?.daily_subsidy)?.map(
                        (day) => (
                          <div className="bg-white capitalize px-[16px] py-[12px] d-row rounded-3xl">
                            <small className="mr-2 font-inter-medium text-dark-gray text-[16px]">
                              {day}:
                            </small>
                            <small className="font-inter-medium text-dark-gray text-[16px]">
                              {priceFormatter(
                                mealPlan.attributes?.daily_subsidy[day]
                              )}
                            </small>
                          </div>
                        )
                      )}
                  </div>
                )}
              </strong>

              <button
                className="btn-white w-[141px] mt-[12px]"
                onClick={() => setChangeSubsidy(true)}
              >
                Update
              </button>
            </div>

            <div className="h-[182px] bg-light-gray rounded-lg p-[24px] d-col">
              <div className="d-row justify-between items-center">
                <span className="text-dark-gray font-inter-regular text-[16px]">
                  Teams added
                </span>

                <span className="text-dark-gray font-inter-regular text-[16px]">
                  {mealPlan?.attributes?.location_employees?.length} users in
                  total
                </span>
              </div>
              <strong className="font-inter-semibold text-dark-gray">
                {mealPlan?.attributes?.teams?.length} teams
              </strong>

              <button
                className="btn-white w-[141px] mt-[12px]"
                onClick={() => {
                  navigate("/meal-management/manage-teams");
                }}
              >
                Manage users
              </button>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Contact for delivery
              </span>
              <strong className="font-inter-semibold text-dark-gray">
                {mealPlan?.attributes?.delivery_contact_name}
              </strong>
              <p className="text-dark-gray font-inter-regular text-[16px]">
                {mealPlan?.attributes?.delivery_contact_phone}
              </p>
              <button
                className="btn-white w-[141px] mt-[12px]"
                onClick={() => setChangeContact(true)}
              >
                Change contact
              </button>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Delivery instructions
              </span>
              <strong className="font-inter-semibold text-dark-gray">
                {mealPlan?.attributes?.delivery_instructions}
              </strong>
              <p className="text-dark-gray font-inter-regular text-[16px]">
                Please include any helpful information such as parking
                information, loading dock, or entry instructions
              </p>
              <button
                onClick={() => {
                  setChangeDeliveryInstructions(true);
                }}
                className="btn-white w-[141px] mt-[12px]"
              >
                Update
              </button>
            </div>

            <div className="h-[182px] bg-light-gray w-full rounded-lg p-[24px] d-col">
              <span className="text-dark-gray font-inter-regular text-[16px]">
                Meal Preferences
              </span>
              <strong className="font-inter-semibold text-dark-gray">
                Please keep this updated so we can pick the best restaurants to
                meet your team expectations
              </strong>

              <button
                className="btn-white w-[141px] mt-[12px]"
                onClick={() => navigate("/meal-management/meal-preference")}
              >
                Update
              </button>
            </div>
          </div>

          {changeDeliveryTime && (
            <ChangeDeliveryTime
              visible={changeDeliveryTime}
              setModal={setChangeDeliveryTime}
            />
          )}

          {changeExpectedOrders && (
            <ChangeExpectedOrders
              visible={changeExpectedOrders}
              setModal={setChangeExpectedOrders}
            />
          )}

          {changeSubsidy && (
            <ChangeSubsidy
              visible={changeSubsidy}
              setModal={setChangeSubsidy}
            />
          )}

          {changeContact && (
            <ChangeContact
              visible={changeContact}
              setModal={setChangeContact}
            />
          )}

          {changeDeliveryInstructions && (
            <ChangeDeliveryInstructions
              visible={changeDeliveryInstructions}
              setModal={setChangeDeliveryInstructions}
            />
          )}

          {changeMealPlanName && (
            <ChangeMealPlanName
              visible={changeMealPlanName}
              setModal={setChangeMealPlanName}
              placeholder={mealPlan?.attributes?.name}
            />
          )}

          {changeEnabledDays && (
            <ChangeEnabledDays
              visible={changeEnabledDays}
              setModal={setChangeEnabledDays}
            />
          )}
        </div>
      ) : (
        <>
          <div className="d-col">
            <strong className="text-dark-gray text-[20px] font-inter-medium">
              Uncatering plans
            </strong>
            <small className="font-inter-regular">
              Custom meals ordered by individual employees
            </small>
          </div>

          {isPresent(mealPlans) ? (
            <UncateringPlans
              data={mealPlans}
              managePlanPress={(value, id) => managePlanPress(value, id)}
            />
          ) : (
            <div className="d-col w-full items-center">
              <NotFound
                data={mealPlans}
                message="No active meal plans enabled"
              />
              <button className="btn-disabled-mode  ">
                Learn about Uncatering
              </button>
            </div>
          )}

          <div className="d-col mt-[32px]">
            <strong className="text-dark-gray text-[20px] font-inter-medium">
              Upcoming group orders
            </strong>
            <small className="font-inter-regular">
              One-off custom meals ordered by individuals
            </small>
          </div>

          <UpcomingGroupOrders
            onClick={setViewBag}
            data={groupOrders}
            navigate={navigate}
          />

          <div className="d-col mt-[32px]">
            <strong className="text-dark-gray text-[20px] font-inter-medium">
              Upcoming catering orders
            </strong>
            <small className="font-inter-regular">
              Catering order placed by one person on behalf of many
            </small>
          </div>

          <UpcomingCateringOrders onClick={setViewBag} />

          {viewBagModal && (
            <ViewBag
              // getAvailableTimes={getAvailableTimes}
              // getAvailableDates={getAvailableDates}
              visible={viewBagModal}
              // setApplied={setApplied}

              setModal={setViewBag}
            />
          )}
        </>
      )}
    </div>
  );
}

export default ListView;
