import { useContext, useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import classNames from "classnames";
import { toJS } from "mobx";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";

function ChangeContact({ visible, setModal }) {
  const { mealManagementStore } = useContext(MobXProviderContext);

  const mealPlan = mealManagementStore.mealPlan;
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");

  const onSavePress = () => {
    if (!name || !contact) return;

    mealManagementStore
      .updateMealPlan(mealPlan?.id, {
        meal_series: {
          delivery_contact_name: name,
          delivery_contact_phone: contact,
        },
      })
      .then(() => setModal(false));
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-row justify-between items-center">
                  <h2 className="text-2xl font-inter-bold">
                    Delivery point of contact
                  </h2>
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <span className="font-inter-regular text-dark-gray text-[14px] mt-[24px]">
                  Changes will be applied to you next delivery in line
                </span>

                <label className="inter-regular text-[14px] text-dark-gray mt-[24px]">
                  Name
                </label>
                <input
                  required
                  className="flex w-full bg-background text-dark-gray focus:outline-none rounded-l-md text-md py-[9px] p-[19px]"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <label className="inter-regular text-[14px] text-dark-gray mt-[24px]">
                  Phone number
                </label>

                <Input
                  defaultCountry="CA"
                  className="flex w-full bg-background text-dark-gray focus:outline-none rounded-l-md text-md py-[9px] p-[19px]"
                  value={contact}
                  placeholder="Example: (506) 234-5678"
                  onChange={(val) => setContact(val)}
                />

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={onSavePress}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ChangeContact;
