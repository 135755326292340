import { makeAutoObservable, observable, action } from "mobx";
import { client } from "../store";
import api from "store/api";
import userStore from "./userStore";
import qs from "qs";
import { generateId } from "../utils/helpers/validation";

class PaymentInfoStore {
  locationBillingAccounts = null;
  stripeCreditCards = [];
  defaultPaymentMethodId = null;
  defaultSelectedCard = null;

  constructor() {
    makeAutoObservable(this, {
      locationBillingAccounts: observable,
    });
  }

  async getLocationBillingAccounts(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.billingAccounts()}/${params.location_id}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.locationBillingAccounts = data?.data;

          return data?.data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async updateBillingAccount(locationId, payload) {
    return client()
      .put(
        `${api.billingAccounts()}/${locationId}?location_id=${locationId}`,
        payload
      )
      .then(
        action("fetchSuccess", ({ response }) => {
          this.locationBillingAccounts = response?.data;

          return response?.data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async getLocationCreditCards(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.cards()}/?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.stripeCreditCards = data;
          this.setDefaultPaymentMethod(data?.customer);
          if (data?.customer && data?.payment_methods) {
            this.setDefaulSelectedCard(data?.customer, data?.payment_methods);
          }

          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  setDefaultPaymentMethod(customer) {
    this.defaultPaymentMethod =
      customer?.invoice_settings?.default_payment_method;
  }

  setDefaulSelectedCard(customer, paymentMethods) {
    const defaultPaymentId = customer?.invoice_settings?.default_payment_method;
    this.defaultPaymentMethodId = defaultPaymentId;
    let card = paymentMethods?.data?.find(
      (card) => card?.id == defaultPaymentId
    );

    this.defaultPaymentMethodId = defaultPaymentId;
    this.defaultSelectedCard = {
      expMonth: card?.card?.exp_month,
      expYear: card?.card?.exp_year,
      brand: card?.card?.display_brand,
      last4: card?.card?.last4,
      customer_id: card?.customer,
      id: card?.id,
    };
  }

  resetDefaultPaymentMethodAndCard() {
    this.defaultSelectedCard = null;
    this.defaultPaymentMethodId = null;
  }

  async updateDefaultLocationCreditCards(paymentID, params) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.cards()}/${paymentID}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.getLocationCreditCards({ location_id: params?.location_id });

          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async createLocationCreditCard(params) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.cards()}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async removeLocationCreditCard(params) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.cards()}/${params.card_id}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async requestInvoiceAvailability(locationId, payload) {
    return client()
      .put(
        `${api.locationAddress()}/${locationId}?location_id=${locationId}`,
        payload
      )
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }
}

const paymentInfoStore = new PaymentInfoStore();
export default paymentInfoStore;
