import { useContext, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";

function TeamRemove({ visible, setModal }) {
  const [instructions, setInstructions] = useState("");
  const { mealManagementStore } = useContext(MobXProviderContext);

  const mealPlan = mealManagementStore.mealPlan;

  const onDeletePress = () => {
    let teamToDestroy = mealPlan?.attributes?.team_meal_plans?.find(
      (team) => Number(team?.team_id) === Number(visible)
    );

    mealManagementStore
      .updateMealPlan(mealPlan?.id, {
        meal_series: {
          team_meal_plans_attributes: [
            { id: teamToDestroy?.id, _destroy: true },
          ],
        },
      })
      .then(() => setModal(false));
  };

  return (
    <Transition appear show={visible ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] h-[276px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-row justify-between items-center">
                  <div />
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <h1 className="self-center font-inter-semibold text-[24px] text-dark-gray">
                  Remove team from {mealPlan?.attributes?.name}
                </h1>
                <span className="font-inter-regular text-[16px] text-dark-gray self-center">
                  Are you sure you want to remove this team from this meal?
                </span>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={onDeletePress}
                  >
                    Delete
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default TeamRemove;
