import { toJS } from "mobx";
import { observer } from "mobx-react";

import { useContext, useEffect, useReducer } from "react";

import { MobXProviderContext } from "mobx-react";
import moment from "moment-timezone";
// import DatePicker from "components/GroupOrder/DatePicker";
// import TimePicker from "components/GroupOrder/TimePicker";
import {
  dateFormat,
  dateTimeFormatLong,
  deadlineFormat,
  timeFormat,
} from "utils/helpers/dateTime";
import { handleKeyDown } from "utils/helpers/validation";
import classNames from "classnames";
// import FieldError from "components/FieldError";
import { isPresent } from "utils/helpers/array";

const NewGroupOrder = () => {
  const initialState = {
    groupOrderName: null,
    perPersonBudget: null,
    expectedAttendees: null,
    selectedDate: null,
    selectedTime: null,
    selectedDateUser: null,
    selectedTimeUser: null,
    times: [],
    userTimes: [],
    dateError: false,
    timeError: false,
    userDateError: false,
    userTimeError: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const groupOrderStore = toJS(store?.groupOrderStore);

  let { currentUser, address } = userStore ?? {};
  let { dates, currentGroupOrder, userDates } = groupOrderStore ?? {};

  let { cart } = cartStore ?? {};
  const router = null;

  let {
    selectedDate,
    selectedTime,
    groupOrderName,
    perPersonBudget,
    expectedAttendees,
    selectedDateUser,
    selectedTimeUser,
    times,
    userTimes,
    dateError,
    timeError,
    userDateError,
    userTimeError,
  } = provider;

  useEffect(() => {
    if (selectedDate && dateError)
      dispatch({ field: "dateError", value: false });
    if (selectedTime && timeError)
      dispatch({ field: "timeError", value: false });
    if (selectedDateUser && userDateError)
      dispatch({ field: "userDateError", value: false });
    if (selectedTimeUser && userTimeError)
      dispatch({ field: "userTimeError", value: false });
  }, [selectedDate, selectedDate, selectedDateUser, selectedTimeUser]);

  useEffect(() => {
    if (!store.userStore?.isHydrated || !router?.isReady) return;
    if (currentGroupOrder?.attributes) {
      setValues();
    }
  }, [store.userStore?.isHydrated, router?.isReady]);

  useEffect(() => {
    if (selectedDate && selectedDateUser) {
      getGroupOrderTimes(selectedDate, true);
      getGroupOrderTimes(selectedDateUser, false);
    }
  }, [selectedDate, selectedDateUser]);

  useEffect(() => {
    if (selectedDate && selectedDateUser) {
      getGroupOrderTimes(selectedDateUser, false);
    }
  }, [selectedTime]);

  const setValues = () => {
    const attendees = currentGroupOrder?.attributes?.expected_attendees;
    const budget = currentGroupOrder?.attributes?.per_person_budget;

    dispatch({
      field: "groupOrderName",
      value: currentGroupOrder?.attributes?.title,
    });

    dispatch({
      field: "perPersonBudget",
      value: budget,
    });

    dispatch({
      field: "expectedAttendees",
      value: attendees,
    });

    const adminDate = new Date(currentGroupOrder?.attributes?.admin_deadline);
    const isFutureDate = moment(adminDate).isSameOrAfter(moment());
    const adminTime = moment(
      currentGroupOrder?.attributes?.admin_deadline,
      deadlineFormat
    ).format(timeFormat);
    const adminDeadline = `${moment(adminDate).format(
      dateFormat
    )} ${adminTime}`;

    if (isFutureDate) {
      dispatch({
        field: "selectedDate",
        value: adminDate,
      });

      getGroupOrderTimes(adminDate, true, {
        expectedAttendees: attendees,
        perPersonBudget: budget,
      });

      getDatesForGroupOrder(attendees, budget, true);

      dispatch({
        field: "selectedTime",
        value: adminTime,
      });

      const userDate = new Date(
        currentGroupOrder?.attributes?.attendee_deadline
      );

      dispatch({
        field: "selectedDateUser",
        value: userDate,
      });

      dispatch({
        field: "selectedTimeUser",
        value: moment(
          currentGroupOrder?.attributes?.attendee_deadline,
          deadlineFormat
        ).format(timeFormat),
      });

      getDatesForGroupOrder(attendees, budget, false, adminDeadline);

      getGroupOrderTimes(userDate, false, {
        expectedAttendees: attendees,
        perPersonBudget: budget,
        adminDate,
        adminTime,
      });
    }
  };

  const checkAndNavigate = (response) => {
    if (response?.data?.attributes) {
      if (currentUser?.attributes?.access_token)
        router.push(
          `/group-order/confirmation/${response?.data?.attributes?.slug}`
        );
      else router.push(`/group-order/sign-in`);
    }
  };

  const submit = (e) => {
    e.preventDefault();

    if (!selectedDate) {
      dispatch({ field: "dateError", value: true });

      return;
    }

    if (!selectedTime) {
      dispatch({ field: "timeError", value: true });
      return;
    }

    if (!selectedDateUser) {
      dispatch({ field: "userDateError", value: true });

      return;
    }

    if (!selectedTimeUser) {
      dispatch({ field: "userTimeError", value: true });

      return;
    }

    let payload = {
      group_order: {
        title: groupOrderName,
        per_person_budget: perPersonBudget,
        expected_attendees: expectedAttendees,
        admin_deadline: `${moment(selectedDate).format(
          dateFormat
        )} ${selectedTime}`,
        attendee_deadline: `${moment(selectedDateUser).format(
          dateFormat
        )} ${selectedTimeUser}`,
        address: address,
        order_date: cart?.cartDate,
        order_time: cart?.cartTime,
        menu_id: router?.query?.menu_id,
      },
    };

    if (router?.query?.utm_source && router?.query?.utm_source == "odc") {
      payload.group_order.source_of_business_before_checkout = "restaurant";
    } else {
      payload.group_order.source_of_business_before_checkout = "hungerhub";
    }

    if (currentUser?.id) payload.group_order.user_id = currentUser?.id;

    store?.userStore?.setLoader(true);

    if (currentGroupOrder?.attributes?.slug) {
      store?.groupOrderStore
        ?.updateGroupOrder(currentGroupOrder?.attributes?.slug, payload)
        .then((response) => {
          store?.userStore?.setLoader(false);

          checkAndNavigate(response);
        });
    } else
      store?.groupOrderStore.createGroupOrder(payload).then((response) => {
        store?.userStore?.setLoader(false);

        checkAndNavigate(response);
      });
  };

  const getDatesForGroupOrder = (attendees, budget, isAdmin, adminDeadline) => {
    const _expectedAttendees = attendees || expectedAttendees;
    const _perPersonBudget = budget || perPersonBudget;

    if (_expectedAttendees && _perPersonBudget && router?.query?.menu_id) {
      let payload = {
        menu_id: router?.query?.menu_id,
        attendee: _expectedAttendees,
        budget_per_attendee: _perPersonBudget,
        order_date: moment(cart?.cartDate).format(dateFormat),
        delivery_time: cart?.cartTime,
        admin: isAdmin,
      };

      if (!isAdmin) payload.admin_deadline = adminDeadline;

      store?.groupOrderStore.getDatesForGroupOrder(payload);
    }
  };

  const getGroupOrderTimes = (date, isAdmin, data) => {
    let payload = {
      menu_id: router?.query?.menu_id,
      attendee: Math.abs(data?.expectedAttendees ?? expectedAttendees),
      budget_per_attendee: data?.perPersonBudget ?? perPersonBudget,
      selected_date: moment(cart?.cartDate).format(dateFormat),
      current_date_time: moment().format(dateTimeFormatLong),
      delivery_time: cart?.cartTime,
      admin: isAdmin ? true : false,
    };

    if (isAdmin) payload.admin_date = moment(date).format(dateFormat);

    if (!isAdmin) {
      payload.admin_deadline = `${moment(
        selectedDate ?? data?.adminDate
      ).format(dateFormat)} ${selectedTime ?? data?.adminTime}`;
      payload.attendee_date = moment(date).format(dateFormat);
    }

    store?.groupOrderStore.getTimesForGroupOrder(payload).then((response) => {
      dispatch({
        field: isAdmin ? "times" : "userTimes",
        value: response?.timeslots,
      });

      const isPastAdminDeadline = moment(
        `${moment(selectedDate).format(dateFormat)} ${selectedTime}`,
        "YYYY-MM-DD h:mm a"
      ).isBefore(moment());

      const isPastUserDeadline = moment(
        `${moment(selectedDateUser).format(dateFormat)} ${selectedTimeUser}`,
        "YYYY-MM-DD h:mm a"
      ).isBefore(moment());

      if (
        !isAdmin &&
        selectedTimeUser &&
        (!isPresent(response?.timeslots) || isPastUserDeadline)
      ) {
        dispatch({ field: "selectedTimeUser", value: null });
      }

      if (
        isAdmin &&
        selectedTime &&
        (!isPresent(response?.timeslots) || isPastAdminDeadline)
      ) {
        dispatch({ field: "selectedTime", value: null });
      }
    });
  };

  const fieldsValidated = () => {
    return (
      groupOrderName !== null &&
      perPersonBudget !== null &&
      expectedAttendees !== null &&
      selectedDate !== null &&
      selectedTime !== null &&
      selectedDateUser !== null &&
      selectedTimeUser !== null
    );
  };

  return (
    <>
      <div
        className="flex justify-center bg-white min-h-[calc(100vh-200px)] mx-auto p-5 sm:p-10 md:p-8 sm:mt-[30px] md:mt-0"
        style={{ maxWidth: "1440px" }}
      >
        <div className="flex flex-col mt-[2%] w-full pb-40 sm:pb-0">
          <div className="flex flex-col text-center">
            <span className="text-md font-inter-semibold text-hh-accent-light">
              Step 1/3
            </span>

            <span className="text-[24px] font-inter-semibold text-black-light mt-1.5">
              Add Group Order Details
            </span>
          </div>

          <div className="bg-light-gray rounded-md w-full d-col p-[12px] mt-[32px]">
            <small className="font-inter-regular text-[12px] text-heading mb-[12px]">
              Restaurant: [MENU name]
            </small>
            <small className="font-inter-regular text-[12px] text-heading mb-[12px]">
              Delivery Address: 99 John street,Toronto, ON
            </small>
            <small className="font-inter-regular text-[12px] text-heading mb-[12px]">
              Delivery Day: Tuesday, October 10th, 2023
            </small>
            <small>Delivery Time: 6:00pm</small>
          </div>

          <form onSubmit={submit} autoComplete="off" className="w-[593px]">
            <div className="flex flex-col mt-8">
              <span className="text-md2 font-inter-semibold">
                Group order name*
              </span>

              <input
                required
                name={"groupOrderNameField"}
                className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-md w-full bg-background pl-4 mt-3"
                placeholder="ex. Friday lunch & Learn"
                value={groupOrderName}
                onChange={(e) =>
                  dispatch({ field: "groupOrderName", value: e.target.value })
                }
              />
            </div>

            <div className="flex flex-col mt-6">
              <span className="text-md2 font-inter-semibold">
                Budget and expected number of invitees*
              </span>

              <div className="mt-3 flex flex-col sm:grid sm:grid-cols-2 sm:gap-3">
                <div className="flex mb-3 sm:mb-0 items-center text-md rounded-xl bg-background w-full py-2 px-3 h-12">
                  <span className="mt-[2px]">$</span>

                  <input
                    required
                    name={"perPersonBudgetField"}
                    className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-md w-full bg-background"
                    placeholder="Per person budget"
                    value={perPersonBudget}
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    onKeyDown={handleKeyDown}
                    type="number"
                    onChange={(e) =>
                      dispatch({
                        field: "perPersonBudget",
                        value: e.target.value,
                      })
                    }
                  />
                </div>

                <input
                  required
                  name={"expectedAttendeesField"}
                  className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-sm w-full !text-md bg-background pl-4"
                  placeholder="Expected attendees"
                  value={expectedAttendees}
                  onWheelCapture={(e) => {
                    e.target.blur();
                  }}
                  onKeyDown={handleKeyDown}
                  max={300}
                  type="number"
                  onBlur={() => getDatesForGroupOrder(null, null, true)}
                  onChange={(e) =>
                    dispatch({
                      field: "expectedAttendees",
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="flex flex-col mt-6">
              <span className="text-md2 font-inter-semibold">
                Set a deadline for invitees to add their meals*
              </span>

              <span className="text-sm mt-[6px]">
                You will have an additional hour past this deadline to edit and
                finalize your group order
              </span>

              <div className="mt-3 flex flex-col sm:grid sm:grid-cols-2 sm:gap-3">
                <div className="w-full">
                  <span className="text-md">Date</span>

                  {/* <DatePicker
                    dateOptions={userDates?.filter((date) =>
                      moment(date).isSameOrBefore(moment(selectedDate))
                    )}
                    selectedDate={selectedDateUser}
                    setSelectedDate={(date) => {
                      dispatch({ field: "selectedDateUser", value: date });

                      getGroupOrderTimes(date);
                    }}
                  /> */}

                  {/* {userDateError && <FieldError />} */}
                </div>

                <div>
                  <div className="mt-3 sm:mt-0">
                    <span className="text-md">Time</span>
                  </div>

                  <div className="mt-1">
                    {/* <TimePicker
                      times={userTimes}
                      selectedTime={selectedTimeUser}
                      selectedDate={selectedDateUser}
                      setSelectedTime={(time) =>
                        dispatch({ field: "selectedTimeUser", value: time })
                      }
                    />

                    {userTimeError && <FieldError />} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="fixed sm:static p-5 sm:p-0 sm:flex bg-white bottom-0 right-0 left-0 mt-6 w-full">
              <button
                type="submit"
                className={classNames({
                  "rounded-md p-6 w-full text-white btn-style text-md font-inter-medium": true,
                  "bg-secondary-text ": !fieldsValidated(),
                  "bg-hh-accent-dark hover:bg-hh-accent-light":
                    fieldsValidated(),
                })}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NewGroupOrder.groupOrderLayout = true;

export default observer(NewGroupOrder);
