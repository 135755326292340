import React from "react";
import { SearchBar } from "components";
import ConfirmationModal from "components/ConfirmationModal";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { ChangeSubsidy, AssignTeam, AddUsersToMeal } from "alerts";
import { useManagePlan } from "contexts/ManagePlanContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

function ManageUsers() {
  const {
    changeSubsidy,
    setChangeSubsidy,
    removeUser,
    setRemoveUser,
    assignTeam,
    setAssignTeam,
    addUsersToMeal,
    setAddUsersToMeal,
  } = useManagePlan();

  const navigate = useNavigate();

  return (
    <div className="d-col p-6 w-full relative">
      <button
        className="flex items-center cursor-pointer mb-[24px]"
        onClick={() => {
          navigate("/home");
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} style={{ width: 14, height: 14 }} />
        <span className="text-md text-left font-inter-medium text-black-light ml-2">
          Back
        </span>
      </button>

      <span className="font-inter-medium text-dark-gray text-[14px] mt-[18px] self-center">
        Delivery Address: 1448 Lawrence Avenue East
      </span>

      <h2 className="font-inter-semibold text-dark-gray text-[24px] mt-[5px] mb-[45px] self-center">
        Changes to hungerhub staff happy hour
      </h2>

      <div className="mb-4">
        <div className="d-row mt-[24px] items-center justify-between">
          <strong className="text-dark-gray text-[24px] font-inter-medium">
            Teams in hungerhub staff happy hour
          </strong>
          <button
            className="btn-blue-accent"
            onClick={() => {
              setAssignTeam(true);
            }}
          >
            Assign teams
          </button>
        </div>

        <p className="font-inter-regular max-w-[543px] text-[14px] text-dark-gray">
          Users added or removed from the following teams will be automatically
          added or removed from this meal series
        </p>

        <div className="flex items-center space-x-2 my-[24px]">
          <div className="bg-light-gray px-4 py-[12px] rounded-full flex items-center">
            <span className="text-sm text-gray-700">Team 1</span>
            <button className="ml-2 text-gray-700 text-xs">✕</button>
          </div>
          <div className="bg-light-gray px-4 py-[12px] rounded-full flex items-center">
            <span className="text-sm text-gray-700">Team 2</span>
            <button className="ml-2 text-gray-700 text-xs">✕</button>
          </div>
        </div>
      </div>

      <strong className="text-dark-gray text-[24px] font-inter-medium">
        Users in this meal series
      </strong>

      <span className="font-inter-regular max-w-[543px] text-[14px] text-dark-gray">
        Users number: 181
      </span>

      <div className="d-row items-center justify-between my-[24px]">
        <div className="d-row items-center">
          <SearchBar />
          <small className="fonte-inter-regular tex-sm mx-[10px]">
            Filter by:
          </small>

          <Select
            styles={customStylesSubsidy}
            aria-labelledby="sort-by"
            aria-label="sort-by"
            placeholder="Subsidy type"
            closeMenuOnSelect
            //   value={sortBy}
            //   onChange={onChange}
            //   options={sortByOptions}
            className="w-[195px]"
          />
        </div>

        <button
          className="btn-blue-accent"
          onClick={() => {
            setAddUsersToMeal(true);
          }}
        >
          Add users to this meal
        </button>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="bg-light-gray">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  ></th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  >
                    First name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Last name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Team
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Subsidy
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Subsidy Value
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {people.map((person) => (
                  <tr key={person.email} className="even:bg-light-gray">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox text-blue-600 h-4 w-4"
                        />
                      </label>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                      Greek 2 Go
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      12
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      Sent to restaurant
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      $72.27
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      14
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      $12 Weekly
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="absolute bottom-0 right-0 d-row items-center justify-center left-0 ">
        <div className="btn-blue-accent rounded-3xl px-[32px] py-[16px] d-row items-center hover:bg-catering h-[48px] ">
          <small className="font-inter-medium text-[16px]">
            1 user selected
          </small>
          <button
            className="font-inter-medium text-white px-[12px] underline text-[16px] py-0"
            onClick={() => setChangeSubsidy(true)}
          >
            Update subsidy
          </button>
          <button
            className="font-inter-medium text-white underline text-[16px] py-0"
            onClick={() => setRemoveUser(true)}
          >
            Remove user from this meal
          </button>
        </div>
      </div>

      {changeSubsidy && (
        <ChangeSubsidy visible={changeSubsidy} setModal={setChangeSubsidy} />
      )}

      {removeUser && (
        <ConfirmationModal
          visible={removeUser}
          setModal={setRemoveUser}
          title="Remove users?"
          body="Are you sure you want to delete these users"
          rightButtonText="Delete"
          //   onClickRightButton={onClickRemove}
        />
      )}

      {assignTeam && (
        <AssignTeam visible={assignTeam} setModal={setAssignTeam} />
      )}

      {addUsersToMeal && (
        <AddUsersToMeal visible={addUsersToMeal} setModal={setAddUsersToMeal} />
      )}
    </div>
  );
}

export default ManageUsers;
