import React, { useState, useEffect, useContext } from "react";
import MyCrousal from "components/crousal";
import Input from "components/input";
import ActiveStepIndicator from "components/shared/activeStepIndicator";
import { observer } from "mobx-react";
import { MobXProviderContext } from "mobx-react";

const Step4 = ({ disableButton }) => {
  const { onboardingStore } = useContext(MobXProviderContext);

  const dietaryPreferences = onboardingStore.dietaryPreferences || [];

  const cuisineTypes = onboardingStore.cuisineTypes || [];

  const [selectedCuisineTypes, setSelectedCuisineTypes] = useState([]);
  const [dietPreference, setDietPreference] = useState([]);
  const [additionalPreference, setAdditionalPreference] = useState("");
  const [allCuisineTypes, setAllCuisineTypes] = useState([]);

  useEffect(() => {
    disableButton(false);
  }, []);

  useEffect(() => {
    onboardingStore.handlePreferencesData(
      allCuisineTypes,
      dietPreference,
      additionalPreference
    );
  }, [selectedCuisineTypes, additionalPreference, dietPreference]);

  useEffect(() => {
    handleAllCuisineTypes();
  }, [selectedCuisineTypes]);

  const handleAllCuisineTypes = () => {
    const allCuisineTypes = cuisineTypes?.map((type) => {
      const foundCuisine = selectedCuisineTypes.find(
        (selectedType) => selectedType.name === type.name
      );

      if (foundCuisine) {
        const newObj = {};
        newObj[type.name] = foundCuisine.review;
        return newObj;
      } else {
        return {
          [type.name]: "neutral",
        };
      }
    });

    setAllCuisineTypes(allCuisineTypes);
  };

  const handleDietaryPreferenceClick = (value) => {
    if (dietPreference.includes(value)) {
      setDietPreference((prevDays) => prevDays.filter((day) => day !== value));
    } else {
      setDietPreference((prevDays) => [...prevDays, value]);
    }
  };

  const handleCuisineTypeClick = (name, review) => {
    setSelectedCuisineTypes((prevCuisines) => {
      const existingCuisineIndex = prevCuisines.findIndex(
        (cuisine) => cuisine.name === name
      );

      if (existingCuisineIndex !== -1) {
        if (prevCuisines[existingCuisineIndex].review === review) {
          return prevCuisines.filter((cuisine) => cuisine.name !== name);
        } else {
          return prevCuisines?.map((cuisine) =>
            cuisine.name === name ? { name, review } : cuisine
          );
        }
      } else {
        return [...prevCuisines, { name, review }];
      }
    });
  };

  const handleAdditionalPreference = (value) => {
    setAdditionalPreference(value);
  };

  return (
    <div className="flex flex-col mt-[268px] pt-[50px]">
      <ActiveStepIndicator step={4} />
      <div className="flex-col flex gap-6">
        <div className="flex inline-flex">
          <div className="expected-bag-text xs:w-full-235 font-inter-semibold">
            How does your team feel about the following cuisines?
          </div>
        </div>

        <div className="screen-lg:hidden screen-md:hidden screen-sm:flex mb-0.5 w-full xs:w-full-215">
          <div className="screen-lg:inline-flex screen-md:inline-flex">
            {cuisineTypes?.map((cuisine, index) => (
              <div
                key={index}
                className={`screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                  selectedCuisineTypes.some(
                    (cuisineType) => cuisineType.name === cuisine.name
                  )
                    ? "bg-indigo-500 text-white "
                    : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                } rounded-3xl justify-start screen-lg:justify-center screen-md:justify-center gap-2 flex  `}
                onClick={() => {
                  handleCuisineTypeClick(cuisine.name, "like");
                }}
              >
                {cuisine?.name}
              </div>
            ))}
          </div>
        </div>

        <div className="hidden screen-lg:block screen-md:block ">
          <MyCrousal
            dietaryPreferences={cuisineTypes}
            handleReviewButton={handleCuisineTypeClick}
            selectedCuisines={selectedCuisineTypes}
          />
        </div>

        <div className="expected-bag-text xs:w-full-235 font-inter-semibold">
          Dietary needs that we need to be aware of
        </div>

        <div className="mb-0.5 w-full xs:w-full-215">
          <div className="screen-lg:inline-flex screen-md:inline-flex">
            {dietaryPreferences?.map((value, index) => (
              <div
                key={index}
                className={`screen-lg:mr-3 screen-md:mr-1 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px] ${
                  dietPreference.some((diet) => diet === value)
                    ? "bg-indigo-500 text-white "
                    : "bg-slate-50 hover:bg-indigo-500 hover:text-white "
                } rounded-3xl justify-start screen-lg:justify-center screen-md:justify-center gap-2 flex`}
                onClick={() => handleDietaryPreferenceClick(value)}
              >
                {value?.name}
              </div>
            ))}
          </div>
        </div>

        <div className="w-full mb-6">
          <Input
            text="Any additional preferences or dietary restrictions?"
            placeholder={"ex. Halal Food"}
            otherMealName={handleAdditionalPreference}
            mealName={additionalPreference}
            isCompulsary={false}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(Step4);
