import React, { useState, Fragment, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Dialog, Transition } from "@headlessui/react";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";

const CheckoutForm = ({ setModal, rightClick }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const stores = useContext(MobXProviderContext);
  const userStore = toJS(stores?.userStore);
  let { locationID } = userStore;

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
      focus: {
        color: "#303238",
        backgroundColor: "#000",
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      const cardId = paymentMethod?.id;

      stores.paymentInfoStore
        .createLocationCreditCard({ location_id: locationID, card_id: cardId })
        .then((response) => {
          stores.userStore?.setLoader(false);
        })
        .catch(() => stores.userStore.setLoader(false));
    }

    rightClick(false);
    return;
  };

  return (
    <form
      className="d-col items-center w-full h-full gap-6 justify-between items-center"
      onSubmit={handleSubmit}
    >
      <div className="w-full gap-4 flex-col flex">
        <div className="d-col w-full gap-4">
          <div className="text-slate-700 text-lg font-inter-medium leading-snug">
            Card Number
          </div>

          <div
            style={{
              padding: "15px",
            }}
            className="w-full bg-slate-50 rounded-xl border border-slate-50"
          >
            <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>

        <div className="lg:d-row md:d-col w-full gap-[18px]">
          <div className="d-col w-full gap-4">
            <div className="text-slate-700 text-lg font-inter-medium leading-snug">
              Expiration Date
            </div>

            <div
              style={{
                padding: "15px",
              }}
              className="w-full bg-slate-50 rounded-xl border border-slate-50"
            >
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>

          <div className="d-col w-full gap-4">
            <div className="text-slate-700 text-lg font-inter-medium leading-snug">
              CVV{" "}
            </div>

            <div
              style={{
                padding: "15px",
              }}
              className="w-full bg-slate-50 rounded-xl border border-slate-50"
            >
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="hidden md:flex w-[764px] h-[0px] border border-zinc-200 mb-2" /> */}

      <div className="mt-2 inline-flex gap-[19px]">
        <button
          className="btn-light btn-style lg:w-[98px] md:w-[197.50px]"
          onClick={() => setModal(false)}
        >
          Cancel
        </button>

        <button
          className="btn-purple btn-style lg:w-[98px] md:w-[197.50px]"
          type="submit"
          disabled={!stripe}
        >
          Save
        </button>
      </div>

      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);

const AddCard = ({ visible, setModal, leftClick, rightClick }) => (
  <Transition appear show={visible} as={Fragment}>
    <Dialog
      as="div"
      className="relative z-[90]"
      onClose={() => setModal(false)}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="lg:w-[664px] md:w-[620px] w-[320px] lg:h-[410px] md:h-[681px] d-col rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
              <div className="d-row justify-between items-center">
                <h2 className="text-2xl font-inter-bold my-5">Add New Card</h2>
                <button
                  onClick={() => setModal(false)}
                  className="text-dark-gray "
                  aria-label="Close"
                >
                  <span className="text-xl">&times;</span>
                </button>
              </div>

              <Elements stripe={stripePromise}>
                <CheckoutForm
                  setModal={setModal}
                  leftClick={leftClick}
                  rightClick={rightClick}
                />
              </Elements>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default AddCard;
