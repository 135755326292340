import { useState, useEffect } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";

const MyCalendar = ({
  showButtons,
  isMultiple,
  handleDatePicker,
  selectedValue,
  handleMultipleDates,
  value,
  disableMonthYearPicker,
}) => {
  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [date, setDate] = useState();
  const [initialState, setInitialState] = useState();

  useEffect(() => {
    if (isMultiple) {
      const currentDate = new DateObject();
      const currentMonth = new Date().getMonth();

      setInitialState(currentDate.setMonth(currentMonth + value));
    } else {
      setDate(new DateObject(selectedValue));
    }
  }, []);

  const handleDateChange = (newDate) => {
    isMultiple ? handleMultipleDates(newDate) : handleDatePicker(newDate);
  };

  return (
    <Calendar
      highlightToday={false}
      value={isMultiple ? initialState : date}
      onChange={handleDateChange}
      buttons={showButtons}
      weekDays={weekDays}
      months={months}
      disableYearPicker={disableMonthYearPicker}
      disableMonthPicker={disableMonthYearPicker}
      multiple={isMultiple}
      monthYearSeparator={" "}
      minDate={new Date()}
      weekPicker={false}
    />
  );
};

export default MyCalendar;
