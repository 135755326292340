import React, { useState } from "react";
import { refreshIcon } from "assets/img";
import { ViewBag } from "alerts";
import { ChevronLeftIcon } from "assets/img";
import { useNavigate } from "react-router-dom";
import { IS_LARGE_SCREEN } from "utils/constants/common";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

function OrdersByRestaurant() {
  const navigate = useNavigate();
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Restaurant
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Users Ordered
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Food Total
                </th>

                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {people.map((person) => (
                <tr
                  key={person.email}
                  className="even:bg-light-gray"
                  onClick={
                    IS_LARGE_SCREEN
                      ? null
                      : () => navigate("/home/order-detail/1")
                  }
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-bold text-dark-gray sm:pl-3">
                    Greek 2 Go
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    12
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    Sent to restaurant
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    $72.27
                  </td>
                  <td className="hidden lg:flex relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <button
                      onClick={() => navigate("/home/order-detail/1")}
                      className="btn-outline"
                    >
                      View order
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function OrdersByUsers({ onClick }) {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-bold text-dark-gray sm:pl-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Restaurant
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Team
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Cutlery
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-inter-bold text-dark-gray"
                >
                  Bag#
                </th>

                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                ></th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {people.map((person) => (
                <tr
                  key={person.email}
                  className="even:bg-light-gray"
                  onClick={IS_LARGE_SCREEN ? null : () => onClick(true)}
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-bold text-dark-gray sm:pl-3">
                    Greek 2 Go
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    12
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    Sent to restaurant
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    $72.27
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-bold text-dark-gray">
                    14
                  </td>
                  <td className="hidden lg:flex relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <button
                      onClick={() => onClick(true)}
                      className="btn-outline"
                    >
                      View bag
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function OrderDetail() {
  const [viewBagModal, setViewBag] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="rounded-lg px-[28px] flex flex-col min-w-full">
      <button
        className="font-inter-medium d-row items-center mb-[22px] text-sm cursor-pointer hover:text-gray-500"
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={ChevronLeftIcon} className="h-[20px] w-[20px]" />
        Back to home page
      </button>

      <div className="lg:d-row justify-between items-end">
        <div className="d-col">
          <strong className="text-dark-gray text-[20px] font-inter-medium">
            [Day//DD/Month]
          </strong>

          <strong className="text-heading text-[32px] font-inter-regular">
            Testing Meal Name
          </strong>
        </div>

        <div className="d-row md:mt-[32px] md:justify-between">
          <button className="btn-outline h-[48px] mr-[12px] rounded-lg font-inter-semibold border-1">
            Manage Invitation links
          </button>
          <button className="btn-blue-accent rounded-lg font-inter-semibold">
            Request changes to this meal
          </button>
        </div>
      </div>

      <div className="d-row justify-between mt-[32px]">
        <strong className="text-dark-gray text-[20px] font-inter-medium">
          Orders by Restaurant
        </strong>

        <button className="h-12 flex flex-row bg-light-gray items-center px-4 py-[4px] rounded-lg ml-6">
          <img className="h-4 w-4 mr-[8px]" src={refreshIcon} />
          <small className="text-dark-gray font-inter-regular text-sm">
            Refresh
          </small>
        </button>
      </div>

      <OrdersByRestaurant />

      <div className="d-row justify-between mt-[32px]">
        <div className="d-col">
          <strong className="text-dark-gray text-[20px] font-inter-medium">
            Users status
          </strong>

          <small className="font-inter-regular">
            <span className="hidden lg:flex">
              <p>Users who ordered: 181 Users with no orders: 56</p>
            </span>

            <span className="hidden lg:hidden md:flex flex-col">
              <p>Users who ordered: 181</p>
              <p>Users with no orders: 56</p>
            </span>
          </small>
        </div>

        <div className="d-row md:items-center">
          <button className="btn-blue-accent rounded-lg font-inter-semibold">
            Place order
          </button>
          <button className="md:h-12 flex flex-row bg-light-gray items-center px-4 py-[4px] rounded-lg ml-6">
            <img className="h-4 w-4 mr-[8px]" src={refreshIcon} />
            <small className="text-dark-gray font-inter-regular text-sm">
              Refresh
            </small>
          </button>
        </div>
      </div>

      <OrdersByUsers onClick={setViewBag} />

      {viewBagModal && (
        <ViewBag
          // getAvailableTimes={getAvailableTimes}
          // getAvailableDates={getAvailableDates}
          visible={viewBagModal}
          // setApplied={setApplied}

          setModal={setViewBag}
        />
      )}
    </div>
  );
}

export default OrderDetail;
