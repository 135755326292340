import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../src/assets/stylesheets/global.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { MobXProviderContext } from "mobx-react";
import onboardingStore from "mobXstore/onboardingStore";
import mealManagementStore from "mobXstore/mealManagementStore";
import userStore from "mobXstore/userStore";
import deliveryInfoStore from "mobXstore/deliveryInfoStore";
import paymentInfoStore from "mobXstore/paymentInfoStore";

const stores = {
  onboardingStore,
  mealManagementStore,
  userStore,
  deliveryInfoStore,
  paymentInfoStore,
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MobXProviderContext.Provider value={stores}>
      {/* <BrowserRouter> */}
      <App />
      {/* </BrowserRouter> */}
    </MobXProviderContext.Provider>
  </React.StrictMode>
);
