import { useState, useContext, useCallback } from "react";
import { generateCalendarDays } from "utils/helpers/calendar";
import { MonthPicker, SearchBar } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableList, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { ListView } from "./index";
import classNames from "classnames";
import { AddMeal } from "alerts";
import ConfirmationModal from "components/ConfirmationModal";
import { useManagePlan } from "contexts/ManagePlanContext";
import { MobXProviderContext } from "mobx-react";
import { isPresent } from "utils/helpers/array";
import dayjs from "dayjs";
import dateTime from "utils/helpers/dateTime";

export default function MealManagement() {
  const [calendarView, setCalendarView] = useState("list");
  const [addMeal, setAddMeal] = useState(false);
  const { managePlan } = useManagePlan();
  const [uncateringMealPending, setUncateringMealPending] = useState(false);
  const { mealManagementStore } = useContext(MobXProviderContext);
  const [calendarDays, setCalendarDays] = useState(
    generateCalendarDays(dayjs().year(), dayjs().month())
  ); // Month is 0-indexed (0 for January, 1 for February, etc.)

  const organisedMeals = mealManagementStore.organisedMeals;
  const meals = mealManagementStore.meals;

  const findMealsByDate = (date) => {
    return organisedMeals?.find((meal) => {
      return meal?.date === date;
    });
  };

  const onMonthChange = useCallback((month) => {
    setCalendarDays(generateCalendarDays(month.year(), month.month()));
    const startDate = month.startOf("month").format("YYYY-MM-DD");
    const endDate = month.endOf("month").format("YYYY-MM-DD");
    mealManagementStore.getMeals({ start_date: startDate, end_date: endDate });
  }, []);

  return (
    <div className="w-full px-[32px] pb-32">
      <div className="lg:flex lg:h-full lg:flex-col">
        {!managePlan && (
          <header className="d-col items-start justify-between mt-[48px] mb-[34px] lg:flex-none">
            <div className="lg:d-row md:d-row sm:d-col justify-between w-full items-center gap-4">
              {calendarView === "calendar" ? (
                <MonthPicker onMonthChange={(month) => onMonthChange(month)} />
              ) : (
                <h1 className="font-inter-semibold text-[24px] text-heading lg:mb-0 md:mb-0 mb-4">
                  Your meals
                </h1>
              )}

              <div className="d-row">
                <button
                  className={classNames({
                    "lg:w-32 md:w-32 w-full d-row items-center rounded-xl text-catering hover:text-white font-inter-bold mr-[10px]": true,
                    "btn-disabled-mode": calendarView !== "list",
                    "btn-blue-accent text-white": calendarView == "list",
                  })}
                  onClick={() => {
                    setCalendarView("list");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTableList}
                    className="icon h-4 w-4 lg:mr-[4px] md:mr-[4px] mr-[12px]"
                  />
                  List view
                </button>

                <button
                  className={classNames({
                    "lg:w-40 md:w-40 w-full d-row items-center rounded-xl text-catering hover:text-white font-inter-bold": true,
                    "btn-disabled-mode": calendarView !== "calendar",
                    "btn-blue-accent text-white": calendarView == "calendar",
                  })}
                  onClick={() => {
                    setCalendarView("calendar");
                    mealManagementStore.getMeals({
                      start_date: dayjs()
                        ?.startOf("month")
                        .format("YYYY-MM-DD"),
                      end_date: dayjs()?.endOf("month").format("YYYY-MM-DD"),
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className="icon h-4 w-4 lg:mr-[4px] md:mr-[4px] mr-[12px]"
                  />
                  Calendar view
                </button>
              </div>
            </div>

            {calendarView === "calendar" ? (
              <div
                className={classNames({
                  "rounded-lg bg-light-gray d-row px-[16px] py-[13px] w-full mt-[23px]": true,
                })}
              >
                <div className="d-row items-center">
                  <div className="bg-uncatering h-3 w-3 rounded-lg" />
                  <small className="text-sm font-inter-medium text-dark-gray ml-[12px]">
                    Uncatering Orders
                  </small>
                </div>
                <div className="d-row items-center mx-[21px]">
                  <div className="bg-group h-3 w-3 rounded-lg" />
                  <small className="text-sm font-inter-medium text-dark-gray ml-[12px]">
                    Group Orders
                  </small>
                </div>
                <div className="d-row items-center">
                  <div className="bg-catering h-3 w-3 rounded-lg" />
                  <small className="text-sm font-inter-medium text-dark-gray ml-[12px]">
                    Catering
                  </small>
                </div>
              </div>
            ) : (
              <div className="d-row items-center justify-between w-full mt-[32px]">
                <SearchBar width="lg:w-1/2 md:w-1/2 w-2/3" />

                <button
                  className={classNames({
                    "d-row items-center rounded-xl text-catering hover:text-white font-inter-bold": true,
                    "btn-blue-accent text-white": true,
                  })}
                  onClick={() => setAddMeal(true)}
                >
                  Add meal
                </button>
              </div>
            )}
          </header>
        )}

        {calendarView === "calendar" ? (
          <div className="lg:flex lg:flex-auto lg:flex-col">
            <div className="grid grid-cols-7 gap-px bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
              <div className="bg-white py-2">
                <span className="font-inter-bold text-secondary text-[20px] ">
                  Mo{" "}
                </span>
              </div>
              <div className="bg-white py-2">
                <span className="font-inter-bold text-secondary text-[20px] ">
                  Tu
                </span>
              </div>
              <div className="bg-white py-2">
                <span className="font-inter-bold text-secondary text-[20px] ">
                  We
                </span>
              </div>
              <div className="bg-white py-2">
                <span className="font-inter-bold text-secondary text-[20px] ">
                  Th
                </span>
              </div>
              <div className="bg-white py-2">
                <span className="font-inter-bold text-secondary text-[20px] ">
                  Fr
                </span>
              </div>
              <div className="bg-white py-2">
                <span className="font-inter-bold text-secondary text-[20px] ">
                  Sa
                </span>
              </div>
              <div className="bg-white py-2">
                <span className="font-inter-bold text-secondary text-[20px] ">
                  Su
                </span>
              </div>
            </div>

            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
              <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                {calendarDays.map((day) => (
                  <div
                    key={day.date}
                    className={classNames(
                      day.isCurrentMonth
                        ? "bg-white"
                        : "bg-white text-gray-200",
                      "relative px-3 py-[27px]"
                    )}
                  >
                    <div className="d-col items-end">
                      <time
                        dateTime={day.date}
                        className={
                          day.isToday
                            ? "flex h-[32px] w-[32px] items-center justify-center rounded-full bg-catering font-inter-bold text-white text-[20px]"
                            : "font-inter-bold text-[20px]"
                        }
                      >
                        {day.date.split("-").pop().replace(/^0/, "")}
                      </time>

                      {day?.isCurrentMonth && (
                        <button
                          onClick={() => setAddMeal(true)}
                          type="button"
                          className="flex flex-col items-center justify-center bg-sidebar-gray w-[144px] h-[28px] rounded-sm hover:bg-gray-200 mt-[14px] mx-auto"
                        >
                          <span className="font-inter-medium text-dark-gray">
                            + Add meal
                          </span>
                        </button>
                      )}
                    </div>

                    {isPresent(findMealsByDate(day?.date)?.meal) && (
                      <ol className="mt-[10px]">
                        {findMealsByDate(day?.date)?.meal?.map((event) => (
                          <li key={event.id}>
                            <button
                              // onClick={() => setAddMeal(true)}
                              onClick={() => {
                                if (event?.name !== "ODC" && !event?.active)
                                  setUncateringMealPending(true);
                              }}
                              type="button"
                              className={classNames({
                                "flex flex-col border-l-[6px] mb-2 text-left px-1 items-center justify-center bg-sidebar-gray w-[144px] min-h-[25px] rounded-sm hover:bg-gray-200 mx-auto": true,
                                "border-under-review":
                                  event.name !== "ODC" && !event?.active,
                                "border-catering": event.name === "ODC",
                                "border-uncatering": event.name !== "ODC",
                              })}
                            >
                              <span className="font-inter-medium text-dark-gray">
                                {dateTime.formatTime(event?.cutoff)} -{" "}
                                {event?.name}
                              </span>

                              {event.name !== "ODC" && !event?.active && (
                                <small className="font-inter-medium text-under-review text-[12px] self-start">
                                  Under review
                                </small>
                              )}
                            </button>
                          </li>
                        ))}
                      </ol>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <ListView />
        )}

        <AddMeal visible={addMeal} setModal={(value) => setAddMeal(value)} />

        {uncateringMealPending && (
          <ConfirmationModal
            visible={uncateringMealPending}
            setModal={setUncateringMealPending}
            title="Uncatering request under review"
            body="You will be able to manage your uncatering meal plan as soon as we enable it. "
            rightButtonText="Learn more about our services"
            // onClickRightButton={onReduceQuantity}
            hideCancel={true}
          />
        )}

        {/* {selectedDay?.events.length > 0 && (
          <div className="px-4 py-10 sm:px-6 lg:hidden">
            <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
              {selectedDay.events.map((event) => (
                <li
                  key={event.id}
                  className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
                >
                  <div className="flex-auto">
                    <p className="font-semibold text-gray-900">{event.name}</p>
                    <time
                      dateTime={event.datetime}
                      className="mt-2 flex items-center text-gray-700"
                    >
                      <ClockIcon
                        className="mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {event.time}
                    </time>
                  </div>
                  <a
                    href={event.href}
                    className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                  >
                    Edit<span className="sr-only">, {event.name}</span>
                  </a>
                </li>
              ))}
            </ol>
          </div>
        )} */}
      </div>
    </div>
  );
}
