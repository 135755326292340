import React, { useState, useEffect, useContext } from "react";
import SmallTeamMessage from "./teamMessage";
import ActiveStepIndicator from "./activeStepIndicator";
import { peopleCount } from "utils/constants/onboardingStep";
import classNames from "classnames";
import { MobXProviderContext } from "mobx-react";

const Step1 = ({ disableButton }) => {
  const { onboardingStore } = useContext(MobXProviderContext);
  const [expectedBag, setExpectedBag] = useState("");

  useEffect(() => {
    disableButton(expectedBag === "" || expectedBag === "<20");
    onboardingStore.handleExpectedBagData(expectedBag);
  }, [expectedBag, disableButton]);

  return (
    <div className="step-one-content py-2 d-col items-center justify-center h-70">
      <ActiveStepIndicator step={1} />

      <div className="flex-col flex gap-6">
        <div className="expected-bag-text screen-xs:w-full-235 font-inter-semibold">
          <h1>How many people do you expect to order on your meal plan?</h1>
        </div>

        <div className="mb-0.5 w-full screen-xs:w-full-215">
          <div className="screen-lg:inline-flex screen-md:inline-flex">
            {peopleCount.map((value) => (
              <React.Fragment key={value}>
                <div
                  className={classNames(
                    "screen-lg:mr-3 screen-md:mr-3 mt-4 screen-lg:mt-0 screen-md:mt-0 px-4 py-3 hover:cursor-pointer font-inter-medium text-sm leading-[14px] rounded-3xl justify-start gap-2 flex",
                    {
                      "bg-indigo-500 text-white": expectedBag === value,
                      "bg-slate-50 text-slate-700 screen-lg:hover:bg-indigo-500 screen-lg:hover:text-white":
                        expectedBag !== value,
                    }
                  )}
                  onClick={() => setExpectedBag(value)}
                >
                  {value}
                </div>

                {value === "<20" && expectedBag === value && (
                  <div className="hidden screen-lg:hidden screen-md:hidden screen-sm:flex link-div my-4">
                    <SmallTeamMessage />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {expectedBag === "<20" && (
          <div className="hidden screen-lg:flex screen-md:flex my-4">
            <div className="link-div">
              <SmallTeamMessage />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1;
