import { makeAutoObservable, observable, action, makeObservable } from "mobx";
import axios from "axios";
import { API_URL } from "../utils/constants/api";
import { getLocationEmployees, isPresent } from "../utils/helpers/array";
import LocalStorage from "../utils/helpers/localStorage";
import { makePersistable, clearPersistedStore } from "mobx-persist-store";
import userStore from "./userStore";
import { client } from "../store";
import api from "store/api";

class DeliveryInfoStore {
  locationAddress = null;
  locationAddresses = [];
  singleLocationAddress = null;
  primaryAddress = null;

  constructor() {
    makeObservable(this, {
      locationAddress: observable,
      locationAddresses: observable,
      singleLocationAddress: observable,
      primaryAddress: observable,
    });
  }

  async clearStoredData() {
    await clearPersistedStore(this);
  }

  async getLocationAddress(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.locationAddress()}/${locationId}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.locationAddress = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateLocationNameAndContact(locationId, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.locationAddress()}/${locationId}?location_id=${locationId}`,
        payload
      );
      userStore.setLoader(false);
      this.locationAddress = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async getLocationAddresses(locationId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.locationAddresses()}?location_id=${locationId}`
      );
      userStore.setLoader(false);

      const primaryAddress = response?.data?.data?.find(
        (address) => address?.attributes?.primary
      );
      this.primaryAddress = primaryAddress;
      this.locationAddresses = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async getSingleLocationAddress(locationId, addressId) {
    try {
      userStore.setLoader(true);
      const response = await client().get(
        `${api.locationAddresses()}/${addressId}?location_id=${locationId}`
      );
      userStore.setLoader(false);
      this.singleLocationAddress = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateAddress(locationId, addressId, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().put(
        `${api.locationAddresses()}/${addressId}?location_id=${locationId}`,
        payload
      );
      userStore.setLoader(false);

      console.log("res", response);
      // this.locationAddress = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  async createAddress(locationId, payload) {
    try {
      userStore.setLoader(true);
      const response = await client().post(
        `${api.locationAddresses()}?location_id=${locationId}`,
        payload
      );
      userStore.setLoader(false);

      console.log("res", response);
      return response?.data?.data;
      // this.locationAddress = response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
}

const deliveryInfoStore = new DeliveryInfoStore();
export default deliveryInfoStore;
