import { useCallback } from "react";

export const useMenuItemSelection = () => {
  const initRequiredCheck = useCallback((menuItem, dispatch) => {
    let optionsTypes = []; //option types
    let options = menuItem?.option_categories;
    // Create optionTypes object so that options can separated into their own groups
    // to account for different limits of options per group

    options?.forEach((option) => {
      optionsTypes = Object.assign({}, optionsTypes, {
        [option.id]: [],
      });
    });

    // The following is to check a radio button if the option is required
    options?.forEach((optionType) => {
      // Set required for radio buttons
      if (
        optionType.attributes.required &&
        optionType.attributes.options.length &&
        optionType.attributes.choose_upto === 1
      ) {
        let activeOptions = optionType.attributes.options?.filter(
          (option) => option?.attributes?.availability === "active"
        );
        if (activeOptions?.length > 0)
          optionsTypes[optionType.id] = activeOptions[0].id;
      }
      // Set required for limited checkbox menu items
      if (
        optionType.attributes.required &&
        optionType.attributes.options.length &&
        (optionType.attributes.choose_upto >= 2 ||
          optionType.attributes.choose_upto === 0)
      ) {
        let activeOptions = optionType.attributes.options?.filter(
          (option) => option?.attributes?.availability === "active"
        );
        if (activeOptions?.length > 0)
          optionsTypes[optionType.id] = [activeOptions[0].id];
      }
    });

    dispatch({
      field: "optionTypes",
      value: optionsTypes,
    });
  });

  const setSelectedOptions = useCallback((editItem, optionsField, dispatch) => {
    let tempOptionTypes = {};

    editItem?.attributes?.[optionsField]?.forEach((option) => {
      tempOptionTypes = Object.assign({}, tempOptionTypes, {
        [option.attributes.option_category_id]:
          tempOptionTypes[option.attributes.option_category_id]?.length > 0
            ? [
                ...tempOptionTypes[option.attributes.option_category_id],
                option.attributes.option_id.toString(),
              ]
            : [option.attributes.option_id.toString()],
      });

      dispatch({
        field: "optionTypes",
        value: tempOptionTypes,
      });
    });

    dispatch({
      field: "optionTypes",
      value: tempOptionTypes,
    });

    dispatch({
      field: "instructions",
      value: editItem?.attributes?.special_instructions,
    });

    if (optionsField == "order_item_options")
      dispatch({
        field: "nameLabel",
        value: editItem?.attributes?.notes,
      });

    dispatch({
      field: "quantity",
      value: editItem?.attributes?.quantity,
    });
  });

  const updateQuantity = (e, dispatch) => {
    const value = e?.target?.value;

    if (value > 0)
      dispatch({
        field: "quantity",
        value: value ? Number(value) : value,
      });
  };

  return {
    initRequiredCheck,
    setSelectedOptions,
    updateQuantity,
  };
};
