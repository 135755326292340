import { faAdd, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CartItem from "components/Cart/CartItem";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { MobXProviderContext } from "mobx-react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import DatePicker from "components/DatePicker";
import TimePicker from "components/timepicker";
import { InfoButton, RadioActive, RadioInActive } from "assets/img";
import {
  priceFormatter,
  validateEmail,
  preventMinus,
  preventPasteNegative,
} from "utils/helpers/validation";
import Tooltip from "components/Tooltip";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import ConfirmationModal from "components/ConfirmationModal";
import { groupBy, isPresent } from "utils/helpers/array";
import EmbedHTML from "components/shared/embedHTML";

import { getOperatingHoursOptionsForDropdown } from "utils/helpers/dateTime";
import InputError from "components/InputError/InputError";
import TipButton from "components/TipButton";
import { TIP_VALUES_DESKTOP, TIP_VALUES_MOBILE } from "utils/constants/common";
import ConfirmationBadge from "components/ConfirmationBadge";
import { getByPassLocationCheckText } from "utils/helpers/string";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);

const cart = {
  data: {
    id: "1496",
    type: "uncheckedout_order",
  },

  billAs: "company",
  cartDate: "2024-02-20",
  cartTime: "5:45 pm",
  city: null,
  comment: "",
  company: "test",
  cutlery: true,
  data: {
    id: "1496",
    type: "uncheckedout_order",
  },
  email: "abdul@hungerhub.com",
  employeeId: 5984,
  firstName: "testing",
  grandTotal: "309.60",
  items: [
    {
      id: "7437",
      type: "order_item",
      attributes: {
        quantity: 1,
        special_instructions: "testing",
        notes: "testing one",
        price: "14.99",
        category: "standalone category template by arooba",
        category_display_name: "<p>standalone category template by arooba</p>",
        detailed_restaurant_name: "Restaurant & by & arooba(Basits menu)",
        menu_category_id: 2161,
        menu_item: {
          id: "6198",
          type: "menu_item",
        },
        options: [
          {
            id: 13137,
            name: "standalone template",
            display_name: "<p>standalone template</p>",
            position: 2,
          },
        ],
        order_item_options: [
          {
            id: "8718",
            type: "order_item_option",
          },
        ],
        total_price: "14.99",
      },
      restaurant_name: "Basits menu",
    },
  ],
  lastName: "Abdul",
  phone: "+13103333333",
  restaurant: {
    attributes: {
      code: "onvvl",
      name: "Basits menu",
      menu_id: 416,
      restaurant_id: 214,
      zone_id: 16,
    },
  },
  shipmentId: 4792,
  tipAmount: 24,
  unCheckedOutOrder: {
    slug: "1c608016004042de00a3fd7beb8b1c47",
    subtotal: "151.49",
    tax: "20.68",
    service_fee: "7.57",
    // Additional details for the unchecked out order would be included here
  },
};

const CheckoutPage = () => {
  const initialState = {
    comment: "",
    firstName: "",
    lastName: "",
    billAs: "company",
    company: "",
    phone: "",
    email: "",
    tip: "10",
    otherTip: "",
    customTip: 0,
    isInvalidAddress: false,
    phoneError: "",
    taxAmount: 0,
    internalAddress: null,
    deliveryFee: 0,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const [addressValidationAlert, setAddressValidationAlert] = useState(null);
  const [bypassPlaceOrder, setBypassPlaceOrder] = useState(null);
  const [copiedAlert, showCopiedAlert] = useState(false);
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const restaurantStore = toJS(store?.restaurantStore);
  const router = null;
  let { address, currentUser, addressTimezone } = userStore ?? {};
  let { activeRestaurant } = restaurantStore ?? {};
  //   let { unCheckedOutOrder } = cartStore ?? {};
  let unCheckedOutOrder = {};
  let completeAddress = toJS(store?.userStore?.completeAddress);

  let {
    tip,
    customTip,
    otherTip,
    firstName,
    lastName,
    phone,
    email,
    company,
    comment,
    billAs,
    isInvalidAddress,
    phoneError,
    taxAmount,
    internalAddress,
    deliveryFee,
  } = provider;

  let tipAmount,
    grandTotalAmount = 0;

  useEffect(() => {
    router?.events?.on("routeChangeComplete", () => {
      window.scroll({
        top: 0,
        left: 0,
      });
    });
  }, []);

  useEffect(() => {
    if (unCheckedOutOrder?.id && store?.userStore?.isHydrated)
      store.cartStore?.getCartItems(unCheckedOutOrder?.id);
  }, [router?.isReady && unCheckedOutOrder?.id, store?.userStore?.isHydrated]);

  useEffect(() => {
    if (cart?.firstName && cart?.lastName && cart?.phone && cart?.email) {
      dispatch({ field: "firstName", value: cart?.firstName });
      dispatch({ field: "lastName", value: cart?.lastName });
      dispatch({ field: "phone", value: cart?.phone });
      dispatch({ field: "email", value: cart?.email });

      if (cart?.billAs == "company") {
        dispatch({ field: "billAs", value: cart?.billAs });
        dispatch({ field: "company", value: cart?.company });
      }

      if (cart?.billAs && cart?.company) {
        dispatch({ field: "billAs", value: cart?.billAs });
        dispatch({ field: "company", value: cart?.company });
      }
    }
  }, [cart?.firstName, cart?.lastName, cart?.phone, cart?.email]);

  useEffect(() => {
    if (!store?.userStore?.isHydrated) return;
  }, [
    cart?.suite,
    cart?.streetNumber,
    cart?.streetName,
    cart?.city,
    cart?.province,
    cart?.postalCode,
    store?.userStore?.isHydrated,
  ]);

  useEffect(() => {
    const isSharedLink =
      store?.userStore?.isHydrated &&
      router?.query?.slug &&
      router?.query?.shared;

    if (isSharedLink) {
      store.cartStore?.getCartItems(router?.query?.slug, true);
    }
  }, [router?.isReady, router?.query?.slug, store?.userStore?.isHydrated]);

  useEffect(() => {
    const isSharedLink =
      store?.userStore?.isHydrated &&
      router?.query?.slug &&
      router?.query?.shared;

    if (isSharedLink) {
      dispatch({
        field: "tip",
        value: cart?.tipPercentage == 0 ? "Other" : cart?.tipPercentage,
      });

      dispatch({
        field: "customTip",
        value: cart?.tipPercentage == 0 ? cart?.tipAmount : 0,
      });

      dispatch({
        field: "otherTip",
        value: cart?.tipPercentage == 0 ? cart?.tipAmount : 0,
      });
    }
  }, [cart?.tipPercentage, cart?.tipAmount]);

  useEffect(() => {
    if (router?.query?.shared) {
      dispatch({
        field: "internalAddress",
        value: {
          suite: cart?.suite,
          streetNumber: cart?.streetNumber,
          streetName: cart?.streetName,
          city: cart?.city,
          province: cart?.province,
          postalCode: cart?.postalCode,
        },
      });
    }
  }, [
    router?.isReady,
    router?.query?.shared,
    cart?.suite,
    cart?.streetNumber,
    cart?.streetName,
    cart?.city,
    cart?.province,
    cart?.postalCode,
  ]);

  useEffect(() => {
    if (currentUser) {
      dispatch({
        field: "firstName",
        value: currentUser?.attributes?.first_name,
      });
      dispatch({
        field: "lastName",
        value: currentUser?.attributes?.last_name,
      });
      dispatch({ field: "phone", value: currentUser?.attributes?.phone });
      dispatch({ field: "email", value: currentUser?.attributes?.email });

      if (currentUser?.attributes?.billable_as == "company") {
        dispatch({ field: "billAs", value: "company" });
        dispatch({
          field: "company",
          value: currentUser?.attributes?.company_name,
        });
      }
    }
  }, [currentUser !== null]);

  useEffect(() => {
    if (cart?.comment) dispatch({ field: "comment", value: cart?.comment });
  }, [cart?.comment]);

  const getAddress = () => {
    return `${internalAddress?.suite ? `${internalAddress?.suite} - ` : ""}${
      internalAddress?.streetNumber ?? ""
    } ${internalAddress?.streetName ?? ""} ${internalAddress?.city ?? ""}, ${
      internalAddress?.province ?? ""
    }, ${internalAddress?.postalCode ?? ""}`;
  };

  useEffect(() => {
    if (!store?.userStore?.isHydrated) return;

    let addressPayload = address ? address : getAddress();

    if (
      addressPayload.replace(/[ ,]/g, "") &&
      cart?.restaurant?.attributes?.restaurant_id
    ) {
      store.userStore?.setLoader(true);
      store.cartStore
        ?.getDeliveryFee(cart?.restaurant?.attributes?.restaurant_id, {
          order_amount: cart?.data?.attributes?.grand_total,
          order_subtotal: cart?.data?.attributes?.subtotal,
          order_address: addressPayload,
          order_id: unCheckedOutOrder?.id,
        })
        .then((response) => {
          if (!response?.valid) {
            dispatch({
              field: "isInvalidAddress",
              value: true,
            });
            if (
              response?.message == "Address out of range." ||
              response?.message == "Address not found."
            )
              setAddressValidationAlert(true);
            else
              store.userStore?.setErrorAlert(true, {
                title: response?.message,
              });
          } else {
            dispatch({
              field: "isInvalidAddress",
              value: false,
            });

            dispatch({
              field: "taxAmount",
              value: response?.tax_amount,
            });

            dispatch({
              field: "deliveryFee",
              value: response?.message || 0,
            });

            dispatch({
              field: "deliveryFee",
              value: response?.message || 0,
            });
          }

          store.userStore?.setLoader(false);
        });
    }
  }, [
    address,
    internalAddress?.suite,
    internalAddress?.streetName,
    internalAddress?.streetNumber,
    internalAddress?.postalCode,
    internalAddress?.city,
    internalAddress?.province,
    cart?.data?.attributes?.subtotal,
    store?.userStore?.isHydrated,
  ]);

  tipAmount = useMemo(() => {
    if (tip !== "Other") return (tip / 100) * cart?.data?.attributes?.subtotal;
    else return customTip || 0;
  }, [cart?.data?.attributes?.subtotal, tip, customTip]);

  grandTotalAmount = useMemo(() => {
    return parseFloat(
      parseFloat(cart?.data?.attributes?.subtotal) +
        parseFloat(taxAmount) +
        parseFloat(tipAmount) +
        parseFloat(cart?.data?.attributes?.service_fee) +
        parseFloat(deliveryFee)
    ).toFixed(2);
  }, [cart?.data?.attributes?.subtotal, deliveryFee, taxAmount, tipAmount]);

  const createCheckOutSession = async (shipmentId, employeeId) => {
    const stripe = await stripePromise;

    const origin =
      typeof window !== "undefined" && window.location.origin
        ? window.location.origin
        : "";

    let params = {
      email,
      tip: parseFloat(tipAmount),
      cutlery: cart?.cutlery,
      employee_id: employeeId,
      shipment_id: shipmentId,
      success_url: `${origin}/checkout/success`,
      cancel_url: `${origin}/checkout/${cart?.data?.attributes?.slug}`,
    };

    let payload = {
      ...params,
      restaurant_name: cart?.restaurant?.attributes?.name,
      uncheckedout_order_id: unCheckedOutOrder?.id,
    };

    store.cartStore
      ?.createSession(params, payload)
      .then((response) => {
        store.userStore?.setLoader(false);

        if (response?.id) {
          store?.cartStore?.saveCartDetails({ stripeSessionId: response.id });

          stripe.redirectToCheckout({
            sessionId: response.id,
          });
        }
      })
      .catch(() => store.userStore?.setLoader(false));
  };

  const isAbleToProceedToPayment = () => {
    const isValidPhone = phone && isValidPhoneNumber(provider.phone);
    const isValidEmail = email && validateEmail(email);
    const userInfoAvailable =
      firstName && lastName && isValidPhone && isValidEmail;

    return billAs == "company"
      ? company && userInfoAvailable
      : userInfoAvailable;
  };

  const updateUncheckedOutOrder = (copyLinkToClipboard) => {
    if (copyLinkToClipboard) {
      const currentURL = new URL(window.location.href);

      currentURL.searchParams.append("shared", "true");

      const updatedURL = currentURL.href;

      navigator.clipboard.writeText(updatedURL);

      showCopiedAlert(true);
      setTimeout(() => showCopiedAlert(false), 2000);
    } else {
      store.userStore.setLoader(true);
    }

    if (unCheckedOutOrder?.id) {
      const unCheckedOutOrderPayload = {
        ...unCheckedOutOrder,
        suite: completeAddress.suite,
        street_name: completeAddress.street_name,
        street_number: completeAddress.street_number,
        city: completeAddress.city,
        province: completeAddress.province,
        postal_code: completeAddress.postal_code,
        billable_as: provider?.billAs,
        company_name: provider.company,
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        status: "order_summary",
        menu_id: cart?.restaurant?.attributes?.menu_id,
        restaurant_id: cart?.restaurant?.attributes?.restaurant_id,
        order_date: moment(cart?.cartDate).format("DD-MM-YYYY"),
        order_time: cart?.cartTime,
        cutlery_required: cart?.cutlery,
        delivery_instructions: comment,
        tip_in_percentage: tip,
        tip_in_fixed_amount: tip !== "Other" ? tipAmount : customTip,
      };

      if (currentUser) unCheckedOutOrderPayload.user_id = currentUser.id;

      return store?.cartStore
        ?.updateUncheckedOutOrder(
          unCheckedOutOrder?.id,
          unCheckedOutOrderPayload
        )
        .then((response) => {
          return response;
        })
        .catch(() => {
          store.userStore.setLoader(false);
        });
    }
  };

  const scrollToPhoneInput = () => {
    const inputElement = document.getElementById("phone");
    if (inputElement) {
      const yOffset = -60; // Adjust this value based on your design and needs
      const y =
        inputElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const proceedToPayment = async (e) => {
    e.preventDefault();

    const isValidPhone = phone && isValidPhoneNumber(provider.phone);
    const isValidEmail = email && validateEmail(email);
    const selectedDateTime = moment(
      `${moment(cart?.cartDate).format("YYYY-MM-DD")} ${cart?.cartTime}`,
      "YYYY-MM-DD h:mm a"
    );

    const result = await updateUncheckedOutOrder();

    if (isPresent(result?.response?.data?.errors)) {
      store?.userStore?.setErrorAlert(true, {
        title:
          "Uncheckedout order not found. Please continue with a new order.",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (isInvalidAddress) {
      store?.userStore?.setErrorAlert(true, {
        title: "Invalid delivery address, please try again",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (address == "") {
      store?.userStore?.setErrorAlert(true, {
        title: "Please enter your delivery address",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (!isValidPhone && !isValidEmail && !firstName && !lastName) {
      store?.userStore?.setErrorAlert(true, {
        title: "Please enter billing information",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (!firstName) {
      store?.userStore?.setErrorAlert(true, {
        title: "First name is required",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (!lastName) {
      store?.userStore?.setErrorAlert(true, {
        title: "Last name is required",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (!selectedDateTime.isAfter(moment())) {
      store?.userStore?.setErrorAlert(true, {
        title:
          "You have selected a past date/time, please recheck the date to continue",
      });
      store.userStore?.setLoader(false);
      return;
    }

    if (!isValidPhone) {
      dispatch({ field: "phoneError", value: "Invalid phone number" });
      store.userStore?.setLoader(false);
      scrollToPhoneInput();
      return;
    }

    if (!isValidEmail) {
      store?.userStore?.setErrorAlert(true, {
        title:
          email == ""
            ? "Email field is required"
            : "You have entered an invalid email.",
      });
      store.userStore?.setLoader(false);
      return;
    }

    saveCartToAirTable();

    store.cartStore?.saveCartDetails({
      firstName,
      lastName,
      email,
      phone,
      company,
      billAs,
      comment,
      tipAmount,
      grandTotal: grandTotalAmount,
    });

    sendProceedPaymentRequest();
  };

  const sendProceedPaymentRequest = (bypassDifferentAddress = false) => {
    let employeePayload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      suite: completeAddress.suite,
      street_name: completeAddress.street_name,
      street_number: completeAddress.street_number,
      city: completeAddress.city,
      province: completeAddress.province,
      postal_code: completeAddress.postal_code,
      zone_id: cart?.restaurant?.attributes?.zone_id,
      delivery_date: moment(cart?.cartDate).format("YYYY-MM-DD"),
      target_time: cart?.cartTime,
      delivery_instructions: comment,
      menu_id: cart?.restaurant?.attributes?.menu_id,
      source_of_business: router?.query?.utm_source
        ? "restaurant"
        : "hungerhub",
      billable_as: provider?.billAs,
      uncheckedout_order_id: unCheckedOutOrder?.id,
    };

    if (bypassDifferentAddress) {
      employeePayload.override_location_check = true;
    }

    if (provider?.billAs == "company")
      employeePayload.company_name = provider.company;

    store.userStore?.setLoader(true);
    store.cartStore
      ?.createEmployee(employeePayload)
      .then((response) => {
        if (response?.employee_id && response?.shipment_id) {
          store.cartStore?.saveCartDetails({
            employeeId: response?.employee_id,
            shipmentId: response?.shipment_id,
          });

          createCheckOutSession(response?.shipment_id, response?.employee_id);
        } else if (
          !response?.success &&
          response?.address_fields &&
          !bypassDifferentAddress
        ) {
          store.userStore?.setLoader(false);
          const address = response?.address_fields;
          setBypassPlaceOrder(getByPassLocationCheckText(address));
        } else store.userStore?.setLoader(false);
      })
      .catch(() => store.userStore?.setLoader(false));
  };

  const onClickLeftButtonInAlert = () => {
    setAddressValidationAlert(null);
    router.push("/");
  };

  const onClickRightButtonInAlert = () => {
    store?.userStore?.setAddress("");

    setAddressValidationAlert(null);
  };

  const getTotalQuantity = (items) => {
    let quantity = 0;
    items.forEach((item) => {
      quantity += item?.attributes?.quantity;
    });

    return quantity;
  };

  const getTimePickerOptions = () => {
    return getOperatingHoursOptionsForDropdown(
      activeRestaurant,
      cart?.cartDate,
      addressTimezone
    );
  };

  const computeOrder = () => {
    let order = "";

    cart?.items?.forEach((item, index) => {
      order += `${index >= 1 ? "+" : ""} ${index + 1}.Item Name= ${
        item?.attributes?.menu_item?.attributes?.title
      } Quantity=${item?.attributes?.quantity},`;

      if (item?.attributes?.order_item_options.length > 0) {
        order += `options=`;
        item?.attributes?.order_item_options?.forEach(
          (option) =>
            (order += `${option?.attributes?.option_group_display_name}: ${option?.attributes?.option_display_name},`)
        );
      }

      order += `instructions= ${item?.attributes?.special_instructions},`;
    });

    return order;
  };

  const saveCartToAirTable = () => {
    store?.cartStore?.saveCartInAirTable({
      Restaurant: cart?.restaurant?.attributes?.name,
      "Customer Name": `${firstName} ${lastName}`,
      Email: email,
      "Phone number": phone,
      utm_source: router?.query?.utm_source,
      Cart: computeOrder(),
    });
  };

  return (
    <>
      <div
        className="flex justify-center w-full border-border-gray bg-light-gray min-h-screen mx-auto"
        // style={{ maxWidth: "1440px" }}
      >
        {cart?.items?.length > 0 ? (
          <div
            className="flex flex-col w-full mx-auto my-5 px-5"
            style={{ maxWidth: "1200px" }}
          >
            <div className="flex items-center">
              <span className="font-inter-medium text-[24px] md:text-[32px] mr-3">
                Order Details
              </span>

              <FontAwesomeIcon
                onClick={() => updateUncheckedOutOrder(true)}
                icon={faShareSquare}
                size="1x"
                className="icon cursor-pointer"
              />
            </div>

            <div className="flex flex-col md:flex-row items-start mt-7">
              <div className="flex flex-col p-5 bg-white rounded-3xl w-full md:w-3/5 md:mr-8 static md:sticky md:top-0">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mt-1">
                  <div className="flex flex-col">
                    <span className="font-inter-medium text-md2 text-black-light">
                      {cart?.restaurant?.attributes?.name}
                    </span>

                    <span className="text-md text-secondary-text">
                      Total items:{" "}
                      {cart?.items?.reduce((acc, item) => {
                        return acc + item?.attributes?.quantity;
                      }, 0)}
                    </span>
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      store.userStore.setLoader(true);
                      router.back();
                    }}
                    className="flex mt-3 sm:mt-0 btn-style items-center justify-center sm:justify-start text-sm border-hh-accent-dark border p-2 px-3 rounded-3xl text-hh-accent-dark"
                  >
                    <div className="flex" style={{ width: 16, height: 16 }}>
                      <FontAwesomeIcon
                        icon={faAdd}
                        size="1x"
                        className="icon"
                      />
                    </div>

                    <span className="text-sm ml-2">edit order</span>
                  </button>
                </div>

                <div className="bg-background p-2 rounded-md mt-4">
                  <label className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-medium text-sm w-44">
                      Request cutlery
                    </span>

                    <input
                      autoComplete="off"
                      type="checkbox"
                      className="form-checkbox"
                      onChange={(e) =>
                        store.cartStore.updateCutlery(e.target.checked)
                      }
                      checked={cart?.cutlery}
                    />
                  </label>
                </div>

                <div className="mt-4">
                  {Object.values(
                    groupBy(
                      cart?.items,
                      (item) => item?.attributes?.menu_category_id
                    )
                  )?.map((entry, index) => (
                    <div key={`${index}-cartItem`}>
                      <div className="flex justify-between items-center bg-background p-3 rounded-md">
                        <span className="text-md font-inter-medium text-primary-black">
                          <EmbedHTML
                            text={
                              entry[0]?.attributes
                                ?.category_display_name_with_html ||
                              entry[0]?.attributes?.category
                            }
                            className="text-primary-black"
                          />
                        </span>

                        <span className="text-sm">
                          Total Qty: {getTotalQuantity(entry)}
                        </span>
                      </div>

                      {entry?.map((cartItem) => (
                        <div className="px-3">
                          <CartItem
                            checkout
                            cartItem={cartItem}
                            key={cartItem.id}
                            moreThanOneItemInCategory={entry.length > 1}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>

              <form onSubmit={proceedToPayment} autoComplete="off">
                <div className="flex flex-col p-5 bg-white rounded-3xl">
                  <span className="text-[20px] font-inter-medium">
                    Delivery Details
                  </span>

                  {/* <AddressInput checkout internalAddress={internalAddress} /> */}

                  <div className="flex flex-col sm:flex-row w-full justify-between mt-5">
                    <div className="flex flex-col w-full sm:w-1/2">
                      <span className="text-sm mb-1">Delivery date</span>

                      <DatePicker
                        isCart
                        selectedDate={cart?.cartDate}
                        activeRestaurant={activeRestaurant}
                        minDate={new Date()}
                        format={"MMMM d, yyyy"}
                        className="flex h-12 bg-background justify-between rounded-lg pr-2 items-center"
                      />
                    </div>

                    <div className="flex flex-col w-full sm:w-1/2 sm:ml-3">
                      <span className="text-sm mb-1">Time</span>

                      {/* <TimePicker
                        isCart
                        selectPicker={{
                          options: getTimePickerOptions(),
                        }}
                        timeZone={cart?.restaurant?.attributes?.tzinfo}
                        selectedDate={cart?.cartDate}
                        selectedTime={cart?.cartTime}
                        className="flex h-12 bg-background justify-between rounded-lg text-sm border-0 text-primary-black"
                      /> */}
                    </div>
                  </div>

                  <div className="flex flex-col mt-5">
                    <span className="text-sm mb-1">Comment</span>

                    <input
                      className="input-gray-bg text-sm"
                      placeholder="Ex. Leave at door"
                      value={provider.comment}
                      onChange={(e) =>
                        dispatch({ field: "comment", value: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-col p-5 bg-white mt-6 rounded-3xl">
                  <span className="text-[20px] font-inter-medium">
                    Billing Info
                  </span>

                  <div className="flex flex-col min-[560px]:flex-row flex-wrap mt-4">
                    <div
                      className="flex cursor-pointer"
                      onClick={() =>
                        dispatch({ field: "billAs", value: "company" })
                      }
                    >
                      <img
                        alt="radio-button"
                        src={
                          provider?.billAs === "company"
                            ? RadioActive
                            : RadioInActive
                        }
                      />

                      <span className="text-md ml-2">Bill as a company</span>
                    </div>

                    <div
                      className="flex mt-3 min-[560px]:mt-0 min-[560px]:ml-4 cursor-pointer"
                      onClick={() =>
                        dispatch({ field: "billAs", value: "individual" })
                      }
                    >
                      <img
                        alt="radio-button-1"
                        src={
                          provider?.billAs === "individual"
                            ? RadioActive
                            : RadioInActive
                        }
                      />

                      <span className="text-md ml-2">
                        Bill as an individual
                      </span>
                    </div>
                  </div>

                  <input
                    required
                    name="firstNameField"
                    className="input-gray-bg-border mt-3 text-sm"
                    placeholder="First name*"
                    value={provider.firstName}
                    onChange={(e) =>
                      dispatch({ field: "firstName", value: e.target.value })
                    }
                  />

                  <input
                    required
                    name={"lastNameField"}
                    className="input-gray-bg-border mt-3 text-sm"
                    placeholder="Last name*"
                    value={provider.lastName}
                    onChange={(e) =>
                      dispatch({ field: "lastName", value: e.target.value })
                    }
                  />

                  {provider?.billAs == "company" && (
                    <input
                      required
                      name={"companyField"}
                      className="input-gray-bg-border mt-3 text-sm"
                      placeholder="Company*"
                      value={provider.company}
                      onChange={(e) =>
                        dispatch({ field: "company", value: e.target.value })
                      }
                    />
                  )}

                  <PhoneInput
                    required
                    id="phone"
                    defaultCountry="CA"
                    value={provider.phone}
                    className={classNames("input-gray-bg-border mt-3 text-sm", {
                      "border-attention": phoneError,
                    })}
                    placeholder="Phone Number"
                    onChange={(value) => {
                      if (phoneError)
                        dispatch({ field: "phoneError", value: "" });

                      dispatch({ field: "phone", value: value ? value : "" });
                    }}
                  />

                  {phoneError && <InputError error={phoneError} />}

                  <input
                    required
                    name={"emailField"}
                    className="input-gray-bg-border mt-3 text-sm"
                    placeholder="Email*"
                    type={"email"}
                    value={provider.email}
                    onChange={(e) =>
                      dispatch({ field: "email", value: e.target.value })
                    }
                  />

                  {!currentUser && (
                    <button
                      type="button"
                      onClick={() => store.userStore.setLoginModal(true)}
                      className="text-center btn-style mt-5 text-sm hover:text-hh-accent-dark"
                    >
                      Already have an account?{" "}
                      <span className="underline">Sign in</span>
                    </button>
                  )}
                </div>

                <div className="flex flex-col p-5 bg-white mt-6 rounded-3xl">
                  <span className="text-[20px] font-inter-medium">Summary</span>

                  <div className="mt-5">
                    <div className="flex w-full items-center justify-between">
                      <span className="text-md">Items</span>
                      <span className="text-md">
                        {priceFormatter(cart?.data?.attributes?.subtotal)}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <div className="flex items-center">
                        <span className="text-md">Delivery</span>

                        <Tooltip
                          grayIcon
                          tooltipText={
                            "Your delivery cost is dependent on restaurant and proximity to your delivery address"
                          }
                          id="delivery-tooltip"
                          backgroundColor={"white"}
                          textColor="black"
                          place={"bottom"}
                        />
                      </div>

                      <span className="text-md">
                        {priceFormatter(deliveryFee)}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <div className="flex items-center">
                        <span className="text-md">Service Fee</span>

                        <Tooltip
                          grayIcon
                          tooltipText={
                            "A service fee of five percent of total item charges is inclusive of all hungerhub charges"
                          }
                          id="service-tooltip"
                          backgroundColor={"white"}
                          textColor="black"
                          place={"bottom"}
                        />
                      </div>

                      <span className="text-md">
                        {priceFormatter(cart?.data?.attributes?.service_fee)}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <span className="text-md">Tax</span>

                      <span className="text-md">
                        {priceFormatter(taxAmount)}
                      </span>
                    </div>

                    <div className="border-t border-b border-border-gray my-4 py-5">
                      <div className="flex w-full items-center justify-between">
                        <span className="text-md font-inter-medium">Tip</span>

                        <span className="text-md">
                          {priceFormatter(tipAmount)}
                        </span>
                      </div>

                      <div className="hidden sm:flex w-full flex-wrap items-center">
                        {TIP_VALUES_DESKTOP?.map((tipValue) => (
                          <TipButton
                            key={`${tipValue}-tip`}
                            onClick={() =>
                              dispatch({
                                field: "tip",
                                value: tipValue,
                              })
                            }
                            tipValue={tipValue}
                            tip={provider?.tip}
                          />
                        ))}
                      </div>

                      <div className="flex sm:hidden w-full flex-wrap items-center">
                        {TIP_VALUES_MOBILE?.map((tipValue) => (
                          <TipButton
                            key={`${tipValue}-tip-mob`}
                            onClick={() =>
                              dispatch({
                                field: "tip",
                                value: tipValue,
                              })
                            }
                            tipValue={tipValue}
                            tip={provider?.tip}
                          />
                        ))}
                      </div>

                      {provider.tip == "Other" && (
                        <div className="flex mt-5 space-x-5">
                          <div className="flex items-center text-md rounded-xl bg-background w-full py-2 px-3 h-12 border border-border-gray">
                            <span className="mr-1">$</span>

                            <input
                              className="text-sm bg-background h-[46px] w-full focus:outline-none"
                              value={otherTip}
                              type="number"
                              min="0"
                              step=".01"
                              onKeyDown={preventMinus}
                              onPaste={preventPasteNegative}
                              onChange={(e) =>
                                dispatch({
                                  field: "otherTip",
                                  value:
                                    e.target.value !== ""
                                      ? Math.abs(e.target.value)
                                      : e.target.value,
                                })
                              }
                            />
                          </div>

                          <button
                            type="button"
                            onClick={() =>
                              dispatch({
                                field: "customTip",
                                value: otherTip,
                              })
                            }
                            className="btn-purple w-28 text-sm"
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="flex border-b border-border-gray pb-5 mt-5">
                      <div className="mr-3">
                        <img
                          layout="fixed"
                          width={16}
                          height={16}
                          alt="info-button-checkout"
                          src={InfoButton}
                        />
                      </div>

                      <span className="text-md">
                        If you have a promo code or a discount, you will be able
                        to apply it on the payments page prior to payment
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-5 mb-16 md:mb-0">
                      <span className="text-md font-inter-medium">Total</span>

                      <span className="text-md font-inter-medium">
                        {priceFormatter(grandTotalAmount)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex mt-5 md:mt-0 bg-white md:bg-transparent p-5 md:static w-full">
                  <button
                    className="btn-purple w-full mt-4 py-5 h-[80px]"
                    type="submit"
                  >
                    <span
                      className={`text-sm font-inter-medium ${
                        isAbleToProceedToPayment()
                          ? "text-white"
                          : "text-secondary-gray"
                      }`}
                    >
                      Proceed to payment
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <>
            {store.userStore?.isHydrated && (
              <button className="text-md p-5 btn-style" type="button">
                <a href="/">
                  You cannot checkout before adding items to your cart, click
                  here to go to home.
                </a>
              </button>
            )}
          </>
        )}
        {addressValidationAlert && (
          <ConfirmationModal
            avoidClose
            visible={addressValidationAlert}
            setModal={setAddressValidationAlert}
            title={"Restaurant outside delivery area"}
            body={`${cart?.restaurant?.attributes?.name} cannot deliver to the updated address, please revert to original address or browse other restaurants`}
            leftButtonText={"Browse other restaurants"}
            rightButtonText={"Return to page"}
            onClickLeftButton={onClickLeftButtonInAlert}
            onClickRightButton={onClickRightButtonInAlert}
          />
        )}
        {bypassPlaceOrder && (
          <ConfirmationModal
            avoidClose
            visible={bypassPlaceOrder}
            setModal={setBypassPlaceOrder}
            body={bypassPlaceOrder}
            rightButtonText="Place Order"
            onClickRightButton={() => {
              sendProceedPaymentRequest(true);
            }}
          />
        )}
        {copiedAlert && <ConfirmationBadge text={"Copied to clipboard!"} />}
      </div>
    </>
  );
};

CheckoutPage.checkoutLayout = true;

export default observer(CheckoutPage);
