import { makeAutoObservable, observable, action } from "mobx";
import { client } from "../store";
import api from "store/api";

class OnboardingStore {
  cuisineTypes = [];
  dietaryPreferences = [];
  expected_bag = "";
  user = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  };

  location = {
    name: "",
    suit: "",
    street_number: "",
    street_name: "",
    city: "",
    province: "",
    postal_code: "",
    delivery_instructions: "",
    contact_number: "",
    disable_credit_cards: "",
    default_subsidy: "",
    default_subsidy_type: "",
    default_daily_subsidy: {
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: "",
    },
    meal_serieses_attributes: [],
    location_preference_attributes: {
      onboarding_cuisines: {},
      onboarding_dietary_preferences: "",
      additional_notes: "",
    },
  };

  constructor() {
    makeAutoObservable(this, {
      expected_bag: observable,
      user: observable,
      location: observable,
      handleExpectedBagData: action,
      handleMealSeriesData: action,
      handleMealSeriesAndSubsidiesData: action,
      handlePreferencesData: action,
      handleUserDetailsData: action,
    });
  }

  async fetchCuisineTypesData() {
    try {
      const response = await client().get(
        `${api.adminPanelDietaryPreference()}?location_id=${1}`
      );

      const cuisineArray = response?.data?.cuisines;
      const preferencesArray = response?.data?.dietary_preferences;

      this.cuisineTypes = cuisineArray;
      this.dietaryPreferences = preferencesArray;
    } catch (error) {
      throw error;
    }
  }

  async dispatchSignUpData() {
    // try {
    //   const payload = {
    //     user: this.user,
    //     location: this.location,
    //   };
    //   const response = await signUpRequest("POST", payload);
    //   return response;
    // } catch (error) {
    //   throw error;
    // }
  }

  calculateExpectedBags(value) {
    if (value == "<20") {
      return 20;
    } else if (value == "20-39") {
      return 20;
    } else if (value == "40-59") {
      return 40;
    } else if (value == "60-79") {
      return 60;
    } else if (value == "80-89") {
      return 80;
    } else {
      return 100;
    }
  }

  formatTime(date) {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  calculateNextMondayDate() {
    const today = new Date();
    const daysUntilMonday = 1 + ((1 - today.getDay() + 6) % 7);
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilMonday);

    nextMonday.setHours(0, 0, 0, 0);

    const formattedDate = `${nextMonday.getDate()}-${
      nextMonday.getMonth() + 1
    }-${nextMonday.getFullYear()}`;

    return formattedDate;
  }

  handleExpectedBagData(expectedBags) {
    this.expected_bag = this.calculateExpectedBags(expectedBags);
  }

  handleMealSeriesData(selectedMeals) {
    this.location.meal_serieses_attributes = selectedMeals.map((meal) => {
      const [hoursMinutes, amPm] = meal.target_time?.split(" ");
      const [hours, minutes] = hoursMinutes?.split(":");

      const targetTime = new Date();
      targetTime.setHours(
        parseInt(hours, 10) + (amPm === "pm" && hours !== "12" ? 12 : 0)
      );
      targetTime.setMinutes(parseInt(minutes, 10));

      const cutoffTime = new Date(targetTime.getTime() - 30 * 60 * 1000);
      const cutoffTimeString = this.formatTime(cutoffTime);

      return {
        name: meal.meal_name,
        target_time: meal.target_time,
        cutoff: cutoffTimeString,
        expectedBags: this.expected_bag,
        start_date: "",
        days: [],
        dates_to_add: [],
      };
    });
  }

  handleMealSeriesAndSubsidiesData(
    days,
    start_date,
    dates_to_add,
    default_subsidy_type,
    default_subsidy,
    disable_credit_cards
  ) {
    this.location.disable_credit_cards = !disable_credit_cards;
    this.location.default_subsidy =
      default_subsidy_type == "daily" ? 0 : default_subsidy;
    this.location.default_subsidy_type = default_subsidy_type;

    this.location.default_daily_subsidy =
      default_subsidy_type == "daily"
        ? {
            monday: "20",
            tuesday: "20",
            wednesday: "20",
            thursday: "20",
            friday: "20",
            saturday: "20",
            sunday: "20",
          }
        : {
            monday: "0",
            tuesday: "0",
            wednesday: "0",
            thursday: "0",
            friday: "0",
            saturday: "0",
            sunday: "0",
          };

    this.location.meal_serieses_attributes.forEach((mealSeries) => {
      mealSeries.start_date =
        start_date === "I am not sure yet"
          ? this.calculateNextMondayDate()
          : start_date;
      mealSeries.days = days;
      mealSeries.dates_to_add = dates_to_add.flatMap(
        (month) => month[Object.keys(month)[0]]
      );
    });
  }

  handlePreferencesData(
    onboarding_cuisines,
    onboarding_dietary_preferences,
    additional_notes
  ) {
    this.location.location_preference_attributes.onboarding_cuisines =
      onboarding_cuisines;
    this.location.location_preference_attributes.onboarding_dietary_preferences =
      onboarding_dietary_preferences.join(", ");
    this.location.location_preference_attributes.additional_notes =
      additional_notes;
  }

  handleUserDetailsData(userDetails) {
    this.user.first_name = userDetails.first_name;
    this.user.last_name = userDetails.last_name;
    this.user.email = userDetails.email;
    this.user.phone = userDetails.phone_number;

    this.location.name = userDetails.company_name;
    this.location.suit = userDetails.unite_suit;
    this.location.street_number = userDetails.street_number;
    this.location.street_name = userDetails.street_name;
    this.location.city = userDetails.city;
    this.location.province = userDetails.province;
    this.location.postal_code = userDetails.postal_code;
    this.location.delivery_instructions = userDetails.comment;
    this.location.contact_number = userDetails.phone_number;
  }
}

const onboardingStore = new OnboardingStore();
export default onboardingStore;
